import React, { useMemo, useContext } from 'react';
import { getSelectedOption } from '../../../../../../common/utils';
import ConditionalSelectComponent from '../../../../../../common/components/ConditionalSelectComponent';
import { WindowSizeContext } from '../../../../../../common/context/WindowSizeContext';
import {useBlacklistedDashboardComponents} from "../../../providers/blacklistedComponentsProvider/hooks";
import {
    COMPONENT_METRIC_AVG_FREQUENCY_OF_VISIT,
    COMPONENT_METRIC_BASKET_SIZE,
    COMPONENT_METRIC_SALES, COMPONENT_METRIC_TRANSACTIONS, COMPONENT_METRIC_UNITS_SOLD_PER_TRANSACTION
} from "../Customer/dashboardDefinition";


export default function MetricFilter({ isMTD, filters, onFilterChange, store, business_unit, metric_options }) {
    const windowSize = useContext(WindowSizeContext);
    const isGroupChannel = filters?.group === 'channel';
    const isGroupBU = filters?.group === 'business_unit' || filters?.group === 'bu_group_3';
    const isGroupZone = filters?.group === 'zone';
    const isGroupHigherMBU = filters?.group === 'higher_mbu';

    const blacklistedComponents = useBlacklistedDashboardComponents()

    const options = useMemo(() => {
        const availableOptions = [
            { value: 'actual_sales', label: 'Sales', disabled: blacklistedComponents.includes(COMPONENT_METRIC_SALES) },
            { value: 'actual_transactions', label: 'Transactions', disabled: blacklistedComponents.includes(COMPONENT_METRIC_TRANSACTIONS) },
            { value: 'actual_basket_size', label: 'Basket Size', disabled: blacklistedComponents.includes(COMPONENT_METRIC_BASKET_SIZE) },
            { value: 'actual_catchment_rate', label: 'Catchment Rate' },
            { value: 'actual_conversion_rate', label: 'Conversion Rate' },
            { value: 'actual_space_tfa', label: 'Space Productivity TFA' },
            { value: 'actual_space_nsa', label: 'Space Productivity NSA' },
            { value: 'actual_smac_sales', label: 'SMAC Sales' },
            { value: 'actual_mall_foot_traffic', label: 'Mall Foot Traffic' },
            { value: 'actual_sm_store_foot_traffic', label: 'Dept Store Foot Traffic' },
            { value: 'actual_frequency_of_visit', label: 'Avg Frequency of Visit', disabled: blacklistedComponents.includes(COMPONENT_METRIC_AVG_FREQUENCY_OF_VISIT) },
            { value: 'actual_units_sold', label: 'Units Sold' },
            { value: 'actual_units_sold_per_txn', label: 'Units Sold Per Transaction', disabled: blacklistedComponents.includes(COMPONENT_METRIC_UNITS_SOLD_PER_TRANSACTION) },
        ].filter(o => !o.disabled);

        if (isGroupChannel && !metric_options) {
            const notMetricsForChannel = ['actual_catchment_rate', 'actual_conversion_rate', 'actual_space_tfa', 'actual_space_nsa', 'actual_mall_foot_traffic', 'actual_sm_store_foot_traffic', 'actual_units_sold', 'actual_units_sold_per_txn', 'actual_frequency_of_visit'];
            return availableOptions.filter(option => !notMetricsForChannel.includes(option.value));

        } else if (isGroupBU) {
            const notMetricsForBU = ['actual_catchment_rate', 'actual_conversion_rate', 'actual_space_tfa', 'actual_mall_foot_traffic', 'actual_sm_store_foot_traffic', 'actual_frequency_of_visit'];
            return availableOptions.filter(option => !notMetricsForBU.includes(option.value));

        } else if (isGroupZone) {
            const notMetricsForZone = ['actual_units_sold', 'actual_units_sold_per_txn'];
            return availableOptions.filter(option => !notMetricsForZone.includes(option.value));

        } else if (isGroupHigherMBU && business_unit) { // Metrics for bu view merch
            const notMetricsForHigherMBU = ['actual_catchment_rate', 'actual_conversion_rate', 'actual_space_tfa', 'actual_mall_foot_traffic', 'actual_sm_store_foot_traffic', 'actual_frequency_of_visit'];
            return availableOptions.filter(option => !notMetricsForHigherMBU.includes(option.value));

        } else if (isGroupHigherMBU) { // Metrics for bu view ops
            const notMetricsForHigherMBU = ['actual_catchment_rate', 'actual_conversion_rate', 'actual_space_tfa', 'actual_space_nsa', 'actual_mall_foot_traffic', 'actual_sm_store_foot_traffic', 'actual_frequency_of_visit'];
            return availableOptions.filter(option => !notMetricsForHigherMBU.includes(option.value));

        } else if (store) { // Metrics for branch view ops
            const notMetricsForBranchView = ['actual_catchment_rate', 'actual_conversion_rate', 'actual_units_sold', 'actual_units_sold_per_txn', 'actual_frequency_of_visit'];
            return availableOptions.filter(option => !notMetricsForBranchView.includes(option.value));

        } else if (metric_options === 'bu_view_merch_customer') {
            const notMetricsForBUViewMerchCustomer = ['actual_catchment_rate', 'actual_conversion_rate', 'actual_space_tfa', 'actual_space_nsa', 'actual_mall_foot_traffic', 'actual_sm_store_foot_traffic', 'actual_units_sold', 'actual_smac_sales'];
            const buViewMerchCustomerMetrics = availableOptions.filter(option => !notMetricsForBUViewMerchCustomer.includes(option.value));
            // add the prefix 'SMAC' on the metrics which are specific for BU View > Merch > Customers
            buViewMerchCustomerMetrics.forEach(metric => { metric.label = 'SMAC ' + metric.label; });
            return buViewMerchCustomerMetrics;
        }

        return availableOptions;
    }, [isGroupChannel, isGroupBU, isGroupZone, isGroupHigherMBU, store, business_unit, metric_options]);

    const selectedValue = getSelectedOption(filters?.metric_column, options)

    return (
        <>
            <ConditionalSelectComponent
                id="metric_filter"
                labelId="metric_filter-label"
                options={options}
                label="Metric"
                value={selectedValue}
                onSelectChange={(event, newValue) => {
                    windowSize.isOnMobile
                        ? onFilterChange('metric_column', event?.target?.value)
                        : onFilterChange('metric_column', newValue?.value,);
                }}
                startAdornment={'Metric:'}
                endAdornment={false}
                clearSelected={() => onFilterChange('metric_column', '')}
                disableClearable={true}
            />
        </>
    );
}
