import React from 'react';
import {useTableDetail, useTableViews} from "../../../../../common/API/hooks";
import StatusDisplayWithDetail from "./StatusDisplayWithDetail";
import Box from "@mui/material/Box";
import {Tooltip} from "react-tooltip";
import {useTableViewsMutation} from "./hooks";
import Button from "@mui/material/Button";


export default function SMIPViewsStatusDisplay({tableName, views}) {
    const {data: tableDetail, isLoading: isLoadingTableDetail} = useTableDetail(tableName, {})
    const {data, isLoading} = useTableViews(tableName, {})
    const tableViewsMutation = useTableViewsMutation()

    const viewsWithApiData = React.useMemo(() => {
        if (!data) return views;
        return views.map(v => {
            const dbData = data.find(d => d.name === v.name)
            const formattedSql = dbData?.view_sql?.replace('\r\n', '\n')


            return {
                ...v,
                db: dbData ? {
                    ...dbData,
                    view_sql: formattedSql
                } : null
            }
        })
    }, [data, views]);

    const missingViews = React.useMemo(() => {
        if (!data) return [];
        return viewsWithApiData.filter(v => {
            return !v.db
        })
    }, [viewsWithApiData, data])

    const viewDefinitionMismatches = React.useMemo(() => {
        if (!data) return [];
        return viewsWithApiData.filter(v => {
            return !!v.db
        }).filter(v => {
            const sqlFromRegistry = v.sql.replaceAll(/[\r\n ]+/gi, " ").toLowerCase();
            const sqlFromDB = v.db.view_sql?.replaceAll(/[\r\n ]+/gi, " ").toLowerCase();
            return sqlFromDB != sqlFromRegistry
        })
    }, [data, viewsWithApiData])

    const fixMissing = () => {
        missingViews.forEach(view => {
            tableViewsMutation.mutate({
                table: tableDetail.id,
                view_sql: view.sql,
                ...view
            })
        })
    }

    const fixMismatch = () => {
        viewDefinitionMismatches.forEach(view => {
            tableViewsMutation.mutate({
                id: view.db.id,
                table: tableDetail.id,
                view_sql: view.sql,
                ...view
            })
        })
    }

    return <StatusDisplayWithDetail
        isLoading={isLoading}
        isSuccess={missingViews.length === 0 && viewDefinitionMismatches.length === 0}>
        <Box>
            {views.length} SMIP Views
        </Box>
        {
            missingViews.length > 0 && (
                <Box>
                    Missing:
                    <ul>
                        {
                            missingViews.map(v => {
                                return <li>{v.name}</li>
                            })
                        }
                    </ul>
                    <Button onClick={fixMissing}>
                        FIX MISSING
                    </Button>
                </Box>
            )
        }
        {
            viewDefinitionMismatches.length > 0 && (
                <Box>
                    Mismatch SQL:
                    <ul>
                        {
                            viewDefinitionMismatches.map(v => {
                                return <>
                                    <li id={v.name}>
                                        {v.name}

                                    </li>
                                    <Tooltip anchorSelect={`#${v.name}`} clickable>
                                        <Box sx={{maxWidth: '50vw'}}>
                                            <pre>
                                                {v.sql}
                                            </pre>
                                            <hr/>

                                            <pre>
                                                {v.db.view_sql}
                                            </pre>
                                            xs
                                        </Box>
                                    </Tooltip>
                                </>
                            })
                        }
                    </ul>
                    <Button onClick={fixMismatch}>
                        FIX MISMATCH
                    </Button>
                </Box>
            )
        }

    </StatusDisplayWithDetail>
}