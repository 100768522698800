import React, {useContext} from 'react';
import {DashboardMetaContext} from "../contexts";


export default function DashboardMetaProvider({children, dashboardDefinition, additionalContext= {}}) {
    return <DashboardMetaContext.Provider value={{
        ...dashboardDefinition,
        context: additionalContext
    }}>
        {children}
    </DashboardMetaContext.Provider>;
}

export function useDashboardPath() {
    const {path} = useContext(DashboardMetaContext)

    return path
}

export function useDashboardComponents() {
    const {components} = useContext(DashboardMetaContext);

    return components
}