import React from 'react';
import CircularProgress from "@mui/material/CircularProgress";
import {Check, Error} from "@mui/icons-material";


export default function StatusDisplay({isLoading=true, isSuccess=true, size="1em"}) {

    if (isLoading) {
        return (
            <CircularProgress size={size} sx={{marginRight: "1em"}}/>
        )
    }
    if (!isSuccess) {
        return (
            <Error size={size} sx={{marginRight: "0.5em"}} color={"error"}/>
        )
    }

    return (
        <Check size={size} sx={{marginRight: "0.5em"}} color={"success"}/>
    )
}