import {useIsComponentUsable} from "./hooks";

export default function BlacklistableComponentWrapper({children, componentId, alternateChildren=null}) {
    const isVisible = useIsComponentUsable(componentId)

    if (!isVisible) {
        return alternateChildren
    }

    return (children)
}