export const sm_store_performance_dictionary = {
    component: "SM Store Performance",
    enabled: true,
    subcomponents: [
        {
            component: "Topline Scorecard",
            enabled: true,
            subcomponents: [
                {
                    component: "Sales",
                    enabled: true,
                    subcomponents: [
                        { component: "Total Sales", enabled: true },
                        { component: "Total Plan", enabled: true },
                        { component: "vs Plan", enabled: true },
                        { component: "vs Last Year", enabled: true },
                    ]
                },
                {
                    component: "Transaction",
                    enabled: true,
                    subcomponents: [
                        { component: "Total Sales", enabled: true },
                        { component: "Total Plan", enabled: true },
                        { component: "vs Plan", enabled: true },
                        { component: "vs Last Year", enabled: true },
                    ]
                },
                {
                    component: "Basket Size",
                    enabled: true,
                    subcomponents: [
                        { component: "Total Sales", enabled: true },
                        { component: "Total Plan", enabled: true },
                        { component: "vs Plan", enabled: true },
                        { component: "vs Last Year", enabled: true },
                    ]
                },
                {
                    component: "SMAC Sales",
                    enabled: true,
                    subcomponents: [
                        { component: "Total Sales", enabled: true },
                        { component: "Total Plan", enabled: true },
                        { component: "vs Plan", enabled: true },
                        { component: "vs Last Year", enabled: true },
                    ]
                },
                {
                    component: "Catchment Rate",
                    enabled: true,
                    subcomponents: [
                        { component: "Total Sales", enabled: true },
                        { component: "Total Plan", enabled: true },
                        { component: "vs Plan", enabled: true },
                        { component: "vs Last Year", enabled: true },
                    ]
                },
                {
                    component: "Conversion Rate",
                    enabled: true,
                    subcomponents: [
                        { component: "Total Sales", enabled: true },
                        { component: "Total Plan", enabled: true },
                        { component: "vs Plan", enabled: true },
                        { component: "vs Last Year", enabled: true },
                    ]
                },
                {
                    component: "Mall Foot Traffic",
                    enabled: true,
                    subcomponents: [
                        { component: "Total Sales", enabled: true },
                        { component: "Total Plan", enabled: true },
                        { component: "vs Plan", enabled: true },
                        { component: "vs Last Year", enabled: true },
                    ]
                },
                {
                    component: "Dept Store Foot Traffic",
                    enabled: true,
                    subcomponents: [
                        { component: "Total Sales", enabled: true },
                        { component: "Total Plan", enabled: true },
                        { component: "vs Plan", enabled: true },
                        { component: "vs Last Year", enabled: true },
                    ]
                },
                {
                    component: "Space Productivity NSA",
                    enabled: true,
                    subcomponents: [
                        { component: "Total Sales", enabled: true },
                        { component: "Total Plan", enabled: true },
                        { component: "vs Plan", enabled: true },
                        { component: "vs Last Year", enabled: true },
                    ]
                },
                {
                    component: "Space Productivity TFA",
                    enabled: true,
                    subcomponents: [
                        { component: "Total Sales", enabled: true },
                        { component: "Total Plan", enabled: true },
                        { component: "vs Plan", enabled: true },
                        { component: "vs Last Year", enabled: true },
                    ]
                }
            ]
        },
        {
            component: "Performance per Group",
            enabled: true,
            subcomponents: [
                {
                    component: "Channel",
                    enabled: true,
                    subcomponents: [
                        { component: "Total Sales", enabled: true },
                        { component: "Percent Contribution", enabled: true },
                        { component: "Total Plan", enabled: true },
                        { component: "vs Plan", enabled: true },
                        { component: "vs Last Year", enabled: true },
                        { component: "vs 2019", enabled: true },
                        { component: "12 Months Sales Trend vs Plan", enabled: true }
                    ]
                },
                {
                    component: "Business Units",
                    enabled: true,
                    subcomponents: [
                        { component: "Total Sales", enabled: true },
                        { component: "Percent Contribution", enabled: true },
                        { component: "Total Plan", enabled: true },
                        { component: "vs Plan", enabled: true },
                        { component: "vs Last Year", enabled: true },
                        { component: "vs 2019", enabled: true },
                        { component: "12 Months Sales Trend vs Plan", enabled: true }
                    ]
                },
                {
                    component: "Zone",
                    enabled: true,
                    subcomponents: [
                        { component: "Total Sales", enabled: true },
                        { component: "Percent Contribution", enabled: true },
                        { component: "Total Plan", enabled: true },
                        { component: "vs Plan", enabled: true },
                        { component: "vs Last Year", enabled: true },
                        { component: "vs 2019", enabled: true },
                        { component: "12 Months Sales Trend vs Plan", enabled: true }
                    ]
                }
            ]
        },
        {
            component: "Store Performance",
            enabled: true,
            subcomponents: [
                {
                    component: "Sales",
                    enabled: true,
                    subcomponents: [
                        { component: "Total Sales", enabled: true },
                        { component: "Percent Contribution", enabled: true },
                        { component: "vs Plan", enabled: true },
                        { component: "vs Last Year", enabled: true },
                        { component: "vs 2019", enabled: true },
                        { component: "12 Months Sales Trend vs Plan", enabled: true }
                    ]
                }
            ]
        }
    ]
};


export const bu_view_merchandise_dictionary = {
    component: "Business Unit View - Shoes",
    enabled: true,
    subcomponents: [
        {
            component: "Topline Scorecard",
            enabled: true,
            subcomponents:
                [
                    {
                        component: "Sales",
                        enabled: true,
                        subcomponents: [
                            { component: "Total Sales", enabled: true },
                            { component: "Total Sales Plan", enabled: true },
                            { component: "vs Sales Plan", enabled: true },
                            { component: "vs Sales Last Year", enabled: true },
                        ]
                    },
                    {
                        component: "Transaction",
                        enabled: true,
                        subcomponents: [
                            { component: "Total Transaction", enabled: true },
                            { component: "Total Transaction Plan", enabled: true },
                            { component: "vs Transaction Plan", enabled: true },
                            { component: "vs Transaction Last Year", enabled: true },
                        ]
                    },
                    {
                        component: "Basket Size",
                        enabled: true,
                        subcomponents: [
                            { component: "Total Basket Size", enabled: true },
                            { component: "Total Basket Size Plan", enabled: true },
                            { component: "vs Basket Size Plan", enabled: true },
                            { component: "vs Basket Size Last Year", enabled: true },
                        ]
                    },
                    {
                        component: "SMAC Sales",
                        enabled: true,
                        subcomponents: [
                            { component: "Total SMAC Sales", enabled: true },
                            { component: "Total SMAC Sales Plan", enabled: true },
                            { component: "vs SMAC Sales Plan", enabled: true },
                            { component: "vs SMAC Sales Last Year", enabled: true },
                        ]
                    },
                    {
                        component: "Space Productivity NSA",
                        enabled: true,
                        subcomponents: [
                            { component: "Total Space Productivity NSA", enabled: true },
                            { component: "Total Space Productivity NSA Plan", enabled: true },
                            { component: "vs Space Productivity NSA Plan", enabled: true },
                            { component: "vs Space Productivity NSA Last Year", enabled: true },
                        ]
                    },
                    {
                        component: "Units Sold",
                        enabled: true,
                        subcomponents: [
                            { component: "Total Units Sold", enabled: true },
                            { component: "Total Units Sold Plan", enabled: true },
                            { component: "vs Units Sold Plan", enabled: true },
                            { component: "vs Units Sold Last Year", enabled: true },
                        ]
                    },
                    {
                        component: "Units Sold Per Transaction",
                        enabled: true,
                        subcomponents: [
                            { component: "Total Units Sold Per Transaction", enabled: true },
                            { component: "Total Units Sold Per Transaction Plan", enabled: true },
                            { component: "vs Units Sold Per Transaction Plan", enabled: true },
                            { component: "vs Units Sold Per Transaction Last Year", enabled: true },
                        ]
                    },
                    {
                        component: "Unit Price",
                        enabled: true,
                        subcomponents: [
                            { component: "Total Unit Price", enabled: true },
                            { component: "Total Unit Price Plan", enabled: true },
                            { component: "vs Unit Price Plan", enabled: true },
                            { component: "vs Unit Price Last Year", enabled: true },
                        ]
                    }
                ]
        },
    ]
}
