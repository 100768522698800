import {Checkbox, Grid, List, ListItemButton, ListItemIcon, ListItemText, Paper, Typography} from "@mui/material";
import React from "react";

export function CheckboxListBox({selected, options, onChange, onDoubleClick, noDataMessage = "No available options"}) {
    const handleToggle = (value) => {
        if (selected.includes(value)) {
            onChange(selected.filter(item => item !== value))
        } else {
            onChange([...selected, value])
        }
    }

    if (!options || options.length === 0) {
        return (
            <Paper sx={{minWidth: '200px', width: '25vw'}}>
                <Grid container sx={{height: '30vh', overflow: 'auto'}}>
                    <Grid container justifyContent={'center'} alignItems={'center'}>
                        <Typography alignItems={'center'} py={1}>
                            {noDataMessage}
                        </Typography>
                    </Grid>
                </Grid>
            </Paper>
        )
    }

    return (
        <Paper sx={{minWidth: '200px', width: '25vw'}}>
            <Grid container sx={{height: '30vh', overflow: 'auto'}}>
                <Grid width={'100%'}>
                    <List dense component="div" role="list">
                        {options?.map((option) => {
                            const labelId = `transfer-list-item-${option.value}-label`;

                            return (
                                <ListItemButton
                                    key={option.value}
                                    role="listitem"
                                    onClick={() => handleToggle(option.value)}
                                    onDoubleClick={() => onDoubleClick && onDoubleClick(option.value)}
                                >
                                    <ListItemIcon>
                                        <Checkbox
                                            checked={selected.includes(option.value)}
                                            tabIndex={-1}
                                            disableRipple
                                            inputProps={{
                                                'aria-labelledby': labelId,
                                            }}
                                        />
                                    </ListItemIcon>
                                    <ListItemText id={labelId} primary={option.label}/>
                                </ListItemButton>
                            );
                        })}
                    </List>
                </Grid>
            </Grid>
        </Paper>
    )
}