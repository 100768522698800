import PersonIcon from "@mui/icons-material/Person";
import GroupsIcon from "@mui/icons-material/Groups";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import KeyIcon from "@mui/icons-material/Key";
import RegistrationIcon from "@mui/icons-material/HowToReg";
import TimelineIcon from "@mui/icons-material/Timeline";

export const defaultStyle = {width: '2em', height: 'auto', marginTop: '12px'};
export const MANAGEMENT_MODULES = {
    userManagement: {
        name: 'User Management',
        icon: <PersonIcon sx={defaultStyle}/>,
        path: '/user-management',
    },
    groupManagement: {
        name: 'Group Management',
        icon: <GroupsIcon sx={defaultStyle}/>,
        path: '/group-management',
    },
    modulePermission: {
        name: 'Module Permission',
        icon: <AutoStoriesIcon sx={defaultStyle}/>,
        path: '/module-permission',
    },
    permissionManagement: {
        name: 'Dashboard Permissions',
        icon: <KeyIcon sx={defaultStyle}/>,
        path: '/permission-management',
    },
    registrations: {
        name: 'Registrations',
        icon: <RegistrationIcon sx={defaultStyle}/>,
        path: '/registration-management',
    },
    loginHistory: {
        name: 'Login History',
        icon: <TimelineIcon sx={defaultStyle}/>,
        path: '/login-history',
    },
};