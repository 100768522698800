import AcquisitionContent from "./Acquisition";
import RegistrationContent from "./Registration";
import UsageContent from "./Usage";

export default function TabContent({selectedTab, blacklistedComponents}){

    switch(selectedTab){
        case 'registration':
            return <RegistrationContent blacklistedComponents={blacklistedComponents}/>;
        case 'usage':
            return <UsageContent />;
        case 'acquisition':
            return <AcquisitionContent />;
        default: 
            <></> // show nothing 
    }
 
}