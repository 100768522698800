import {useMutation, useQueryClient} from "react-query";
import ApiClient from "../../../../../common/API";
import {toast} from "react-toastify";

export function useTableColumnMutation() {
    const queryClient = useQueryClient();

    return useMutation((columnData) => {
        if (columnData.id) {
            // apply as PUT
            const putPromise = ApiClient().put(`/data/column/${columnData.id}/`, columnData);
            return toast.promise(putPromise, {
                pending: 'Updating column...',
                success: 'Column updated!',
                error: 'Failed to update column'
            })
        } else {
            // create new
            const createPromise = ApiClient().post('/data/column/', columnData);

            return toast.promise(createPromise, {
                pending: `Creating column ${columnData.name}...`,
                success: `Column ${columnData.name} created!`,
                error: `Failed to create column ${columnData.name}`
            })
        }
    }, {
        onSuccess: () => {
            return queryClient.invalidateQueries(['data', 'table'])
        }
    })
}

export function useTableColumnAggregateMutation() {
    // this mutation just tries to add a new column aggregate
    // API will take care of duplications

    const queryClient = useQueryClient();

    return useMutation(({column, aggregateType}) => {
        console.log("Creating aggregate for column", column, aggregateType)
        if (aggregateType === 'sum') {
            const createSumPromise = ApiClient().post(`/data/column/${column.id}/add_sum_aggregate/`, {});
            return toast.promise(createSumPromise, {
                pending: `Adding special sum aggregate to ${column.name}...`,
                success: `Special sum aggregate added to ${column.name}!`,
                error: `Failed to add special sum aggregate to ${column.name}`
            })
        } else if (aggregateType === 'avg') {
            const createAvgPromise = ApiClient().post(`/data/column/${column.id}/add_average_aggregate/`, {});
            return toast.promise(createAvgPromise, {
                pending: `Adding special avg aggregate to ${column.name}...`,
                success: `Special avg aggregate added to ${column.name}!`,
                error: `Failed to add special avg aggregate to ${column.name}`
            })
        } else {
            const createAutoAggregatesPromise = ApiClient().post(`/data/column/${column.id}/add_auto_aggregates/`, {});
            return toast.promise(createAutoAggregatesPromise, {
                pending: `Adding auto aggregates to ${column.name}...`,
                success: `Auto aggregates added to ${column.name}!`,
                error: `Failed to add auto aggregates to ${column.name}`
            })
        }
    }, {
        onSuccess: () => {
            return queryClient.invalidateQueries(['data', 'table'])
        }
    })
}


export function useTableViewsMutation() {
    const queryClient = useQueryClient();

    return useMutation((viewData) => {
        if (viewData.id) {
            const putPromise = ApiClient().put(`/data/views/${viewData.id}/`, viewData);
            return toast.promise(putPromise, {
                pending: `Updating view ${viewData.name}...`,
                success: `View ${viewData.name} updated!`,
                error: `Failed to update view ${viewData.name}`
            })
        } else {
            const createPromise = ApiClient().post('/data/views/', viewData);
            return toast.promise(createPromise, {
                pending: `Creating view ${viewData.name}...`,
                success: `View ${viewData.name} created!`,
                error: `Failed to create view ${viewData.name}`
            })
        }
    }, {
        onSuccess: () => {
            return queryClient.invalidateQueries(['data', 'table'])
        }
    })
}
