import React from "react";
import LoadingComponent from "../../../../../common/components/LoadingComponent";
import { Box, Grid, Typography } from "@mui/material";
import NumericValueDisplay from "../../../common/presentation/numericValueDisplay";
import TableComponentV2 from "../../../../../common/components/TableComponentV2";
import { useSelectorValue } from "../../../common/providers/selectorProvider";
import { IndicatorCellBox } from "../../../Customers/Transactions/BUGroupShareOfWallet";
import BarIndicator from "../../../common/presentation/barIndicator";
import { convertToValidNumber, sortArrObjectsBasedOnGivenArray, sortByOrder } from "../../../../../common/utils";
import { SEGMENT_TYPE_RFM } from "../../../CustomerAnalytics/constants";
import { DIMENSION_GENERATION, LABEL_ALL_BRANCH } from "../../hooks";
import NoAvailableDataDisplay from "../../NoAvailableDataDisplay";
import RFMSegmentsTooltip from "./RFMSegmentsTooltip";


function getSegmentsOrderArray(segmentType) {
    if (segmentType === SEGMENT_TYPE_RFM) {
        return [
            "Major Contributors",
            "Regular Big-Spenders",
            "Loyal Customers",
            "Shouldn't Lose",
            "Potential Loyalist",
            "Needs Engagement",
            "Recent Low-Spenders",
            "High-Value Outliers",
            "Frequent Volume Buyers",
            "Promising Mid-Spenders",
            "Low-Spenders",
            "Slipping Low-Value Customers",
            "Hibernating Mid-Spenders",
            "Disengaged Low-Spenders",
            "Dormant Low-Spenders"
        ];
    } else if (segmentType === DIMENSION_GENERATION) {
        return [
            'Gen Alpha',
            'Gen Z',
            'Millenials',
            'Gen X',
            'Boomers',
            'No Data',
        ];
    } else { // Card Type
        return [
            'Start',
            'SMAC', // Blue
            'Prestige',
        ]
    }
}

const thickBorder = '2px solid #C3C3C3!important'


export default function CustomerSegmentsTable({ data, isLoading }) {
    const segmentType = useSelectorValue('segment_type');
    const orderArray = getSegmentsOrderArray(segmentType);
    const branch = useSelectorValue('branch')

    const customerSegmentsTableColumns = React.useMemo(() => {

        function setTotalStyle(row) {
            const pk = row?.original?.pk;

            if (pk.toString().toLowerCase() === 'total') {
                return {
                    fontWeight: 'bold',
                }
            }
            return {}
        }

        return [
            {
                header: segmentType,
                accessorKey: 'pk',
                customStyle: { lineHeight: "2.5em", borderRight: thickBorder },
                cell: ({ row, getValue }) => {
                    const value = getValue();
                    if(segmentType === SEGMENT_TYPE_RFM && !!value) {
                        return <Box sx={{ marginX: 'auto', marginLeft: "1em", width: "13em", textWrap: "nowrap", ...setTotalStyle(row) }}>
                            <Grid item xs={12} sm={12}>  <RFMSegmentsTooltip value={value} /> </Grid>
                        </Box>
                    } else {
                        return <Box sx={{ marginX: 'auto', marginLeft: "1em", width: "13em", textWrap: "nowrap", ...setTotalStyle(row) }}>
                            {value === 'SMAC' ? 'Blue' : value}
                        </Box>
                    }
                },
            },
            {
                id: "branch",
                header: branch === 'All Branch' ? LABEL_ALL_BRANCH : branch,
                columns: [
                    {
                        header: 'Member Count',
                        accessorKey: 'member_count',
                        cell: ({ row, getValue }) => {
                            return <Box sx={{ marginX: 'auto', width: "4em", textWrap: "nowrap", ...setTotalStyle(row) }}>
                                <NumericValueDisplay value={getValue()} isAbbreviated={true} />
                            </Box>
                        },
                        customStyle: {
                            width: '8rem',
                            textAlign: 'left',
                        },
                        headerStyle: {
                            backgroundColor: "#E7F1F8"
                        }
                    },
                    {
                        header: 'vs Last Year',
                        id: 'member_count_vs_ly',
                        accessorKey: 'member_count_vs_ly',
                        cell: ({ row, getValue }) => {
                            const value = getValue()
                            return value ? <IndicatorCellBox>
                                <BarIndicator colorFunctionFor={'sales'} value={convertToValidNumber(value)?.toFixed(1)} isInverted={false} isValueColorCoded={true} isOnTable={true} label="vs last year" />
                            </IndicatorCellBox> : '-'
                        },
                        customStyle: {
                            width: '8rem',
                            lineHeight: "2.5em",
                            textAlign: 'center',
                        },
                        headerStyle: {
                            backgroundColor: "#E7F1F8"
                        }
                    },
                    {
                        header: 'Avg. Transactions',
                        id: 'avg_transactions',
                        accessorKey: 'avg_txn',
                        cell: ({ row, getValue }) => {
                            return <Box sx={{ marginX: 'auto', width: "4em", textWrap: "nowrap", ...setTotalStyle(row) }}>
                                <NumericValueDisplay value={getValue()} isAbbreviated={true} />
                            </Box>
                        },
                        customStyle: {
                            width: '8rem',
                            textAlign: 'center',
                            borderLeft: thickBorder
                        },
                        headerStyle: {
                            backgroundColor: "#E7F1F8"
                        }
                    },
                    {
                        header: 'vs Last Year',
                        id: 'vs_ly_transactions',
                        accessorKey: 'avg_txn_vs_ly',
                        cell: ({ row, getValue }) => {
                            const value = getValue()
                            return value ? <IndicatorCellBox>
                                <BarIndicator colorFunctionFor={'sales'} value={convertToValidNumber(value)?.toFixed(1)} isInverted={false} isValueColorCoded={true} isOnTable={true} label="vs last year" />
                            </IndicatorCellBox> : '-'
                        },
                        customStyle: {
                            width: '8rem',
                            textAlign: 'center',
                        },
                        headerStyle: {
                            backgroundColor: "#E7F1F8"
                        }
                    },
                    {
                        header: 'Avg. Trx Value',
                        id: 'avg_txn_value',
                        accessorKey: 'atv',
                        cell: ({ row, getValue }) => {
                            return <Box sx={{ marginX: 'auto', width: "4em", textWrap: "nowrap", ...setTotalStyle(row) }}>
                                <NumericValueDisplay value={getValue()} isAbbreviated={true} prefix={'₱'} />
                            </Box>
                        },
                        customStyle: {
                            width: '8rem',
                            textAlign: 'left',
                            borderLeft: thickBorder
                        },
                        headerStyle: {
                            backgroundColor: "#E7F1F8"
                        }
                    },
                    {
                        header: 'vs Last Year',
                        id: 'vs_ly_trx_value',
                        accessorKey: 'atv_vs_ly',
                        cell: ({ row, getValue }) => {
                            const value = getValue()
                            return value ? <IndicatorCellBox>
                                <BarIndicator colorFunctionFor={'sales'} value={convertToValidNumber(value)?.toFixed(1)} isInverted={false} isValueColorCoded={true} isOnTable={true} label="vs last year" />
                            </IndicatorCellBox> : '-'
                        },
                        customStyle: {
                            width: '8rem',
                            textAlign: 'center',
                        },
                        headerStyle: {
                            backgroundColor: "#E7F1F8"
                        }
                    },
                    {
                        header: 'Freq of Visit',
                        id: 'avg_visits',
                        accessorKey: 'visit_freq',
                        cell: ({ row, getValue }) => {
                            return <Box sx={{ marginX: 'auto', width: "4em", textWrap: "nowrap", ...setTotalStyle(row) }}>
                                <NumericValueDisplay value={getValue()?.toFixed(0)} isAbbreviated={true} />
                            </Box>
                        },
                        customStyle: {
                            width: '8rem',
                            textAlign: 'center',
                            borderLeft: thickBorder
                        },
                        headerStyle: {
                            backgroundColor: "#E7F1F8"
                        }
                    },
                    {
                        header: 'vs Last Year',
                        id: 'vs_ly_frequency',
                        accessorKey: 'visit_freq_vs_ly',
                        cell: ({ row, getValue }) => {
                            const value = getValue()
                            return value ? <IndicatorCellBox>
                                <BarIndicator colorFunctionFor={'sales'} value={convertToValidNumber(value)?.toFixed(1)} isInverted={false} isValueColorCoded={true} isOnTable={true} label="vs last year" />
                            </IndicatorCellBox> : '-'
                        },
                        customStyle: {
                            width: '8rem',
                            textAlign: 'center',
                        },
                        headerStyle: {
                            backgroundColor: "#E7F1F8"
                        }
                    },
                    {
                        header: 'Avg. Sales', // formerly 'Avg. Total Spending',
                        accessorKey: 'avg_sales',
                        cell: ({ row, getValue }) => {
                            return <Box sx={{ marginX: 'auto', width: "4em", textWrap: "nowrap", ...setTotalStyle(row) }}>
                                <NumericValueDisplay value={getValue()} isAbbreviated={true} prefix={'₱'} />
                            </Box>
                        },
                        customStyle: {
                            width: '8rem',
                            textAlign: 'left',
                            borderLeft: thickBorder
                        },
                        headerStyle: {
                            backgroundColor: "#E7F1F8"
                        }
                    },
                    {
                        header: 'vs Last Year',
                        id: 'vs_ly_sales',
                        accessorKey: 'avg_sales_vs_ly',
                        cell: ({ row, getValue }) => {
                            const value = getValue()
                            return value ? <IndicatorCellBox>
                                <BarIndicator colorFunctionFor={'sales'} value={convertToValidNumber(value)?.toFixed(1)} isInverted={false} isValueColorCoded={true} isOnTable={true} label="vs last year" />
                            </IndicatorCellBox> : '-'
                        },
                        customStyle: {
                            width: '8rem',
                            textAlign: 'center',
                        },
                        headerStyle: {
                            backgroundColor: "#E7F1F8"
                        }
                    },
                    {
                        header: 'Avg. Spend / Visit',
                        accessorKey: 'avg_spend_visit',
                        cell: ({ row, getValue }) => {
                            return <Box sx={{ marginX: 'auto', width: "4em", textWrap: "nowrap", ...setTotalStyle(row) }}>
                                <NumericValueDisplay value={getValue()} isAbbreviated={true} prefix={'₱'} />
                            </Box>
                        },
                        customStyle: {
                            width: '8rem',
                            textAlign: 'left',
                            borderLeft: thickBorder
                        },
                        headerStyle: {
                            backgroundColor: "#E7F1F8"
                        }
                    },
                    {
                        header: 'vs Last Year',
                        id: 'vs_ly_daily_spend',
                        accessorKey: 'avg_spend_visit_vs_ly',
                        cell: ({ row, getValue }) => {
                            const value = getValue()
                            return value ? <IndicatorCellBox>
                                <BarIndicator colorFunctionFor={'sales'} value={convertToValidNumber(value)?.toFixed(1)} isInverted={false} isValueColorCoded={true} isOnTable={true} label="vs last year" />
                            </IndicatorCellBox> : '-'
                        },
                        customStyle: {
                            width: '8rem',
                            textAlign: 'center',
                        },
                        headerStyle: {
                            backgroundColor: "#E7F1F8"
                        }
                    },
                ]
            }
        ]
    }, [data, branch,segmentType])

    if (isLoading) {
        return <LoadingComponent />
    }

    if (!data || data?.length === 0) {
        return <NoAvailableDataDisplay />
    }

    return (
        <TableComponentV2
            initialSort={{ id: 'pk', asc: true }}
            columns={sortArrObjectsBasedOnGivenArray(customerSegmentsTableColumns,orderArray, 'pk')}
            data={data}
            tableId={'customerSegments'}
            isCompact={true}
            customTableHeaderStyle={{
                backgroundColor: '#caddeb',
                textAlign: 'center'
            }}
        />
    )
}