import React from "react";
import LoadingComponent from "../../../../common/components/LoadingComponent";
import {Checkbox} from "@mui/material";
import CheckBoxRoundedIcon from "@mui/icons-material/CheckBoxRounded";
import DisabledByDefaultRoundedIcon from "@mui/icons-material/DisabledByDefaultRounded";
import {useModuleList} from "../../../../common/API/hooks";
import {useFindDashboardDefinitionByName, useModuleAccessChecker} from "../hooks";


export default function HasAccessToDashboardCheckboxDisplay({dashboardName, userId, groupId}) {

    const {data: moduleList, isLoading: moduleLoading} = useModuleList();

    const dashboardDefinition = useFindDashboardDefinitionByName(dashboardName);
    const {hasAccessToModule, isLoading: isLoadingModuleAccessChecker} = useModuleAccessChecker(userId, groupId)

    if (moduleLoading || isLoadingModuleAccessChecker) {
        return (
            <LoadingComponent/>
        )
    }

    if (!dashboardDefinition) {
        return null;
    }

    const entryPoints = dashboardDefinition?.entry_points || [];

    const matchingModules = moduleList?.filter(module => {
        return entryPoints.includes(module.name)
    });

    const hasAnyAccess = matchingModules?.some(module => hasAccessToModule(module))


    return (
        <Checkbox
            checked={hasAnyAccess}
            icon={
                hasAnyAccess ?
                    <CheckBoxRoundedIcon fontSize='medium'/> :
                    <DisabledByDefaultRoundedIcon fontSize='medium'/>
            }
        />
    )
}