import { useQuery } from "react-query";
import ApiClient from "../../../common/API";
import {useMemo} from "react";
import {useGroupList, useModuleList, useUserList} from "../../../common/API/hooks";


export function useOwnersPermissionList() {
    const { data, isLoading } = useQuery(
        ["owers", "owners_lists"],
        () => {
            return ApiClient().get(`dashboard/common-dashboard/`, {}).then((res) => res.data)
        },
        {
            refetchWindowFocus: false,
            staleTime: 600000,
        }
    );
    return {
        data,
        isLoading,
    };
}

export function useUserData() {
    const { data, isLoading } = useQuery(
        ["user", "user_lists"],
        () => {
            return ApiClient().get(`dashboard/common-dashboard/user`, {}).then((res) => res.data)
        },
        {
            refetchWindowFocus: false,
            staleTime: 600000,
        }
    );
    return {
        data,
        isLoading,
    };
}


export function useGroupPermissionList() {
    const { data, isLoading } = useQuery(
        ["group", "group_lists"],
        () => {
            return ApiClient().get(`dashboard/common-dashboard/group`, {}).then((res) => res.data)
        },
        {
            refetchWindowFocus: false,
            staleTime: 600000,
        }
    );
    return {
        data,
        isLoading,
    };
}


export function useDataFilterList() {
    const { data, isLoading } = useQuery(
        ["user", "data_filters"],
        () => {
            return ApiClient().get(`dashboard/common-dashboard/data-filters`, {}).then((res) => res.data)
        },
        {
            refetchWindowFocus: false,
            staleTime: 600000,
        }
    );
    return {
        data,
        isLoading,
    };
}

export function useUserPermissionAccess() {
    const { data, isLoading } = useQuery(
        ["user", "permissions"],
        () => {
            return ApiClient().get(`dashboard/common-dashboard/permissions`, {}).then((res) => res.data)
        },
        {
            refetchWindowFocus: false,
            staleTime: 600000,
        }
    );
    return {
        data,
        isLoading,
    };
}


/*
* This hook is used to find dashboard definition by name.
* It looks for the dashboard definition in the dashboardRegistry.
* @param {string} dashboardName - The name of the dashboard to find.
* @returns {Object} - The dashboard definition.
*/
export function useFindDashboardDefinitionByName(dashboardName) {
    const dashboardDefinition = useMemo(() => {
        return Object.values(window.dashboardRegistry).find(dashboard => dashboard.name === dashboardName);
    }, [dashboardName])

    return dashboardDefinition;
}


export function useUserEntry(userId) {
    const {data: userList, isLoading: isLoadingUserList} = useUserList({}, {enabled: !!userId});
    if (!userId) {
        return {
            entry: null,
            isLoading: false
        }
    }
    return {
        entry: userList?.find(user => user.id === parseInt(userId)),
        isLoading: isLoadingUserList
    }
}

export function useGroupEntry(groupId) {
    const {data: groupList, isLoading: groupLoading} = useGroupList({}, {enabled: !!groupId});
    if (!groupId) {
        return {
            entry: null,
            isLoading: false
        }
    }

    return {
        entry: groupList?.find(group => group.group_id === parseInt(groupId)),
        isLoading: groupLoading
    }
}


export function useModuleAccessChecker(userId=null, groupId=null) {
    const {entry: userEntry, isLoading: isLoadingUserList} = useUserEntry(userId);
    const {entry: groupEntry, isLoading: groupLoading} = useGroupEntry(groupId);

    if (groupLoading || isLoadingUserList) {
        return {
            isLoading: true,
            hasAccessToModule: () => false,
            getUsersGroupsAllowedForModule: () => []
        }
    }


    const hasAccessToModule = (module) => {
        if (userEntry) {
            const hasDirectAccess = module.allowed_users && module.allowed_users.includes(userEntry.username)
            const userGroups = userEntry.groups || []
            const hasGroupAccess = module.allowed_groups && module.allowed_groups.some(group => userGroups.includes(group))
            return hasDirectAccess || hasGroupAccess
        }
        if (groupEntry && module.allowed_groups) {
            return module.allowed_groups.includes(groupEntry.name)
        }
        return false
    }

    const getUsersGroupsAllowedForModule = (module) => {
        if (userEntry) {
            const userGroups = userEntry.groups || []
            console.log("userGroups", userGroups, module.allowed_groups, module.allowed_groups.filter(group => userGroups.includes(group)))
            return module.allowed_groups && module.allowed_groups.filter(group => userGroups.includes(group))
        }
        return []
    }

    return {
        isLoading: false,
        hasAccessToModule,
        getUsersGroupsAllowedForModule
    }
}