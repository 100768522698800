import React, { useContext }from 'react';
import { WindowSizeContext } from '../../../../common/context/WindowSizeContext';
import LoadingComponent from '../../../../common/components/LoadingComponent';
import {  useSalesOverallPerformanceData } from "../hooks";
import SalesIndicator from '../SalesIndicator';
import moment from 'moment';
import { Grid, Typography } from '@mui/material';
import { displayNumber } from '../../../../common/utils';
import BlacklistableComponentWrapper from '../../common/providers/blacklistedComponentsProvider/BlacklistableComponentWrapper';
import { COMPONENT_TOPLINE_PERFORMANCE_MTD_SALES, COMPONENT_TOPLINE_PERFORMANCE_MTD_TARGET, COMPONENT_TOPLINE_PERFORMANCE_MTD_VS_TARGET, COMPONENT_TOPLINE_PERFORMANCE_MTD_VS_LY} from '../dashboardDefinition';


export default function MonthToDateSalesOverallPerf({ params }) {
    const {
        actualSales,
        targetSales,
        lastYearSales,
        dailyTargetSales,
        isLoading: isLoadingSalesData

    } = useSalesOverallPerformanceData({ params });
    const windowSize = useContext(WindowSizeContext);
    const year = params?.year;
    let monthDisplay = params?.month?.[0] && moment().month(params?.month?.[0] - 1).format('MMM');
    const targetSalesDisplay = targetSales !== 0 ? `₱ ${displayNumber(targetSales, windowSize.isOnMobile)}` : '--';
    
    if (params?.month?.length > 1) {
        const minMonth = Math.min(...params?.month) - 1 //minus since to match the actual month since we're using start of index 1 as month value
        const maxMonth = Math.max(...params?.month) - 1 //minus since to match the actual month since we're using start of index 1 as month value

        monthDisplay = moment().month(minMonth).format('MMM') + '-' + moment().month(maxMonth).format('MMM')
    }
    
    if (isLoadingSalesData) {
        return <LoadingComponent />
    }

    return <Grid container spacing={.25} paddingX={5} paddingY={.5} mt={0}>
        <Grid item sm={12}  xs={12} display={"flex"}>
            <Typography variant='h6' style={{ color: '#000' }}> MTD Sales </Typography>                    
            <Typography variant='h6' ml={2} alignItems='center'> ({monthDisplay} {year})  </Typography>
        </Grid>
        <Grid item sm={12} xs={12}> 
            <BlacklistableComponentWrapper componentId={COMPONENT_TOPLINE_PERFORMANCE_MTD_SALES}>
                <Typography variant='h5' fontWeight='500' style={{ color: '#000' }} >
                    {
                        actualSales === undefined ?
                            '--' :
                            <>
                                ₱ {displayNumber(actualSales, windowSize.isOnMobile)}
                            </>
                    }
                </Typography>
            </BlacklistableComponentWrapper>

            <BlacklistableComponentWrapper componentId={COMPONENT_TOPLINE_PERFORMANCE_MTD_TARGET}>
                <Typography >
                    MTD Target: { targetSalesDisplay }
                </Typography>
            </BlacklistableComponentWrapper>
            </Grid>
        <Grid item sm={12} xs={12}>
            <BlacklistableComponentWrapper componentId={COMPONENT_TOPLINE_PERFORMANCE_MTD_VS_TARGET}>
                <SalesIndicator labelValue={dailyTargetSales} label={'vs Running Daily Target'} labelPosition="right" value={(actualSales / dailyTargetSales) * 100} />
            </BlacklistableComponentWrapper>
            <BlacklistableComponentWrapper componentId={COMPONENT_TOPLINE_PERFORMANCE_MTD_VS_LY}>
                <SalesIndicator labelValue={lastYearSales} label={'vs Last Year'} labelPosition="right" value={(actualSales / lastYearSales) * 100} />
            </BlacklistableComponentWrapper>
        </Grid>
    </Grid>
}
