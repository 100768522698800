import { TABLE_NAME } from "./hooks"


export const dashboardName = 'Markets Sales Dashboard'
export const DASHBOARD_PATH = 'markets-dashboard/sales'

// components 
export const COMPONENT_TOPLINE_PERFORMANCE = 'markets_sales_topline_performance'
export const COMPONENT_TOPLINE_PERFORMANCE_MTD = 'markets_sales_topline_performance_mtd'
export const COMPONENT_TOPLINE_PERFORMANCE_MTD_SALES = 'markets_sales_topline_performance_mtd_sales'
export const COMPONENT_TOPLINE_PERFORMANCE_MTD_TARGET = 'markets_sales_topline_performance_mtd_target'
export const COMPONENT_TOPLINE_PERFORMANCE_MTD_VS_TARGET = 'markets_sales_topline_performance_mtd_vs_target'
export const COMPONENT_TOPLINE_PERFORMANCE_MTD_VS_LY = 'markets_sales_topline_performance_mtd_vs_last_year'
export const COMPONENT_TOPLINE_PERFORMANCE_YTD = 'markets_sales_topline_performance_ytd'
export const COMPONENT_TOPLINE_PERFORMANCE_YTD_SALES = 'markets_sales_topline_performance_ytd_sales'
export const COMPONENT_TOPLINE_PERFORMANCE_YTD_TARGET = 'markets_sales_topline_performance_ytd_target'
export const COMPONENT_TOPLINE_PERFORMANCE_YTD_VS_TARGET = 'markets_sales_topline_performance_ytd_vs_target'
export const COMPONENT_TOPLINE_PERFORMANCE_YTD_VS_LY = 'markets_sales_topline_performance_ytd_vs_last_year'

export const COMPONENT_OVERALL_PERFORMANCE = 'markets_sales_overall_performance'
export const COMPONENT_OVERALL_PERFORMANCE_COMPANY = 'markets_sales_overall_performance_company'
export const COMPONENT_OVERALL_PERFORMANCE_SALES = 'markets_sales_overall_performance_sales'
export const COMPONENT_OVERALL_PERFORMANCE_CONTRIBUTION = 'markets_sales_overall_performance_contribution'
export const COMPONENT_OVERALL_PERFORMANCE_TARGET_SALES = 'markets_sales_overall_performance_target_sales'
export const COMPONENT_OVERALL_PERFORMANCE_VS_TARGET = 'markets_sales_overall_performance_vs_running_daily_target'
export const COMPONENT_OVERALL_PERFORMANCE_LY_SALES = 'markets_sales_overall_performance_last_year_sales'
export const COMPONENT_OVERALL_PERFORMANCE_VS_LY_SALES = 'markets_sales_overall_performance_vs_last_year_sales'

export const COMPONENT_PERFORMANCE_PER_GROUP = 'markets_sales_performance_per_group'
export const COMPONENT_PERFORMANCE_PER_PRODUCT_CATEGORY = 'markets_sales_performance_per_product_category'
export const COMPONENT_PERFORMANCE_PER_REGION = 'markets_sales_performance_per_region'
export const COMPONENT_PERFORMANCE_PER_STORE_SIZE = 'markets_sales_performance_per_store_size'
export const COMPONENT_PERFORMANCE_PER_STORE_TYPE = 'markets_sales_performance_per_store_type'
export const COMPONENT_PERFORMANCE_PER_STORE_SUBTYPE = 'markets_sales_performance_per_store_subtype'
export const COMPONENT_PERFORMANCE_PER_GROUP_LABEL = 'markets_sales_performance_per_group_label'
export const COMPONENT_PERFORMANCE_PER_GROUP_SALES = 'markets_sales_performance_per_group_sales'
export const COMPONENT_PERFORMANCE_PER_GROUP_CONTRIBUTION = 'markets_sales_performance_per_group_contribution'
export const COMPONENT_PERFORMANCE_PER_GROUP_TARGET_SALES = 'markets_sales_performance_per_group_target_sales'
export const COMPONENT_PERFORMANCE_PER_GROUP_VS_TARGET = 'markets_sales_performance_per_group_vs_running_daily_target'
export const COMPONENT_PERFORMANCE_PER_GROUP_LY_SALES = 'markets_sales_performance_per_group_last_year_sales'
export const COMPONENT_PERFORMANCE_PER_GROUP_VS_LY_SALES = 'markets_sales_performance_per_group_vs_last_year_sales'

export const COMPONENT_STORE_PERFORMANCE = 'markets_sales_store_performance'
export const COMPONENT_STORE_PERFORMANCE_STORE = 'markets_sales_store_performance_store'
export const COMPONENT_STORE_PERFORMANCE_SALES = 'markets_sales_store_performance_sales'
export const COMPONENT_STORE_PERFORMANCE_ADS = 'markets_sales_store_performance_average_daily_sales'
export const COMPONENT_STORE_PERFORMANCE_MTD_YTD_SALES = 'markets_sales_store_performance_mtd_ytd_sales'
export const COMPONENT_STORE_PERFORMANCE_TARGET = 'markets_sales_store_performance_target'
export const COMPONENT_STORE_PERFORMANCE_MTD_YTD_TARGET = 'markets_sales_store_performance_mtd_ytd_target_sales'
export const COMPONENT_STORE_PERFORMANCE_VS_TARGET = 'markets_sales_store_performance_vs_running_daily_target'
export const COMPONENT_STORE_PERFORMANCE_LY_SALES = 'markets_sales_store_performance_last_year_sales'
export const COMPONENT_STORE_PERFORMANCE_LY_MTD_YTD_SALES = 'markets_sales_store_performance_last_year_mtd_ytd_sales'
export const COMPONENT_STORE_PERFORMANCE_VS_LY_SALES = 'markets_sales_store_performance_vs_last_year_sales'
export const COMPONENT_STORE_PERFORMANCE_ADS_PER_SQM = 'markets_sales_store_performance_average_daily_sales_per_sqm'
export const COMPONENT_STORE_PERFORMANCE_SALES_PER_SQM = 'markets_sales_store_performance_sales_per_sqm'
export const COMPONENT_STORE_PERFORMANCE_VS_LY_SALES_PER_SQM = 'markets_sales_store_performance_vs_last_year_sales_per_sqm'


export const dashboardDatasource = [
    {
        "table": {
            "name": TABLE_NAME,
        },
        "columns": [
            {"name": "company"},
            {"name": "branch_id"},
            {"name": "store"},
            {"name": "opening_date"},
            {"name": "closed_date"},
            {"name": "product_group"},
            {"name": "transaction_date"},
            {"name": "target_sales"},
            {"name": "target_sales_daily"},
            {"name": "actual_sales"},
            {"name": "transaction_count"},
            {"name": "mall_type"},
            {"name": "mall_category"},
            {"name": "store_size"},
            {"name": "region"},
            {"name": "area"},
            {"name": "selling_floor"},
            {"name": "load_date"},
        ],
        "data_filter_columns": [
            {name: "company", label: "Company", type: "string"},
            {name: "branch_id", label: "Branch ID", type: "string"},
            {name: "store", label: "Store", type: "string"},
            {name: "product_group", label: "Product Group", type: "string"},
            {name: "mall_type", label: "Mall Type", type: "string"},
            {name: "mall_category", label: "Mall Category", type: "string"},
            {name: "store_size", label: "Store Size", type: "string"},
            {name: "region", label: "Region", type: "string"},
            {name: "area", label: "Area", type: "string"},
        ],
        "views": []
    },
]

export const dashboardComponents = [
    {
        component: "Topline Performance",
        id: COMPONENT_TOPLINE_PERFORMANCE,
        subcomponents: [
            {
                component: "MTD", 
                id: COMPONENT_TOPLINE_PERFORMANCE_MTD,
                subcomponents: [
                    {component: "MTD Sales", id: COMPONENT_TOPLINE_PERFORMANCE_MTD_SALES},
                    {component: "MTD Target", id: COMPONENT_TOPLINE_PERFORMANCE_MTD_TARGET},
                    {component: "vs Running Daily Target", id: COMPONENT_TOPLINE_PERFORMANCE_MTD_VS_TARGET},
                    {component: "vs Last Year Sales", id: COMPONENT_TOPLINE_PERFORMANCE_MTD_VS_LY},
                ]
            },
            {
                component: "YTD", 
                id: COMPONENT_TOPLINE_PERFORMANCE_YTD,
                subcomponents: [
                    {component: "YTD Sales", id: COMPONENT_TOPLINE_PERFORMANCE_YTD_SALES},
                    {component: "YTD Target", id: COMPONENT_TOPLINE_PERFORMANCE_YTD_TARGET},
                    {component: "vs Running Daily Target", id: COMPONENT_TOPLINE_PERFORMANCE_YTD_VS_TARGET},
                    {component: "vs Last Year Sales", id: COMPONENT_TOPLINE_PERFORMANCE_YTD_VS_LY},
                ]
            },
        ]
    },
    {
        component: "Overall Performance",
        id: COMPONENT_OVERALL_PERFORMANCE,
        subcomponents: [
            {component: "Company", id: COMPONENT_OVERALL_PERFORMANCE_COMPANY},
            {component: "MTD/YTD Sales", id: COMPONENT_OVERALL_PERFORMANCE_SALES},
            {component: "cont %", id: COMPONENT_OVERALL_PERFORMANCE_CONTRIBUTION},
            {component: "MTD/YTD Target", id: COMPONENT_OVERALL_PERFORMANCE_TARGET_SALES},
            {component: "vs Running Daily Target", id: COMPONENT_OVERALL_PERFORMANCE_VS_TARGET},
            {component: "Last Year Sales", id: COMPONENT_OVERALL_PERFORMANCE_LY_SALES},
            {component: "vs Last Year Sales", id: COMPONENT_OVERALL_PERFORMANCE_VS_LY_SALES},
        ]
    },
    {
        component: "Performance Per Group",
        id: COMPONENT_PERFORMANCE_PER_GROUP,
        subcomponents: [
            {component: "Product Category", id: COMPONENT_PERFORMANCE_PER_PRODUCT_CATEGORY},
            {component: "Region", id: COMPONENT_PERFORMANCE_PER_REGION},
            {component: "Store Size", id: COMPONENT_PERFORMANCE_PER_STORE_SIZE},
            {component: "Store Type", id: COMPONENT_PERFORMANCE_PER_STORE_TYPE},
            {component: "Store Subtype", id: COMPONENT_PERFORMANCE_PER_STORE_SUBTYPE},
            {component: "Group Label", id: COMPONENT_PERFORMANCE_PER_GROUP_LABEL},
            {component: "MTD/YTD Sales", id: COMPONENT_PERFORMANCE_PER_GROUP_SALES},
            {component: "cont %", id: COMPONENT_PERFORMANCE_PER_GROUP_CONTRIBUTION},
            {component: "MTD/YTD Target", id: COMPONENT_PERFORMANCE_PER_GROUP_TARGET_SALES},
            {component: "vs Running Daily Target", id: COMPONENT_PERFORMANCE_PER_GROUP_VS_TARGET},
            {component: "Last Year Sales", id: COMPONENT_PERFORMANCE_PER_GROUP_LY_SALES},
            {component: "vs Last Year Sales", id: COMPONENT_PERFORMANCE_PER_GROUP_VS_LY_SALES},
        ]
    },
    {
        component: "Store Performance",
        id: COMPONENT_STORE_PERFORMANCE,
        subcomponents: [
            {component: "Store", id: COMPONENT_STORE_PERFORMANCE_STORE},
            {
                component: "MTD/YTD Sales", 
                id: COMPONENT_STORE_PERFORMANCE_SALES,
                subcomponents:[
                    {component: "ADS", id: COMPONENT_STORE_PERFORMANCE_ADS},
                    {component: "MTD/YTD", id: COMPONENT_STORE_PERFORMANCE_MTD_YTD_SALES},
                ],
            },
            {
                component: "MTD/YTD Target", 
                id: COMPONENT_STORE_PERFORMANCE_TARGET,
                subcomponents:[
                    {component: "MTD/YTD Target", id: COMPONENT_STORE_PERFORMANCE_MTD_YTD_TARGET},
                    {component: "vs Running Daily Target", id: COMPONENT_STORE_PERFORMANCE_VS_TARGET},
                ],
            },
            {
                component: "Last Year MTD/YTD Sales", 
                id: COMPONENT_STORE_PERFORMANCE_LY_SALES,
                subcomponents:[
                    {component: "Last Year MTD/YTD", id: COMPONENT_STORE_PERFORMANCE_LY_MTD_YTD_SALES},
                    {component: "vs Running Daily Target", id: COMPONENT_STORE_PERFORMANCE_VS_LY_SALES},
                ],
            },
            {
                component: "ADS per SQM", 
                id: COMPONENT_STORE_PERFORMANCE_ADS_PER_SQM,
                subcomponents:[
                    {component: "Sales per SQM", id: COMPONENT_STORE_PERFORMANCE_SALES_PER_SQM},
                    {component: "vs Last Year", id: COMPONENT_STORE_PERFORMANCE_VS_LY_SALES_PER_SQM},
                ],
            },
        ]
    },
]

export const dashboardDefinition = {
    name: dashboardName,
    data_sources: dashboardDatasource,
    entry_points: [
        'markets-dashboard/sales',
    ],
    owners: [
        "jethro.guzman@sminvestments.com"
    ],
    path: DASHBOARD_PATH,  // this is the main path of the dashboard
    components: dashboardComponents
}