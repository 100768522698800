import { STORE_ZERO_SALES_TABLE } from "./hooks"


export const dashboardName = 'Zero Sales Dashboard'
export const DASHBOARD_PATH = 'markets-dashboard/zero-sales'

// components 
export const COMPONENT_TOPLINE_COUNT_OF_ZERO_SALES = 'topline_count_of_zero_sales'
export const COMPONENT_TOPLINE_COUNT_OF_ZERO_SALES_STORE_NAME = 'topline_count_of_zero_sales_store_name'
export const COMPONENT_TOPLINE_COUNT_OF_ZERO_SALES_TOP_888 = 'topline_count_of_zero_sales_top_888_article_count'
export const COMPONENT_TOPLINE_COUNT_OF_ZERO_SALES_TOP_4K = 'topline_count_of_zero_sales_top_4k_article_count'

export const COMPONENT_BY_STORE_ZERO_SALES_ARTICLES = 'by_store_zero_sales_articles'
export const COMPONENT_BY_STORE_ZERO_SALES_ARTICLES_NUMBER = 'by_store_zero_sales_articles_number'
export const COMPONENT_BY_STORE_ZERO_SALES_ARTICLES_DESCRIPTION = 'by_store_zero_sales_articles_description'
export const COMPONENT_BY_STORE_ZERO_SALES_ARTICLES_PRODUCT_CATEGORY = 'by_store_zero_sales_articles_product_category'
export const COMPONENT_BY_STORE_ZERO_SALES_ARTICLES_MUST_HAVE_TYPE = 'by_store_zero_sales_articles_must_have_type'
export const COMPONENT_BY_STORE_ZERO_SALES_ARTICLES_LAST_RECEIVED_DATE = 'by_store_zero_sales_articles_last_received_date'


export const dashboardDatasource = [
    {
        "table": {
            "name": STORE_ZERO_SALES_TABLE,
        },
        "columns": [
            {"name": "transaction_date"},
            {"name": "company_id"},
            {"name": "store_id"},
            {"name": "store_name"},
            {"name": "store_region"},
            {"name": "article_number"},
            {"name": "article_description"},
            {"name": "article_category"},
            {"name": "article_sub_category"},
            {"name": "load_date"},
            {"name": "last_received_date"},
        ],
        "data_filter_columns": [
            {name: "company_id", label: "Company ID", type: "string"},
            {name: "store_id", label: "Store ID", type: "string"},
            {name: "store_name", label: "Store Name", type: "string"},
            {name: "store_region", label: "Store Region", type: "string"},
            {name: "article_category", label: "Article Category", type: "string"},
            {name: "article_sub_category", label: "Article Subcategory", type: "string"},
        ],
        "views": []
    },
]

export const dashboardComponents = [
    {
        component: "Topline Count of Zero Sales",
        id: COMPONENT_TOPLINE_COUNT_OF_ZERO_SALES,
        subcomponents: [
            {component: "Store Name", id: COMPONENT_TOPLINE_COUNT_OF_ZERO_SALES_STORE_NAME},
            {component: "Top 888 Article Count", id: COMPONENT_TOPLINE_COUNT_OF_ZERO_SALES_TOP_888},
            {component: "Top 4k Article Count", id: COMPONENT_TOPLINE_COUNT_OF_ZERO_SALES_TOP_4K},
        ]
    },
    {
        component: "By Store - Zero Sales Article",
        id: COMPONENT_BY_STORE_ZERO_SALES_ARTICLES,
        subcomponents: [
            {component: "Article Number", id: COMPONENT_BY_STORE_ZERO_SALES_ARTICLES_NUMBER},
            {component: "Article Description", id: COMPONENT_BY_STORE_ZERO_SALES_ARTICLES_DESCRIPTION},
            {component: "Product Category", id: COMPONENT_BY_STORE_ZERO_SALES_ARTICLES_PRODUCT_CATEGORY},
            {component: "Must-Have Type", id: COMPONENT_BY_STORE_ZERO_SALES_ARTICLES_MUST_HAVE_TYPE},
            {component: "Last Received Date", id: COMPONENT_BY_STORE_ZERO_SALES_ARTICLES_LAST_RECEIVED_DATE},

        ]
    },
]

export const dashboardDefinition = {
    name: dashboardName,
    data_sources: dashboardDatasource,
    entry_points: [
        'markets-dashboard/zero-sales',
    ],
    owners: [
        "jethro.guzman@sminvestments.com"
    ],
    path: DASHBOARD_PATH,  // this is the main path of the dashboard
    components: dashboardComponents
}