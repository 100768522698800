import { useMutation, useQueryClient, useQuery } from "react-query";
import ApiClient from "../../../../common/API";
import {toast} from "react-toastify";

export function useDashboardTableList({ dashboardId }) {

    const { data, isLoading } = useQuery(
      ["tables", "dashboard_table_lists", dashboardId],
      () => {
        return ApiClient()
            .get(`dashboard/common-dashboard/${dashboardId}`, {})
            .then((res) => res.data)
      },
      {
        refetchOnWindowFocus: false,
        staleTime: 600000,
      }
    );

    return {
        data,
        isLoading
    }
}

export function useDashboardAccessPermissionsList({ params }) {
    return useQuery(
        ['permissions-list', 'dashboard-access-permission-list', params],
        () => {
            return ApiClient().get(`dashboard/common-dashboard/permissions/`, {
                params: {
                    ...params
                }
            }).then((res) => res.data)
        },
        {
            refetchOnWindowFocus: false,
            staleTime: 600000,
        }
    )
}

export function useDashboardAccessPermissions({ dashboardPermissionId }) {
    const { data, isLoading } = useQuery(
        ["permission", "dashboard-access-permission", dashboardPermissionId],
        () => {
            return ApiClient().get(`dashboard/common-dashboard/permissions/${dashboardPermissionId}`, {}).then((res) => res.data)
        },
        {
            refetchOnWindowFocus: false,
            staleTime: 600000,
            enabled: !!dashboardPermissionId
        }
    );

    return {
        data,
        isLoading
    }
}

export function useDataFilters({params}) {
    return useQuery(
        ["data-filters", params],
        () => {
            return ApiClient().get(
                'dashboard/common-dashboard/data-filters/',
                {
                    params: params
                }
            ).then(res => res.data)
        },
        {
            refetchOnWindowFocus: false,
            staleTime: 600000,
        }
    )
}

export function useSaveDashboardAccessMutation({id}) {
    const queryClient = useQueryClient();
    return useMutation((data) => {
        if (!!id) {
            // we only need to update the component_blacklist
            const patchData = {
                component_blacklist: data.component_blacklist,
            }
            const patchPromise = ApiClient()
                .patch(`dashboard/common-dashboard/permissions/${id}/`, patchData)
            return toast.promise(patchPromise, {
                pending: 'Updating...',
                success: `Dashboard access entry updated!`,
                error: `Error updating dashboard access`,
            })

        } else {
            const postPromise = ApiClient()
                .post(`dashboard/common-dashboard/permissions/`, data)
            return toast.promise(postPromise, {
                pending: 'Creating...',
                success: `Dashboard access entry created!`,
                error: `Error creating dashboard access`,
            })
        }
    }, {
        onSuccess: (response) => {
            return Promise.all([
                queryClient.invalidateQueries(["user", "component", "blacklist"]),
                queryClient.invalidateQueries(['permissions-list', 'dashboard-access-permission-list']),
                queryClient.invalidateQueries(["permission", "dashboard-access-permission"]),
            ])
        },
        onError: (error) => {
            // Handle error for saveDashboardInstance
            console.log(error)
            toast.error(error, {});
        }
    })
}

export function useSaveDataFiltersMutation() {
    const queryClient = useQueryClient();

    return useMutation((data) => {
        const id = data.id
        const hasDataFilterId = !!id

        if (hasDataFilterId) {
            // we only need to update the blacklisted_columns and/or filter_statements
            const patchData = {}
            if (data.blacklisted_columns) {
                patchData.blacklisted_columns = data.blacklisted_columns
            }
            if (data.filter_statements) {
                patchData.filter_statements = data.filter_statements
            }

            return ApiClient().patch(`dashboard/common-dashboard/data-filters/${id}/`, patchData)
        }

        return ApiClient().post('dashboard/common-dashboard/data-filters/', data)
    }, {

        onError: (error) => {
            // Handle error
            toast.error(error, {});
        },
        onSuccess: () => {
            // queryClient.invalidateQueries(["permissions-list", "dashboard-access-permission-list", params])
            // queryClient.invalidateQueries(["permission", "dashboard-access-permission", permissionId])
            // invalidate all queries, including data queries
            queryClient.invalidateQueries([])
            toast.success('Saved filters successfully');
        }
    });
}


export function useTable({tableName}) {
    return useQuery(
        ["data", "table", tableName],
        () => {
            return ApiClient().get(`data/table/${tableName}/`, {}).then((res) => res.data)
        },
        {
            refetchOnWindowFocus: false,
            staleTime: 600000,
            enabled: !!tableName
        }
    );
}

/* HIGHER LEVEL HOOKS */

export function useFindDashboardAccessPermission({userId, groupId, dashboardId}) {
    const params =  {dashboard_id: dashboardId}
    if (userId) {
        params.user_id = userId;
    } else if (groupId) {
        params.group_id = groupId;
    }

    const {data: permissionList} = useDashboardAccessPermissionsList({params});
    const permissionId = permissionList?.length > 0 ?
        permissionList[0]?.id :
        null

    return useDashboardAccessPermissions({dashboardPermissionId: permissionId})
}

export function useFindDashboardDataFilters({userId, groupId, dashboardId}) {
    const params =  {dashboard_id: dashboardId}
    if (userId) {
        params.user_id = userId;
    } else if (groupId) {
        params.group_id = groupId;
    }

    return useDataFilters({params})
}



