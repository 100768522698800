import { useMemo } from 'react'
import { Grid } from '@mui/material'
import QueryBuilderComponent from './QueryBuilder'
import TableSelection from './TableSelection'
import DataPreview from './DataPreview'
import { useQuery } from 'react-query';
import ApiClient from '../../../../../common/API'
import { useTableDetail } from '../../../../../common/API/hooks'
import {OPERATORS_WITHOUT_VALUE} from "./QueryBuilderValueEditor";

const OPERATORS = {
    '>': 'gt',
    '>=': 'gte',
    '<': 'lt',
    '<=': 'lte',
    '!=': 'neq',
    'null': 'is_null',
    'notNull': 'is_not_null',
    'in': 'in',
    'notIn': 'not_in',
}

export default function RowFiltersTab({ selectedTable, queryStatement, setQueryStatement, dashboardDefinition }) {
    const dataFilters = useMemo(() => {
        const getFilterStatements = queryStatement?.rules;

        if (!getFilterStatements || getFilterStatements.length === 0) {
            return undefined;
        }

        return getFilterStatements.reduce((acc, item) => {
            if (item?.field && item?.operator) {
                const operatorSuffix = OPERATORS[item.operator] || '';
                const fieldName = operatorSuffix ? `${item.field}__${operatorSuffix}` : item.field;

                if (OPERATORS_WITHOUT_VALUE.includes(item.operator)) {
                    acc[fieldName] = 1;
                } else if (item?.value !== undefined && item?.value !== '') {
                    acc[fieldName] = Array.isArray(item.value) ? item.value.join(',') : item.value;
                }
            }

            return acc;
        }, {});

    }, [queryStatement, selectedTable]);



    return (
        <Grid container sx={{overflowY: 'scroll'}} maxHeight={'45vh'} spacing={2} p={2}>
            <Grid item xs={12}>
                <QueryBuilderComponent
                    selectedTable={selectedTable}
                    dashboardDefinition={dashboardDefinition}
                    queryStatement={queryStatement} setQueryStatement={setQueryStatement}
                />
            </Grid>

            <Grid item xs={12}>
                <DataPreview
                    selectedTable={selectedTable}
                    filters={dataFilters}
                />
            </Grid>
        </Grid>
    )
}