import * as React from 'react';
import { Grid, Typography, Box, Divider, Checkbox, IconButton } from '@mui/material';
import Back from '../../../common/components/BackNavigateComponent';
import ToggleSelectorUserGroup from './Components/ToggleSelectorUserGroup';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import GroupsRoundedIcon from '@mui/icons-material/GroupsRounded';
import { useContext, useState } from 'react';
import { WindowSizeContext } from '../../../common/context/WindowSizeContext';
import SearchBar from './Components/SearchBar';
import UserPermissionManagementTable from './UserPermissionManagementTable';
import GroupPermissionManagementTable from './GroupPermissionManagementTable';
import { Clear } from '@mui/icons-material';
import UnregisteredDashboards from "./UnregisteredDashboards";
import Dashboards from "./Dashboards";

export default function PermissionManagement({ isModal, setIsOpen, ownerView, opsView, dashboardName }) {
    const windowSize = useContext(WindowSizeContext)
    const [searchValue, setsearchValue] = useState('');
    const [entity, setEntity] = useState('user');
    const TOGGLE_OPTIONS = [
        { label: 'User', value: 'user', icon: <PersonRoundedIcon fontSize='small' /> },
        { label: 'Group', value: 'group', icon: <GroupsRoundedIcon fontSize='small' />},
    ];
    const onToggleEntityChange = (event, val) => {
        if (val !== null) {
            setEntity(val);
        }
    };

    return (<>
        <Grid container xs={12} display={'flex'} sx={{ mt: !isModal && 3 }} alignItems={'center'}>
            <Grid xs={12} sm display={'flex'} my={windowSize && .5}>
                {!isModal && <Back />}
                <Typography variant="h5" style={{ marginLeft: '16px' }}>
                    Dashboard Permission Management
                </Typography>
            </Grid>
            <Grid item xs={12} sm={1.5} display={'flex'} justifyContent={'flex-end'} my={windowSize && !isModal && .5}>
                <ToggleSelectorUserGroup
                    options={TOGGLE_OPTIONS}
                    value={entity}
                    onChange={onToggleEntityChange}
                />
            </Grid>
            {
                isModal && <Grid item alignItems="flex-start">
                    <IconButton onClick={setIsOpen}>
                        <Clear />
                    </IconButton>
                </Grid>
            }
        </Grid>

        <Divider sx={{ my: 1, borderBottom: 3 }} />

        <Grid container xs={12} mt={3}>
            <Grid item xs={12} sm={3}>
                <SearchBar search={searchValue} setSearch={setsearchValue} searchFor={entity === 'user' ? 'User' : 'Group'} />
            </Grid>
        </Grid>

        <Box flexGrow={1} sx={{ borderRadius: '.5rem', border: '1px solid #E4E3E3' }} my={3}>
            <Grid container xs={12}  py={.5} display={'flex'} sx={{ border: '1px solid #E4E3E3', backgroundColor: '#F6F6F6', borderRadius: '.5rem .5rem 0 0' }}>
                {entity === 'user' ?
                    <Typography variant='h6' ml={3}>User Permissions</Typography> :
                    <Typography variant='h6' ml={3}>Group Permissions</Typography>
                }
            </Grid>
            <Box sx={{ overflow: isModal && 'auto', maxHeight: isModal && '57vh' }}>
                {entity === 'user'
                    ? <UserPermissionManagementTable entity={entity} search={searchValue} ownerView={ownerView} opsView={opsView} dashboardName={dashboardName} />
                    : <GroupPermissionManagementTable entity={entity} search={searchValue} ownerView={ownerView} opsView={opsView} dashboardName={dashboardName} />
                }
            </Box>
        </Box>

        <UnregisteredDashboards/>
        <Dashboards/>
    </>
    )
}