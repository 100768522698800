import React from "react";
import {Button, Grid, Stack, Typography} from "@mui/material";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import {KeyboardDoubleArrowLeft, KeyboardDoubleArrowRight} from "@mui/icons-material";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import {CheckboxListBox} from "./CheckboxListBox";


export default function SplitMultiSelect(
    {
        selected, options, onChange, reverse=false,
        selectedLabel="Selected", unselectedLabel="Unselected"
    }
) {
    const [checked, setChecked] = React.useState([]);

    const selectedOptions = options.filter(option => selected.includes(option.value))
    const unselectedOptions = options.filter(option => !selected.includes(option.value))

    const selectedCheckedValues = selectedOptions.filter(option => checked.includes(option.value)).map(option => option.value)
    const unselectedCheckedValues = unselectedOptions.filter(option => checked.includes(option.value)).map(option => option.value)

    const deselectAllItems = () => {
        onChange([])
    }
    const selectAllItems = () => {
        onChange(options.map(option => option.value))
    }

    const deselectCheckedItems = () => {
        onChange(selected.filter(value => !selectedCheckedValues.includes(value)))
    }
    const selectCheckedItems = () => {
        onChange([...selected, ...unselectedCheckedValues])
    }

    const selectItem = (value) => {
        onChange([...selected, value])
    }

    const deselectItem = (value) => {
        onChange(selected.filter(item => item !== value))
    }

    // the following are oriented values
    const leftOptions = reverse ? selectedOptions : unselectedOptions
    const rightOptions = reverse ? unselectedOptions : selectedOptions
    const leftCheckedValues = reverse ? selectedCheckedValues : unselectedCheckedValues
    const rightCheckedValues = reverse ? unselectedCheckedValues : selectedCheckedValues
    const leftDoubleClicked = reverse ? deselectItem : selectItem
    const rightDoubleClicked = reverse ? selectItem : deselectItem
    const leftLabel = reverse ? selectedLabel : unselectedLabel
    const rightLabel = reverse ? unselectedLabel : selectedLabel

    const moveCheckedItemsLeft = reverse ? selectCheckedItems : deselectCheckedItems
    const moveCheckedItemsRight = reverse ? deselectCheckedItems : selectCheckedItems
    const moveAllItemsLeft = reverse ? selectAllItems : deselectAllItems
    const moveAllItemsRight = reverse ? deselectAllItems : selectAllItems

    return (
        <Grid container spacing={2} justifyContent="center" alignItems="center" my={3}>
            <Grid item xs={12} sm>
                <Stack spacing={1}>
                    <Typography sx={{fontWeight: 'bold', textAlign: 'center'}}>
                        {leftLabel}
                    </Typography>
                    <Grid sx={{display: 'flex', justifyContent: 'center'}}>
                        <CheckboxListBox
                            selected={leftCheckedValues}
                            options={leftOptions}
                            onChange={(values) => setChecked([
                                ...values,
                                ...rightCheckedValues
                            ])}
                            onDoubleClick={leftDoubleClicked}
                        />
                    </Grid>
                </Stack>
            </Grid>

            <Grid item xs={12} sm={1}>
                <Grid container direction="column" alignItems="center">
                    <Button
                        sx={{my: 0.5}}
                        variant="outlined"
                        size="small"
                        onClick={moveAllItemsRight}
                        disabled={leftOptions?.length === 0}
                        aria-label="move all right"
                    >
                        <KeyboardDoubleArrowRight/>
                    </Button>
                    <Button
                        sx={{my: 0.5}}
                        variant="outlined"
                        size="small"
                        onClick={moveCheckedItemsRight}
                        disabled={leftCheckedValues?.length === 0}
                        aria-label="move selected right"
                    >
                        <KeyboardArrowRight/>
                    </Button>
                    <Button
                        sx={{my: 0.5}}
                        variant="outlined"
                        size="small"
                        onClick={moveCheckedItemsLeft}
                        disabled={rightCheckedValues?.length === 0}
                        aria-label="move selected left"
                    >
                        <KeyboardArrowLeft/>
                    </Button>
                    <Button
                        sx={{my: 0.5}}
                        variant="outlined"
                        size="small"
                        onClick={moveAllItemsLeft}
                        disabled={rightOptions?.length === 0}
                        aria-label="move all left"
                    >
                        <KeyboardDoubleArrowLeft/>
                    </Button>
                </Grid>
            </Grid>

            <Grid item xs={12} sm>
                <Stack spacing={1}>
                    <Typography sx={{fontWeight: 'bold', textAlign: 'center'}}>
                        {rightLabel}
                    </Typography>
                    <Grid sx={{display: 'flex', justifyContent: 'center'}}>
                        <CheckboxListBox
                            selected={rightCheckedValues}
                            options={rightOptions}
                            onChange={(values) => setChecked([
                                ...values,
                                ...leftCheckedValues
                            ])}
                            onDoubleClick={rightDoubleClicked}
                            />
                    </Grid>
                </Stack>
            </Grid>
        </Grid>
    )
}