import React from 'react';
import { useState, useContext } from 'react'
import { Grid, Typography, Divider, Box, Chip } from '@mui/material'
import { WindowSizeContext } from '../../../../common/context/WindowSizeContext';
import Back from '../../../../common/components/BackNavigateComponent';
import UserPermissionTable from './UserPermissionsTable';
import GroupPermissionTable from './GroupPermissionsTable';
import { useParams } from 'react-router-dom';
import { useUserDashboardList, useGroupDashboardList, useCommonDashboardList } from './hooks';
import { useDashboardAccessPermissionsList } from '../DashboardPermissionManagementModal/hooks';
import { useDataFilterList, useUserPermissionAccess } from '../hooks';
import {People, Person} from "@mui/icons-material";

export default function OpsPermissionManagement() {
    const { email, id, toggle_group } = useParams()
    const windowSize = useContext(WindowSizeContext)
    const [searchValue, setsearchValue] = useState('');

    const { data: userDashboardList, isLoading: isUserDashboardListLoading } = useUserDashboardList({ userId: id, toggle_group })
    const userGroups = userDashboardList?.groups

    const { data: groupDashboardList, isLoading: isGroupDashboardListLoading } = useGroupDashboardList({ groupId: id, toggle_group })
    const groupUsers = groupDashboardList?.users?.sort()

    const { data: dashboardList, isLoading } = useCommonDashboardList()
    //data-filter API endpoint
    const { data: userDataFilterList, isLoading: isUserDataFilterListLoading } = useDataFilterList()
    //permissions API endpoint
    const { data: userPermissionAccess, isLoading: isUserPermissionAccessLoading } = useUserPermissionAccess()

    const [showMore, setShowMore] = useState(false);

    const displayedGroupUsers = showMore ? groupUsers : groupUsers?.slice(0, 3);

    const handleShowMore = () => {
        setShowMore(!showMore);
    };

    return (<>
        <Grid container mt={3}>
            <Grid item xs={12} marginY={1} display="flex" my={windowSize && .5} alignItems={'center'}>
                <Back />
                <Typography variant="h5" ml={3} >
                    Dashboard Permission Management
                </Typography>
            </Grid>
        </Grid >

        <Divider sx={{ my: 1, borderBottom: 3 }} />

        <Grid container xs={12} mx={1} py={2} direction={'column'}>
            <Typography variant='h6' sx={{ fontWeight: '500', my: 1 }}>
                {
                    toggle_group === 'user' ?
                        <Person sx={{marginRight: "0.5rem"}}/> :
                        <People sx={{marginRight: "0.5rem"}}/>
                }
                {email}'s Access
            </Typography>

            {toggle_group === 'user' ?
                <Grid container>
                    <Grid item>
                        <Typography variant='subtitle1' fontWeight={'bold'}> Groups: </Typography>
                    </Grid>
                    <Grid item xs={7} direction={'column'} >
                        <Grid item xs={4} >
                            <ul style={{ marginBottom: 0, columns: 3 }}>
                                {userGroups?.slice(0)?.map((item, index) => (
                                    index < 3 ?
                                        <li key={index}>{item}</li> : null
                                ))}
                            </ul>
                        </Grid>
                        {userGroups?.length > 3 && (
                            showMore ? (
                                <>
                                    <Grid item >
                                        <ul style={{ marginBottom: 0, columns: 3 }}>
                                            {userGroups?.slice(3)?.map((item, index) => (
                                                <li key={index}>{item}</li>
                                            ))}
                                        </ul>
                                    </Grid>
                                    <Chip variant='outlined' size='small' style={{ marginLeft: 10 }} onClick={handleShowMore} label='Show Less' />
                                </>
                            ) : (
                                <Chip variant='outlined' size='small' style={{ marginLeft: 10 }} onClick={handleShowMore} label={`+${userGroups.length - 3} more . . .`} />
                            )
                        )}
                    </Grid>
                </Grid>
                :
                <Grid container>
                    <Grid item>
                        <Typography variant='subtitle1' fontWeight={'bold'}> Users: </Typography>
                    </Grid>
                    <Grid item xs={7} direction={'column'} >

                        <Grid item display={'flex'}>
                            <ul style={{ marginBottom: 0, columns: 3 }}>
                                {displayedGroupUsers?.map((item, index) => (
                                    <li key={index} style={{textWrap: "nowrap", textOverflow: "ellipsis", overflow: "hidden"}}>{item}</li>
                                ))}
                            </ul>
                        </Grid>

                        {groupUsers?.length > 3 && (
                            showMore ? (
                                <Chip variant='outlined' size='small' style={{ marginLeft: 10 }} onClick={handleShowMore} label='Show Less' />
                            ) : (
                                <Chip variant='outlined' size='small' style={{ marginLeft: 10 }} onClick={handleShowMore} label={`+${groupUsers.length - 3} more . . .`} />
                            )
                        )}
                    </Grid>
                </Grid>
            }
        </Grid >

        <Box flexGrow={1} sx={{ borderRadius: '.5rem', border: '1px solid #E4E3E3' }} my={3}>
            <Grid container xs={12} py={.5} display={'flex'} sx={{ border: '1px solid #E4E3E3', backgroundColor: '#F6F6F6', borderRadius: '.5rem .5rem 0 0' }}>
                <Typography variant='h6' ml={3}>Permissions</Typography>
            </Grid>
            {
                toggle_group === 'user' ?
                    <UserPermissionTable search={searchValue} data={dashboardList} userDashboardData={userDashboardList} isLoading={isLoading} id={id} dataFilter={userDataFilterList} dashboardAccess={userPermissionAccess} />
                    : <GroupPermissionTable search={searchValue} data={dashboardList} groupDashboardData={groupDashboardList} isLoading={isLoading} id={id} dataFilter={userDataFilterList} dashboardAccess={userPermissionAccess} />
            }

        </Box>
    </>
    )
}