import { TABLE_CUSTOMER_ANALYTICS_SEGMENTS_YEARLY } from "../CustomerAnalytics/hooks"
import { 
    TABLE_SMAC_MEMBERS_MONTHLY,
    TABLE_SMAC_MEMBERS_YEARLY,
    TABLE_CITY_PENETRATION_MONTHLY,
    TABLE_CITY_PENETRATION_YEARLY,
    TABLE_SMAC_ACTIVITY_MONTHLY,
    TABLE_SMAC_ACTIVITY_YEARLY,
    TABLE_DAY_TIME_SALES_MONTHLY,
    TABLE_DAY_TIME_SALES_YEARLY,
    TABLE_BU_ACTIVITY_MONTHLY,
    TABLE_BU_ACTIVITY_YEARLY,
    TABLE_CUSTOMER_RECENCY_MONTHLY,
    TABLE_CUSTOMER_RECENCY_YEARLY,
    TABLE_SKU_PERFORMANCE_MONTHLY,
    TABLE_SKU_PERFORMANCE_YEARLY,
    TABLE_HOMEBRANCH_MONTHLY,
    TABLE_HOMEBRANCH_YEARLY,
    TABLE_SMAC_CUSTOMER_SEGMENTS_MONTHLY,
    TABLE_SMAC_CUSTOMER_SEGMENTS_YEARLY, 
    TABLE_BASKET_SIZE_MONTHLY,
    TABLE_BASKET_SIZE_YEARLY
} from "./hooks"
import { 
    VIEW_BY_ACQUISITION, 
    VIEW_BY_REGISTRATION, 
    VIEW_BY_USAGE 
} from "./constants"


export const dashboardName = 'SMAC Dashboard'
export const DASHBOARD_PATH = 'retail-dashboard/smac-dashboard'

// components 
export const COMPONENT_ACQUISITION = VIEW_BY_ACQUISITION
export const COMPONENT_SMAC_MEMBERS_ACQUISITION = 'smac_members_acquisition'
export const COMPONENT_SMAC_CARD_TYPES_ACQUISITION = 'smac_card_types_acquisition'
export const COMPONENT_SMAC_CARD_STATUS = 'smac_card_status'
export const  COMPONENT_MEMBER_PENETRATION = 'member_penetration'

export const COMPONENT_REGISTRAION = VIEW_BY_REGISTRATION
export const COMPONENT_SMAC_MEMBERS_REGISTRATION = 'smac_members_registration'
export const COMPONENT_SMAC_CARD_TYPES_REGISTRATION = 'smac_card_types_registration'
export const COMPONENT_CUSTOMER_DEMOGRAPHICS = 'customer_demographics_graphs'
export const COMPONENT_CUSTOMER_GENERATION = 'customer_generation'
export const COMPONENT_CUSTOMER_AGE = 'customer_age'
export const COMPONENT_CUSTOMER_GENDER = 'customer_gender'
export const COMPONENT_CUSTOMER_MARITAL_STATUS = 'customer_marital_status'
export const COMPONENT_CUSTOMER_CONTACT_INFO = 'customer_contact_info'
export const COMPONENT_CUSTOMER_CAMPAIGN_SUBSCRIPTION = 'customer_subscription'

export const COMPONENT_USAGE = VIEW_BY_USAGE
export const COMPONENT_SMAC_ACTIVE_MEMBERS = 'smac_active_members'
export const COMPONENT_SMAC_CARD_TYPES_USAGE = 'smac_card_types_usage'
export const COMPONENT_CUSTOMER_ACTIVITY_BY_HOMEBRANCH = 'customer_activity_by_home_branch'
export const COMPONENT_CUSTOMER_RECENCY = 'customer_recency'
export const COMPONENT_CUSTOMER_ATV_BY_BASKET_TIER = 'customer_atv_by_basket_tier'
export const COMPONENT_CUSTOMER_SEGMENTS = 'customer_segments'
export const COMPONENT_CUSTOMER_ACTIVITY_BY_BU = 'customer_activity_by_bu'
export const COMPONENT_SKU_PERFORMANCE_BY_SALES = 'sku_performance_by_sales'
export const COMPONENT_SALES_PER_CUSTOMER_BY_DOW_AND_TOD = 'sales_per_customer_by_dow_and_tod'


export const dashboardDatasource = [
    // TOPLINE & SMAC CARD STATUS
    {
        "table": {
            "name": TABLE_SMAC_MEMBERS_MONTHLY,
        },
        "columns": [
            {"name": "year"},
            {"name": "month"},
            {"name": "org"},
            {"name": "card_status"},
            {"name": "dimension"},
            {"name": "dimension_value"},
            {"name": "total_members"},
            {"name": "sales_plan"},
            {"name": "load_date"},
        ],
        "data_filter_columns": [
            {name: "org", label: "Organization", type: "string"},
            {name: "card_status", label: "Card Status", type: "string"},
            {name: "dimension", label: "Dimension", type: "string"},
            {name: "dimension_value", label: "Dimension Value", type: "string"},
        ],
        "views": []
    },
    {
        "table": {
            "name": TABLE_SMAC_MEMBERS_YEARLY,
        },
        "columns": [
            {"name": "year"},
            {"name": "month"},
            {"name": "org"},
            {"name": "card_status"},
            {"name": "dimension"},
            {"name": "dimension_value"},
            {"name": "total_members"},
            {"name": "sales_plan"},
            {"name": "load_date"},
        ],
        "data_filter_columns": [
            {name: "org", label: "Organization", type: "string"},
            {name: "card_status", label: "Card Status", type: "string"},
            {name: "dimension", label: "Dimension", type: "string"},
            {name: "dimension_value", label: "Dimension Value", type: "string"},
        ],
        "views": []
    },

    // CITY PENETRATION
    {
        "table": {
            "name": TABLE_CITY_PENETRATION_MONTHLY,
        },
        "columns": [
            {"name": "year"},
            {"name": "month"},
            {"name": "province"},
            {"name": "city"},
            {"name": "city_population"},
            {"name": "smac_members"},
            {"name": "smac_members_ly"},
            {"name": "penetration_perc"},
            {"name": "penetration_perc_ly"},
            {"name": "smac_active_members"},
            {"name": "smac_active_members_ly"},
            {"name": "load_date"},
        ],
        "data_filter_columns": [
            {name: "province", label: "Province", type: "string"},
            {name: "city", label: "City", type: "string"},
        ],
        "views": []
    },
    {
        "table": {
            "name": TABLE_CITY_PENETRATION_YEARLY,
        },
        "columns": [
            {"name": "year"},
            {"name": "month"},
            {"name": "province"},
            {"name": "city"},
            {"name": "city_population"},
            {"name": "smac_members"},
            {"name": "smac_members_ly"},
            {"name": "penetration_perc"},
            {"name": "penetration_perc_ly"},
            {"name": "smac_active_members"},
            {"name": "smac_active_members_ly"},
            {"name": "load_date"},
        ],
        "data_filter_columns": [
            {name: "province", label: "Province", type: "string"},
            {name: "city", label: "City", type: "string"},
        ],
        "views": []
    },

    // SMAC ACTIVITY
    {
        "table": {
            "name": TABLE_SMAC_ACTIVITY_MONTHLY,
        },
        "columns": [
            {"name": "year"},
            {"name": "month"},
            {"name": "org"},
            {"name": "card_status"},
            {"name": "store"},
            {"name": "card_type"},
            {"name": "activity_status"},
            {"name": "total_members"},
            {"name": "sales_plan"},
            {"name": "load_date"},
        ],
        "data_filter_columns": [
            {name: "org", label: "Organization", type: "string"},
            {name: "card_status", label: "Card Status", type: "string"},
            {name: "store", label: "Store", type: "string"},
            {name: "card_type", label: "Card Type", type: "string"},
            {name: "activity_status", label: "Activity Status", type: "string"},
        ],
        "views": []
    },
    {
        "table": {
            "name": TABLE_SMAC_ACTIVITY_YEARLY,
        },
        "columns": [
            {"name": "year"},
            {"name": "month"},
            {"name": "org"},
            {"name": "card_status"},
            {"name": "store"},
            {"name": "card_type"},
            {"name": "activity_status"},
            {"name": "total_members"},
            {"name": "sales_plan"},
            {"name": "load_date"},
        ],
        "data_filter_columns": [
            {name: "org", label: "Organization", type: "string"},
            {name: "card_status", label: "Card Status", type: "string"},
            {name: "store", label: "Store", type: "string"},
            {name: "card_type", label: "Card Type", type: "string"},
            {name: "activity_status", label: "Activity Status", type: "string"},
        ],
        "views": []
    },

    // DAY TIME SALES (is day_name and hour_of_day to be added in data_filter_columns?)
    {
        "table": {
            "name": TABLE_DAY_TIME_SALES_MONTHLY,
        },
        "columns": [
            {"name": "year"},
            {"name": "month"},
            {"name": "org"},
            {"name": "store"},
            {"name": "day_name"},
            {"name": "hour_of_day"},
            {"name": "sales"},
            {"name": "load_date"},
        ],
        "data_filter_columns": [
            {name: "org", label: "Organization", type: "string"},
            {name: "store", label: "Store", type: "string"},
        ],
        "views": []
    },
    {
        "table": {
            "name": TABLE_DAY_TIME_SALES_YEARLY,
        },
        "columns": [
            {"name": "year"},
            {"name": "org"},
            {"name": "store"},
            {"name": "day_name"},
            {"name": "hour_of_day"},
            {"name": "sales"},
            {"name": "load_date"},
        ],
        "data_filter_columns": [
            {name: "org", label: "Organization", type: "string"},
            {name: "store", label: "Store", type: "string"},
        ],
        "views": []
    },

    // BU ACTIVITY
    {
        "table": {
            "name": TABLE_BU_ACTIVITY_MONTHLY,
        },
        "columns": [
            {"name": "year"},
            {"name": "month"},
            {"name": "org"},
            {"name": "store"},
            {"name": "bu_desc"},
            {"name": "dept_desc"},
            {"name": "subdept_desc"},
            {"name": "active_member"},
            {"name": "active_member_ly"},
            {"name": "member_perc"},
            {"name": "sales"},
            {"name": "sales_ly"},
            {"name": "sales_perc"},
            {"name": "txn"},
            {"name": "txn_ly"},
            {"name": "atv"},
            {"name": "atv_ly"},
            {"name": "visit_freq"},
            {"name": "visit_freq_ly"},
            {"name": "avg_spend_visit"},
            {"name": "avg_spend_visit_ly"},
            {"name": "load_date"},
        ],
        "data_filter_columns": [
            {name: "org", label: "Organization", type: "string"},
            {name: "store", label: "Store", type: "string"},
            {name: "bu_desc", label: "Business Unit", type: "string"},
            {name: "dept_desc", label: "Department", type: "string"},
            {name: "subdept_desc", label: "Subdepartment", type: "string"},
        ],
        "views": []
    },
    {
        "table": {
            "name": TABLE_BU_ACTIVITY_YEARLY,
        },
        "columns": [
            {"name": "year"},
            {"name": "org"},
            {"name": "store"},
            {"name": "bu_desc"},
            {"name": "dept_desc"},
            {"name": "subdept_desc"},
            {"name": "active_member"},
            {"name": "active_member_ly"},
            {"name": "member_perc"},
            {"name": "sales"},
            {"name": "sales_ly"},
            {"name": "sales_perc"},
            {"name": "txn"},
            {"name": "txn_ly"},
            {"name": "atv"},
            {"name": "atv_ly"},
            {"name": "visit_freq"},
            {"name": "visit_freq_ly"},
            {"name": "avg_spend_visit"},
            {"name": "avg_spend_visit_ly"},
            {"name": "load_date"},
        ],
        "data_filter_columns": [
            {name: "org", label: "Organization", type: "string"},
            {name: "store", label: "Store", type: "string"},
            {name: "bu_desc", label: "Business Unit", type: "string"},
            {name: "dept_desc", label: "Department", type: "string"},
            {name: "subdept_desc", label: "Subdepartment", type: "string"},
        ],
        "views": []
    },

    // CUSTOMER RECENCY
    {
        "table": {
            "name": TABLE_CUSTOMER_RECENCY_MONTHLY,
        },
        "columns": [
            {"name": "year"},
            {"name": "month"},
            {"name": "org"},
            {"name": "dimension"},
            {"name": "dimension_value"},
            {"name": "p3m_members"},
            {"name": "p3m_members_ly"},
            {"name": "p3m_perc"},
            {"name": "p6m_members"},
            {"name": "p6m_members_ly"},
            {"name": "p6m_perc"},
            {"name": "p9m_members"},
            {"name": "p9m_members_ly"},
            {"name": "p9m_perc"},
            {"name": "p12m_members"},
            {"name": "p12m_members_ly"},
            {"name": "p12m_perc"},
            {"name": "load_date"},
        ],
        "data_filter_columns": [
            {name: "org", label: "Organization", type: "string"},
            {name: "dimension", label: "Dimension", type: "string"},
            {name: "dimension_value", label: "Dimension Value", type: "string"},
        ],
        "views": []
    },
    {
        "table": {
            "name": TABLE_CUSTOMER_RECENCY_YEARLY,
        },
        "columns": [
            {"name": "year"},
            {"name": "org"},
            {"name": "dimension"},
            {"name": "dimension_value"},
            {"name": "p3m_members"},
            {"name": "p3m_members_ly"},
            {"name": "p3m_perc"},
            {"name": "p6m_members"},
            {"name": "p6m_members_ly"},
            {"name": "p6m_perc"},
            {"name": "p9m_members"},
            {"name": "p9m_members_ly"},
            {"name": "p9m_perc"},
            {"name": "p12m_members"},
            {"name": "p12m_members_ly"},
            {"name": "p12m_perc"},
            {"name": "load_date"},
        ],
        "data_filter_columns": [
            {name: "org", label: "Organization", type: "string"},
            {name: "dimension", label: "Dimension", type: "string"},
            {name: "dimension_value", label: "Dimension Value", type: "string"},
        ],
        "views": []
    },
    
    // SKU PERFORMANCE
    {
        "table": {
            "name": TABLE_SKU_PERFORMANCE_MONTHLY,
        },
        "columns": [
            {"name": "org"},
            {"name": "rank"},
            {"name": "year"},
            {"name": "month"},
            {"name": "store"},
            {"name": "bu_desc"},
            {"name": "dept_desc"},
            {"name": "subdept_desc"},
            {"name": "top_sku"},
            {"name": "top_sales"},
            {"name": "bottom_sku"},
            {"name": "bottom_sales"},
            {"name": "load_date"},
        ],
        "data_filter_columns": [
            {name: "org", label: "Organization", type: "string"},
            {name: "bu_desc", label: "Business Unit", type: "string"},
            {name: "dept_desc", label: "Department", type: "string"},
            {name: "subdept_desc", label: "Subdepartment", type: "string"},
        ],
        "views": []
    },
    {
        "table": {
            "name": TABLE_SKU_PERFORMANCE_YEARLY,
        },
        "columns": [
            {"name": "org"},
            {"name": "rank"},
            {"name": "year"},
            {"name": "store"},
            {"name": "bu_desc"},
            {"name": "dept_desc"},
            {"name": "subdept_desc"},
            {"name": "top_sku"},
            {"name": "top_sales"},
            {"name": "bottom_sku"},
            {"name": "bottom_sales"},
            {"name": "load_date"},
        ],
        "data_filter_columns": [
            {name: "org", label: "Organization", type: "string"},
            {name: "bu_desc", label: "Business Unit", type: "string"},
            {name: "dept_desc", label: "Department", type: "string"},
            {name: "subdept_desc", label: "Subdepartment", type: "string"},
        ],
        "views": []
    },

    // HOMEBRANCH
    {
        "table": {
            "name": TABLE_HOMEBRANCH_MONTHLY,
        },
        "columns": [
            {"name": "year"},
            {"name": "month"},
            {"name": "org"},
            {"name": "homebranch"},
            {"name": "core_members"},
            {"name": "core_members_ly"},
            {"name": "sales"},
            {"name": "sales_ly"},
            {"name": "txn"},
            {"name": "txn_ly"},
            {"name": "atv"},
            {"name": "atv_ly"},
            {"name": "visit_freq"},
            {"name": "visit_freq_ly"},
            {"name": "avg_spend_visit"},
            {"name": "avg_spend_visit_ly"},
            {"name": "load_date"},
        ],
        "data_filter_columns": [
            {name: "org", label: "Organization", type: "string"},
            {name: "homebranch", label: "Homebranch", type: "string"},
        ],
        "views": []
    },
    {
        "table": {
            "name": TABLE_HOMEBRANCH_YEARLY,
        },
        "columns": [
            {"name": "year"},
            {"name": "org"},
            {"name": "homebranch"},
            {"name": "core_members"},
            {"name": "core_members_ly"},
            {"name": "sales"},
            {"name": "sales_ly"},
            {"name": "txn"},
            {"name": "txn_ly"},
            {"name": "atv"},
            {"name": "atv_ly"},
            {"name": "visit_freq"},
            {"name": "visit_freq_ly"},
            {"name": "avg_spend_visit"},
            {"name": "avg_spend_visit_ly"},
            {"name": "load_date"},
        ],
        "data_filter_columns": [
            {name: "org", label: "Organization", type: "string"},
            {name: "homebranch", label: "Homebranch", type: "string"},
        ],
        "views": []
    },

    // SEGMENTS
    {
        "table": {
            "name": TABLE_CUSTOMER_ANALYTICS_SEGMENTS_YEARLY,
        },
        "columns": [
            {"name": "org"},
            {"name": "store"},
            {"name": "year"},
            {"name": "segment_period"},
            {"name": "segment_type"},
            {"name": "segment_label"},
            {"name": "member_count"},
            {"name": "avg_sales"},
            {"name": "avg_spend_visit"},
            {"name": "atv"},
            {"name": "avg_daily_qty"},
            {"name": "avg_revisit"},
            {"name": "visit_freq"},
            {"name": "avg_recency"},
            {"name": "avg_txn"},
            {"name": "frequency"}
        ],
        "data_filter_columns": [
            {name: "org", label: "Organization", type: "string"},
            {name: "store", label: "Store", type: "string"},
            {name: "segment_type", label: "Segment Type", type: "string"},
            {name: "segment_label", label: "Segment Label", type: "string"},
        ],
        "views": []
    },
    {
        "table": {
            "name": TABLE_SMAC_CUSTOMER_SEGMENTS_MONTHLY,
        },
        "columns": [
            {"name": "year"},
            {"name": "month"},
            {"name": "org"},
            {"name": "store"},
            {"name": "bu_grp"},
            {"name": "dimension"},
            {"name": "dimension_value"},
            {"name": "member_count"},
            {"name": "member_count_ly"},
            {"name": "avg_sales"},
            {"name": "avg_sales_ly"},
            {"name": "avg_txn"},
            {"name": "avg_txn_ly"},
            {"name": "atv"},
            {"name": "atv_ly"},
            {"name": "visit_freq"},
            {"name": "visit_freq_ly"},
            {"name": "avg_spend_visit"},
            {"name": "avg_spend_visit_ly"},
            {"name": "load_date"},
        ],
        "data_filter_columns": [
            {name: "org", label: "Organization", type: "string"},
            {name: "store", label: "Store", type: "string"},
            {name: "bu_grp", label: "Business Unit Group", type: "string"},
            {name: "dimension", label: "Segment Type", type: "string"},
            {name: "dimension_value", label: "Segment Label", type: "string"},
        ],
        "views": []
    },
    {
        "table": {
            "name": TABLE_SMAC_CUSTOMER_SEGMENTS_YEARLY,
        },
        "columns": [
            {"name": "year"},
            {"name": "org"},
            {"name": "store"},
            {"name": "bu_grp"},
            {"name": "dimension"},
            {"name": "dimension_value"},
            {"name": "member_count"},
            {"name": "member_count_ly"},
            {"name": "avg_sales"},
            {"name": "avg_sales_ly"},
            {"name": "avg_txn"},
            {"name": "avg_txn_ly"},
            {"name": "atv"},
            {"name": "atv_ly"},
            {"name": "visit_freq"},
            {"name": "visit_freq_ly"},
            {"name": "avg_spend_visit"},
            {"name": "avg_spend_visit_ly"},
            {"name": "load_date"},
        ],
        "data_filter_columns": [
            {name: "org", label: "Organization", type: "string"},
            {name: "store", label: "Store", type: "string"},
            {name: "bu_grp", label: "Business Unit Group", type: "string"},
            {name: "dimension", label: "Segment Type", type: "string"},
            {name: "dimension_value", label: "Segment Label", type: "string"},
        ],
        "views": []
    },

    
    // BASKET SIZE
    {
        "table": {
            "name": TABLE_BASKET_SIZE_MONTHLY,
        },
        "columns": [
            {"name": "year"},
            {"name": "month"},
            {"name": "org"},
            {"name": "store"},
            {"name": "basket_size"},
            {"name": "member_count"},
            {"name": "member_count_ly"},
            {"name": "txn"},
            {"name": "txn_ly"},
            {"name": "sales"},
            {"name": "sales_ly"},
            {"name": "sales_perc"},
            {"name": "sales_perc_ly"},
            {"name": "load_date"},
        ],
        "data_filter_columns": [
            {name: "org", label: "Organization", type: "string"},
            {name: "store", label: "Store", type: "string"},
        ],
        "views": []
    },
    {
        "table": {
            "name": TABLE_BASKET_SIZE_YEARLY,
        },
        "columns": [
            {"name": "year"},
            {"name": "org"},
            {"name": "store"},
            {"name": "basket_size"},
            {"name": "member_count"},
            {"name": "member_count_ly"},
            {"name": "txn"},
            {"name": "txn_ly"},
            {"name": "sales"},
            {"name": "sales_ly"},
            {"name": "sales_perc"},
            {"name": "sales_perc_ly"},
            {"name": "load_date"},
        ],
        "data_filter_columns": [
            {name: "org", label: "Organization", type: "string"},
            {name: "store", label: "Store", type: "string"},
        ],
        "views": []
    },
]

export const dashboardComponents = [
    {
        component: "Acquisition",
        id: COMPONENT_ACQUISITION,
        subcomponents: [
            {
                component: "SMAC members",
                id: COMPONENT_SMAC_MEMBERS_ACQUISITION 
            },
            {
                component: "SMAC card types",
                id: COMPONENT_SMAC_CARD_TYPES_ACQUISITION
            },
            {
                component: "SMAC card status",
                id: COMPONENT_SMAC_CARD_STATUS   
            },
            {
                component: "Member penetration",
                id: COMPONENT_MEMBER_PENETRATION
            },
        ]
    },
    {
        component: "Registration",
        id: COMPONENT_REGISTRAION,
        subcomponents: [
            {
                component: "SMAC members",
                id: COMPONENT_SMAC_MEMBERS_REGISTRATION 
            },
            {
                component: "SMAC card types",
                id: COMPONENT_SMAC_CARD_TYPES_REGISTRATION
            },
            {
                component: "Customer demographics",
                id: COMPONENT_CUSTOMER_DEMOGRAPHICS,
                subcomponents:[
                    {
                        component: "Generation",
                        id: COMPONENT_CUSTOMER_GENERATION 
                    },
                    {
                        component: "Age",
                        id: COMPONENT_CUSTOMER_AGE
                    },
                    {
                        component: "Gender",
                        id: COMPONENT_CUSTOMER_GENDER 
                    },
                    {
                        component: "Marital Status",
                        id: COMPONENT_CUSTOMER_MARITAL_STATUS
                    },
                    {
                        component: "Contact Information",
                        id: COMPONENT_CUSTOMER_CONTACT_INFO 
                    },
                    {
                        component: "Campaign Subscription",
                        id: COMPONENT_CUSTOMER_CAMPAIGN_SUBSCRIPTION 
                    },
                ]
            },
        ]
    },
    {
        component: "Usage",
        id: COMPONENT_USAGE,
        subcomponents:[
            {
                component: "SMAC active members",
                id: COMPONENT_SMAC_ACTIVE_MEMBERS 
            },
            {
                component: "SMAC card types",
                id: COMPONENT_SMAC_CARD_TYPES_USAGE
            },
            {
                component: "Customer activity by homebranch",
                id: COMPONENT_CUSTOMER_ACTIVITY_BY_HOMEBRANCH
            },
            {
                component: "Customer recency",
                id: COMPONENT_CUSTOMER_RECENCY
            },
            {
                component: "Customer atv by basket tier",
                id: COMPONENT_CUSTOMER_ATV_BY_BASKET_TIER
            },
            {
                component: "Customer segments",
                id: COMPONENT_CUSTOMER_SEGMENTS
            },
            {
                component: "Customer activity by business unit",
                id: COMPONENT_CUSTOMER_ACTIVITY_BY_BU
            },
            {
                component: "SKU performance by sales",
                id: COMPONENT_SKU_PERFORMANCE_BY_SALES
            },
            {
                component: "Sales per customer by day of week and time of day",
                id: COMPONENT_SALES_PER_CUSTOMER_BY_DOW_AND_TOD
            }
        ]
    },
]

export const dashboardDefinition = {
    name: dashboardName,
    data_sources: dashboardDatasource,
    entry_points: [
        'retail-dashboard/smac-dashboard',
        'dac/smac-dashboard-ar',
        'dac/smac-acquisition',
        'dac/smac-registration',
        'dac/smac-usage'
    ],
    owners: [
        "jethro.guzman@sminvestments.com"
    ],
    path: DASHBOARD_PATH,  // this is the main path of the dashboard
    components: dashboardComponents
}