import React, {useState} from "react";
import Grid from "@mui/material/Grid";
import {Box, Tab, Tabs} from "@mui/material";
import TableSelection from "../RowFiltersTab/TableSelection";
import {useDashboardTableList} from "../hooks";
import ColumnsTab from "../ColumnsTab";
import RowFiltersTab from "../RowFiltersTab";
import TableTabs from "./TableTabs";


export default function DataTab({restrictionsPerTable, setRestrictionsPerTable, dashboardDefinition, dashboardId}) {


    const [selectedTable, setSelectedTable] = useState(null)


    const {data: dashboardTableList, isDashboardTableListLoading} = useDashboardTableList({dashboardId: dashboardId})


    const selectedTableRestrictions = restrictionsPerTable.hasOwnProperty(selectedTable) ?
        restrictionsPerTable[selectedTable] :
        {}

    const setSelectedTableRestrictions = (dataOrFn) => {
        if (!selectedTable) {
            console.warn("There is no selected table")
            return
        }

        setRestrictionsPerTable(prevState => {
            const oldTableRestriction = prevState[selectedTable] || {};
            const newTableRestriction = typeof dataOrFn === 'function' ?
                dataOrFn(oldTableRestriction) :
                {
                    ...oldTableRestriction,
                    ...dataOrFn
                }

            return {
                ...prevState,
                [selectedTable]: newTableRestriction
            }
        })
    }

    return (
        <Grid item xs={12}>

            <Box component="div" sx={{p: 2}}>
                The following data sources are used in this dashboard. You can set restrictions on the data that is displayed in the dashboard.
            </Box>

            <Grid xs={12}>
                <Grid item xs={12} mt={1} ml={1}>
                    <TableSelection
                        selectedTable={selectedTable}
                        setSelectedTable={setSelectedTable}
                        data={dashboardTableList}
                        isLoading={isDashboardTableListLoading}
                    />
                </Grid>
            </Grid>

            <TableTabs
                selectedTable={selectedTable}
                dashboardDefinition={dashboardDefinition}
                tableRestrictions={selectedTableRestrictions}
                setTableRestrictions={setSelectedTableRestrictions}
            />

        </Grid>
    )
}