import React, {useMemo} from "react";
import {Autocomplete, TextField} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import {useFilterValues} from "../../../../../common/API/hooks";
import LoadingComponent from "../../../../../common/components/LoadingComponent";


export const OPERATORS_WITHOUT_VALUE = ['null', 'notNull']
export const OPERATORS_MULTI_SELECT = ['in', 'notIn']


const optionToValue = (optionOrOptions) => {
    if (Array.isArray(optionOrOptions)) {
        return optionOrOptions.map(option => {
            if (option.hasOwnProperty('value')) {
                return option.value
            }
            return option
        })
    } else {
        if (optionOrOptions.hasOwnProperty('value')) {
            return optionOrOptions.value
        }
        return optionOrOptions

    }
}

export default function QueryBuilderValueEditor({field, operator, value, handleOnChange, context}) {
    const {data: filterValuesRaw, isLoading} = useFilterValues(context.selectedTable, [field], {}, {enabled: true})

    const isMultiple = OPERATORS_MULTI_SELECT.includes(operator)
    const dataFilterDefinition = context.datasourceDefinition?.data_filter_columns?.find(item => item.name === field)

    const availableOptions = useMemo(() => {
        if (!filterValuesRaw) return []

        if (dataFilterDefinition?.type === 'boolean' || dataFilterDefinition?.type === 'date') {
            return []  // TODO: handle boolean and date types
        }

        const rawOptions = filterValuesRaw?.[field]
        if (typeof rawOptions === 'undefined' || typeof rawOptions === 'null' || !rawOptions) {
            return []
        } else if (Array.isArray(rawOptions)) {

            const options = rawOptions.map((item) => {
                return item[field]
            }).filter(value => value !== null)
            .sort()

            return options
        } else if (typeof rawOptions === 'object' && rawOptions.hasOwnProperty('min') && rawOptions.hasOwnProperty('max')) {
            // create range
            const range = []
            for (let i = rawOptions.min; i <= rawOptions.max; i++) {
                range.push({value: i, label: i.toString()})
            }
            return range
        }
        
    }, [filterValuesRaw, dataFilterDefinition])

    const valueOrValues = useMemo(() => {
        if (isMultiple) {
            if (!value) {
                return []
            }
            if (Array.isArray(value)) {
                return value
            } else {
                return [value]
            }
        } else {
            if (!value) {
                return ""
            }
            if (Array.isArray(value)) {
                if (value.length > 0) {
                    return value[0]
                } else {
                    return ""
                }
            }
            return value
        }
    }, [value, isMultiple])

    const selectedOptions = useMemo(() => {
        if (isMultiple) {
            if (!valueOrValues) return []
        } else {
            if (!valueOrValues) return null
        }

        if (!availableOptions) return valueOrValues;

        const optionType = availableOptions[0]?.hasOwnProperty('value') ? 'object' : 'value'

        if (optionType === 'object') {
            if (isMultiple) {
                return availableOptions.filter(option => valueOrValues.includes(option.value))
            } else {
                return availableOptions.find(option => option.value === valueOrValues)
            }
        }

        // optionType === 'value'
        if (isMultiple) {
            return availableOptions.filter(option => valueOrValues.includes(option))
        } else {
            return availableOptions.find(option => option === valueOrValues)
        }
    }, [valueOrValues, availableOptions, isMultiple])

    if (isLoading) {
        return <LoadingComponent/>
    }
    if (operator && OPERATORS_WITHOUT_VALUE.includes(operator)) {
        return null
    }

    return (
        <Autocomplete
            options={availableOptions}
            value={selectedOptions}
            onChange={(event, newValue) => {
                handleOnChange(optionToValue(newValue))
            }}
            renderInput={(params) => <TextField
                {...params}
                label={'Select ' + field}

                InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                        <>
                            {isLoading ? <CircularProgress size={15}/> : null}
                            {params.InputProps.endAdornment}
                        </>
                    ),
                }}/>}
            disableClearable
            multiple={isMultiple}
            size='small'
            sx={{width: '50%'}}
        />
    );
}
