import React from 'react';
import {useDashboardsComponentBlacklistQuery} from "./hooks";
import {BlacklistedComponentsContext} from "../../contexts";

export default function BlacklistedComponentsProvider({children}) {
    const {data, isLoading} = useDashboardsComponentBlacklistQuery()

    const providerValue = React.useMemo(() => {
        if (isLoading || !data ) {
            return {
                blacklistedComponentsByDashboardId: {},
                blacklistedComponentsByDashboardPath: {},
                isLoading: isLoading
            }
        }

        return {
            blacklistedComponentsByDashboardId: data.reduce((acc, item) => {
                acc[item.id] = item.blacklisted_components;
                return acc;
            }, {}),
            blacklistedComponentsByDashboardPath: data.reduce((acc, item) => {
                acc[item.path] = item.blacklisted_components;
                return acc;
            }, {}),
            isLoading: false
        }
    }, [data, isLoading])


    return (
        <BlacklistedComponentsContext.Provider value={providerValue}>
            {children}
        </BlacklistedComponentsContext.Provider>
    )
}