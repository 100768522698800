import { useMemo, useState, useEffect, useContext } from "react"
import Box from "@mui/material/Box"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import TablePagination from '@mui/material/TablePagination'
import TablePaginationActions from "./TablePaginationActions"
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import { WindowSizeContext } from '../../../src/common/context/WindowSizeContext';

import {
	useReactTable,
	getCoreRowModel,
	getPaginationRowModel,
	getSortedRowModel,
	flexRender,
	getExpandedRowModel,
} from "@tanstack/react-table"

export default function TableComponentV2({ columns, data, tableId, hasPagination, enableRowSelection, onSelectRow, isCompact, initialSort, hasSubRow, hasFooterSubtotal, subtotalRow, customTableContainerStyle, customTableStyle, customTableHeaderStyle, propPageSize, isOpexAccounts, paginateExpandedRows, initialState, rowSelectionDepth = 0, isInventoryTable, hasHiddenSubTotalRow }) {

	const initialPageSize = propPageSize || (hasPagination ? 10 : 100);
	const [sorting, setSorting] = useState(initialSort ? [initialSort] : [])
	const [columnVisibility, setColumnVisibility] = useState(initialState ? initialState?.columnVisibility : {})
	const memoizedData = useMemo(() => data, [data])
	const memoizedColumns = useMemo(() => columns, [columns])
	const [expanded, setExpanded] = useState(false)
	const windowSize = useContext(WindowSizeContext);
	useEffect(() => {
		setColumnVisibility(initialState?.columnVisibility);
	}, [initialState?.columnVisibility]);
	const table = useReactTable({
		data: memoizedData,
		columns: memoizedColumns,
		state: {
			sorting,
			expanded,
			columnVisibility
		},
		initialState: {
			pagination: {
				pageSize: initialPageSize
			},
		},
		autoResetAll: false,
		getExpandedRowModel: getExpandedRowModel(),
		onExpandedChange: setExpanded,
		getSubRows: row => row.subRows,
		enableRowSelection: enableRowSelection,
		onSortingChange: setSorting,
		getCoreRowModel: getCoreRowModel(),
		getPaginationRowModel: getPaginationRowModel(),
		paginateExpandedRows: paginateExpandedRows,
		getSortedRowModel: getSortedRowModel(),
		onColumnVisibilityChange: setColumnVisibility,
		enableSortingRemoval: false,
	})

	const { pageSize, pageIndex } = table.getState().pagination

	const [isOpexAccount, setIsOpexAccount] = useState(isOpexAccounts);

	useEffect(() => {
		// gets expanded rows with depth === 0
		const isDepthZeroExpanded = table.getRowModel().rows.some((row) => row.depth === 0 && expanded[row.id]);

		// gets rows with depth !== 0 and not expanded
		const isAnyDeeperRowsExpanded = table.getRowModel().rows.some((row) => row.depth !== 0 && !expanded[row.id]);

		if (isOpexAccount) {
			table.getRowModel().rows.forEach((row) => {
				if (row.depth === 0 && !expanded[row.id]) {
					setExpanded((prevExpanded) => ({
						...prevExpanded,
						[row.id]: true,
					}));
				}
			});
			setIsOpexAccount(false)
		}
		else if (!isDepthZeroExpanded && !isAnyDeeperRowsExpanded && (typeof (expanded) !== 'object' || Object.keys(expanded).length > 0)) {
			setExpanded({});
		}

	}, [isOpexAccounts, expanded, table, isOpexAccount]);


	const fixedColumnStyle = (stickyColumn) => ({
		paddingY: isCompact ? 0.05 : '',
		borderRight: 1,
		borderLeft: 1,
		borderColor: '#e0e0e0',
		position: stickyColumn ? 'sticky' : 'static',
		left: stickyColumn ? -2 : 'auto',
		backgroundColor: stickyColumn ? 'white' : 'transparent',
		zIndex: stickyColumn ? 2 : 'auto',
	});

	const getSubTotalRowColumns = (subtotalRow, columnVisibility) => {
		const hiddenColumns = Object.keys(columnVisibility).filter(key => !columnVisibility[key]);
		const filteredSubTotalRows = Object.keys(subtotalRow).filter(key => hiddenColumns.includes(key));
		return filteredSubTotalRows;
	}

	const tableContainerStyle = customTableContainerStyle || {};
	const tableHeaderStyle = customTableHeaderStyle || {};
	const tableStyle = customTableStyle || {};
	return (
		<Box sx={{ width: '100%' }}>
			<TableContainer component={Paper} sx={{ ...tableContainerStyle }}>
				<Table sx={{ ...tableStyle, minWidth: 250, }} aria-label="react-table-v2">
					<TableHead>
						{table.getHeaderGroups().map((headerGroup, rowIndex) => (
							<TableRow key={headerGroup.id}>
								{headerGroup.headers.map((header, columnIndex) => {
									const stickyColumn = columnIndex === 0;
									const firstRow = rowIndex === 0;
									const headerStyle = firstRow && isInventoryTable ? { backgroundColor: '#0A4FB4', color: 'white' } : tableHeaderStyle

									return (
										<TableCell key={header.id} colSpan={header.colSpan} sx={{
											...fixedColumnStyle(stickyColumn),
											...header.column.columnDef?.customStyle,											
											...headerStyle,
											...(header.column?.columnDef?.headerStyle || {}),
											paddingX: .5
										}}>

											{header.isPlaceholder ? null : (
												<Box display="flex" justifyContent="center" fontWeight="bold" alignItems="center" onClick={header.column.getToggleSortingHandler()}
													sx={{
														'&:hover': {
															cursor: header.column.getCanSort() ? 'pointer' : ''
														}
													}}
												>
													{
														flexRender(
															header.column.columnDef.header,
															header.getContext()
														)
													}
													{
														{
															asc: <KeyboardArrowUpIcon />,
															desc: <KeyboardArrowDownIcon />,
														}[header.column.getIsSorted()] ?? (header.column.getCanSort() ? <UnfoldMoreIcon color="disabled" fontSize="small" /> : null)
													}
												</Box>
											)}
										</TableCell>
									)
								})}
							</TableRow>
						))}
					</TableHead>
					<TableBody>
						{table.getRowModel().rows.map(row => {
							if (row.original._isFooter) {
								return null;
							}
							return (
								<TableRow key={row.id} onClick={(enableRowSelection && row.depth <= rowSelectionDepth) ? () => onSelectRow(row.original) : null} sx={{ "&:hover": { backgroundColor: '#eeeeee' } }}>
									{row.getVisibleCells().map((cell, columnIndex) => {

										const stickyColumn = columnIndex === 0;
										const isSubRow = row.depth > 0;
										const isSubSubRow = row.depth > 1;
										const rowBackgroundColor = isSubRow ? '#eeeeee' : 'white';
										const subRowBackgroundColor = isSubSubRow ? '#fafafa' : 'white';

										return (
											<TableCell key={cell.id} sx={{  ...fixedColumnStyle(stickyColumn), ...cell.column.columnDef?.customStyle, paddingX: .5, backgroundColor: isSubSubRow ? subRowBackgroundColor : rowBackgroundColor }} >
												{flexRender(
													cell.column.columnDef.cell,
													cell.getContext()
												)}
											</TableCell>
										)
									})}
								</TableRow>
							)
						})}
						{table.getCoreRowModel().rows.map(row => {
							if (!row.original._isFooter) {
								return null;
							}
							// TODO: fix duplication of code
							// FIXME: this may not work with pagination
							return (
								<TableRow key={row.id} onClick={(enableRowSelection && row.depth <= rowSelectionDepth) ? () => onSelectRow(row.original) : null} sx={{ "&:hover": { backgroundColor: '#eeeeee' } }}>
									{row.getVisibleCells().map((cell, columnIndex) => {

										const stickyColumn = columnIndex === 0;
										const isSubRow = row.depth > 0;
										const isSubSubRow = row.depth > 1;
										const rowBackgroundColor = isSubRow ? '#eeeeee' : 'white';
										const subRowBackgroundColor = isSubSubRow ? '#fafafa' : 'white';

										return (
											<TableCell key={cell.id} sx={{  ...fixedColumnStyle(stickyColumn), ...cell.column.columnDef?.customStyle, paddingX: .5, backgroundColor: isSubSubRow ? subRowBackgroundColor : rowBackgroundColor }} >
												{flexRender(
													cell.column.columnDef.cell,
													cell.getContext()
												)}
											</TableCell>
										)
									})}
								</TableRow>
							)
						})}
						{(hasHiddenSubTotalRow || subtotalRow) && hasFooterSubtotal &&
							<TableRow sx={{ borderTop: 3, borderColor: '#969696' }}>
								<TableCell sx={{ ...fixedColumnStyle(true), paddingX: 0.5 }}>
									{isInventoryTable ? 'TOTAL' : 'SUBTOTAL'}
								</TableCell>
								{(hasHiddenSubTotalRow ? getSubTotalRowColumns(hasHiddenSubTotalRow, columnVisibility) : Object.keys(subtotalRow)).map((key) => {
									const widthClass = String(key).startsWith('vs') || isInventoryTable ? 'auto' : '50%';
									const value = hasHiddenSubTotalRow ? hasHiddenSubTotalRow[key] : subtotalRow[key];
									return (
										<TableCell key={key} sx={{ marginX: 'auto', paddingX: 0.5, paddingY: 0.05, borderRight: 1, borderLeft: 1, borderColor: '#e0e0e0' }}>
											<Box display="flex" sx={{ width: { sm: widthClass }, marginX: 'auto' }}>
												{flexRender(value)}
											</Box>
										</TableCell>
									);
								})}
							</TableRow>
						}
					</TableBody>
				</Table>

			</TableContainer>
			{
				hasPagination && <TablePagination
					rowsPerPageOptions={[5, 10, 25, { label: 'All', value: data.length }]}
					component="div"
					count={table.getFilteredRowModel().rows.length}
					rowsPerPage={pageSize}
					page={pageIndex}
					SelectProps={{
						inputProps: { 'aria-label': 'rows per page' },
						native: true,
					}}
					onPageChange={(_, page) => {
						table.setPageIndex(page)
					}}
					onRowsPerPageChange={e => {
						const size = e.target.value ? Number(e.target.value) : 10
						table.setPageSize(size)
					}}
					ActionsComponent={TablePaginationActions}
					sx={{
						'.MuiTablePagination-toolbar': {
							justifyContent: 'end',
							alignItems: 'baseline',
							flexWrap: 'wrap',

						},
					}}
				/>
			}
		</Box>
	)
}