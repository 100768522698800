import {useContext, useEffect, useState} from "react";
import Back from "../../../common/components/BackNavigateComponent";
import SelectorProvider from "../common/providers/selectorProvider";
import {GlobalStyles, Grid, Typography} from "@mui/material";
import {WindowSizeContext} from "../../../common/context/WindowSizeContext";
import {useHasScrolledDownDetector} from "../../../common/utils/hooks";
import TabSection from "./TabSection";
import DashboardLevelFilters from "./DashboardLevelFilters";
import AsOfDate from "./AsOfDate";
import {PERIOD_YEARLY} from "../../../Constants/general";
import InformationToolTip from "../../../common/components/InformationToolTip";
import {VIEW_BY_USAGE} from "./constants";
import { registerDashboard } from "../../../common/utils";
import { dashboardDefinition } from "./dashboardDefinition";
import DashboardMetaProvider from "../common/providers/DashboardMetaProvider";

const tooltipMessage = <div style={{ width: '50rem'}}>
    <div>
        <span style={{ fontWeight:'bold'}}>Active vs Inactive</span> – A member is considered "Active" if the member made a purchase within the observation period for the selected organization or branch, otherwise they will be tagged as "Inactive"
    </div>
    <div>
        <span style={{ fontWeight:'bold'}}>Generation</span> – members are classified based on their birth year:
    </div>
    <div>&emsp;Gen Z – 1997 – 2006</div>
    <div>&emsp;Millennial – 1982 – 1996</div>
    <div>&emsp;Gen X – 1965 – 1981</div>
    <div>&emsp;Boomers – 1964 and earlier</div>
    
    <div><span style={{fontWeight: 'bold'}}>Legend</span> on the display of data/metric:</div>
    <div>&emsp;"-" - if data for the metric is not yet available</div>
    <div>&emsp;"N/A" - if metric is not applicable</div>
</div>

registerDashboard(dashboardDefinition) 

export default function SMACDashboard({title="SMAC Dashboard", fixedTabValue=null, tabBlackList=[]}) {
    const windowSize = useContext(WindowSizeContext)
    const scrolling = useHasScrolledDownDetector()
    const [tooltipOpen, setTooltipOpen] = useState(false);
    
    useEffect(() => {    
        if (scrolling && tooltipOpen) {
            setTooltipOpen(false);
        }
    }, [scrolling, tooltipOpen]);
    
    return <DashboardMetaProvider dashboardDefinition={dashboardDefinition}>
        <SelectorProvider defaults={{ 
            'period': PERIOD_YEARLY
        }}>
            <GlobalStyles
                styles={{
                    body: { backgroundColor: '#e0e0e0' },
                }}
            />
            <Grid container>
                <Grid item xs={12} sx={{ display: !windowSize.isOnMobile && "flex", justifyContent: "space-between", alignItems: "center", mt: 2 }}>
                    <Grid item xs display="flex" sx={{ alignItems: "center" }}>
                        <Back />
                        <Typography variant="h5" ml={3}>
                            {title}
                        </Typography>
                        <InformationToolTip onChange={(val)=>setTooltipOpen(val)} open={tooltipOpen} information={tooltipMessage} />
                    </Grid>
                    <Grid item xs>
                        <AsOfDate/>
                    </Grid>
                </Grid>

                <Grid container sx={{ position: {sm: 'sticky', xs: 'static'}, top: '5rem', zIndex: 5, backgroundColor:'#e0e0e0'}}>
                    <DashboardLevelFilters showUsageFilters={!!fixedTabValue && fixedTabValue === VIEW_BY_USAGE}/>
                </Grid>
                    <TabSection fixedTabValue={fixedTabValue} tabBlackList={tabBlackList}/>
            </Grid>
        </SelectorProvider>
    </DashboardMetaProvider>
}