import React from "react";
import {NewSwitch} from "./ComponentsTab";
import {useModulePermissionMutation} from "../../../../common/API/hooks";
import {Grid, Tooltip, Typography} from "@mui/material";
import {Group} from "@mui/icons-material";


export default function EntryPointModuleSwitch({module, hasAccess, hasAccessViaGroup, userName, groupName}) {
    const modulePermissionMutation = useModulePermissionMutation({
        moduleId: module.id,
        toastMessages: {
            success: `Updated access to ${module.name}`,
            error: `Failed to update access to ${module.name}`,
            pending: `Updating access to ${module.name}`
        }
    })

    const toggleAccess = () => {
        const newData = {}
        if (hasAccess) {
            if (userName) {
                newData.allowed_users = module.allowed_users.filter(user => user !== userName)
            }
            if (groupName) {
                newData.allowed_groups = module.allowed_groups.filter(group => group !== groupName)
            }
        } else {
            if (userName) {
                newData.allowed_users = [...module.allowed_users, userName]
            }
            if (groupName) {
                newData.allowed_groups = [...module.allowed_groups, groupName]
            }
        }

        modulePermissionMutation.mutate(newData)
    }

    return (
        <Grid item xs={6} sx={{whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden"}} >
            <NewSwitch
                disabled={!!hasAccessViaGroup}
                checked={hasAccess}
                onChange={(event) => {
                    toggleAccess()
                }}/>
            {
                hasAccessViaGroup && <Tooltip title={`via groups ${hasAccessViaGroup}`}>
                    <Group sx={{marginRight: "0.5em"}}/>
                </Tooltip>
            }
            <Typography variant="span">
                {module.name}
            </Typography>
        </Grid>
    );
}