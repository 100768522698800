import React from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import Section from "../../../../../common/components/Dashboard/Section";
import Box from "@mui/material/Box";
import {useTableDetail} from "../../../../../common/API/hooks";
import {Check, X} from "@mui/icons-material";
import TableNameStatusDisplay from "./TableNameStatusDisplay";
import ColumnsStatusDisplay from "./ColumnsStatusDisplay";
import SMIPViewsStatusDisplay from "./SMIPViewsStatusDisplay";


export default function DataSource({dataSource}) {
    const {data: tableDetail, isLoading} = useTableDetail(dataSource.table.name, {})

    return (
        <Section title={dataSource.table.name}>
            <Box p={1}>
                <Box>
                    <TableNameStatusDisplay tableName={dataSource.table.name} />
                </Box>
                <Box>
                    <ColumnsStatusDisplay tableName={dataSource.table.name} columns={dataSource.columns}/>
                </Box>
                <Box>
                    <SMIPViewsStatusDisplay tableName={dataSource.table.name} views={dataSource.views}/>
                </Box>
            </Box>
        </Section>
    )
}