import ApiClient from "../../../../common/API"
import { useQuery } from "react-query"


export function useCommonDashboardList() {

    return useQuery(
        ["dashboard", "dashboard_lists"],
        () => {
            return ApiClient().get(`dashboard/common-dashboard/`, {}).then((res) => res.data)
        },
        {
            refetchWindowFocus: false,
            staleTime: 600000,
        }
    );
}

export function useUserDashboardList({ userId, toggle_group }) {
    const isUser = toggle_group === 'user'

    return useQuery(
        ["user", "user_dashboard_lists", userId],
        () => {
            return ApiClient().get(`dashboard/common-dashboard/user/${userId}`, {}).then((res) => res.data)
        },
        {
            refetchWindowFocus: false,
            staleTime: 600000,
            enabled: isUser
        }
    );
}

export function useGroupDashboardList({ groupId, toggle_group }) {
    const isGroup = toggle_group === 'group'

    return useQuery(
        ["group", "group_dashboard_lists", groupId],
        () => {
            return ApiClient().get(`dashboard/common-dashboard/group/${groupId}`, {}).then((res) => res.data)
        },
        {
            refetchWindowFocus: false,
            staleTime: 600000,
            enabled: isGroup
        }
    );
}