import React, { useEffect, useState } from "react";
import { Box, Divider, Typography } from "@mui/material";
import InformationToolTip from "../../../../common/components/InformationToolTip";


export default function DPMInformationToolTip() {
    const [tooltipOpen, setTooltipOpen] = useState(false);

    useEffect(() => {
        const clearToolTipOnScroll = () => {
            // Close the tooltip when scrolling
            setTooltipOpen(false);
        };

        window.addEventListener('scroll', clearToolTipOnScroll);
        return () => {
            window.removeEventListener('scroll', clearToolTipOnScroll);
        };
    }, []);

    const infoForTooltip = [
        {
            title: "Datasource Level Management",
            info: [
                "Columns Tab: Column blacklisting removes access to display the values or data from that column in the datasource.",
                "Row Filter Tab: Construct and modify queries in a structured format to display specific values or data on the dashboard.",
                "Note: Actions taken are applied to all dashboards that use the same datasource."
            ]
        },
    ]

    const informationContent = infoForTooltip.map((section, index) => (
        <Box key={index}>
            <Typography variant="body1" fontWeight="bold" my={0.5} mt={index === 0 ? 0 : 2}>{section.title}</Typography>
            <Divider sx={{ borderBottomWidth: '2px', backgroundColor: '#000' }} />
            <Typography variant="body2">
                {section.info.map((info, i) => {
                    const parts = info.split(":");
                    return (
                        <div key={index}>
                            <span style={{ fontWeight: 'bold' }}>{parts[0]}</span>: {parts[1]}
                        </div>
                    );
                })}
            </Typography>
        </Box>
    ))

    const handleTooltipChange = (value) => {
        setTooltipOpen(value);
    };

    return <InformationToolTip onChange={handleTooltipChange} open={tooltipOpen} information={informationContent} />
}