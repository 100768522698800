import {STORE_DATA_COLUMNS, TABLE_NAME as TABLE_NAME_INVENTORY} from "./constants";

import {TABLE_NAME as TABLE_NAME_SALES} from "../Sales/hooks";
import {TABLE_SMAC_MEMBERS_MONTHLY} from "../SMACDashboard/hooks";
import {MKT_LOOKUP_STORE_TABLE} from "../../../common/hooks/branchLookup";

const storeLookupDatasourceDefinition = {
    "table": {
        "name": MKT_LOOKUP_STORE_TABLE,
    },
    "columns": [
        {"name": "area"},
        {"name": "region"},
        {"name": "store_id"},
        {"name": "mall_type"},
        {"name": "mall_category"},
        {"name": "selling_floor"},
        {"name": "new_size_segment"},
        {"name": "store_name_title"},
        {"name": "store_tagging"},
    ],
    "data_filter_columns": [
        {name: "area", label: "Area", type: "string"},
        {name: "region", label: "Region", type: "string"},
        {name: "store_id", label: "Store ID", type: "string"},
        {name: "mall_type", label: "Mall Type", type: "string"},
        {name: "mall_category", label: "Mall Category", type: "string"},
        {name: "selling_floor", label: "Selling Floor", type: "number"},
        {name: "new_size_segment", label: "Size Segment", type: "string"},
        {name: "store_name_title", label: "Store Name", type: "string"},
        {name: "store_tagging", label: "Tagging", type: "string"},
    ],
    "views": []
}


const storeInventoryDatasourceDefinition = {
    "table": {
        "name": TABLE_NAME_INVENTORY,
    },
    "columns": [
        {name: "branch_id"},
        {name: "company_id"},
        {name: "branch_name"},

        {name: "actual_sales"},
        {name: "inventory_stock"},
        {name: "sales_at_cost"},
        {name: "article_description"},
        {name: "store_subtype"},
        {name: "store_type"},
        {name: "store_tagging"},

        {name: "inv_transaction_date"},
        {name: "sales_transaction_date"},
        {name: "transaction_date"},
        {name: "load_date"},

        {name: "damaged_value"},
        {name: "rtv_value"},
        {name: "inventory_value"},

        {name: "article_type"},
        {name: "article_number"},
        {name: "food_group"},
        {name: "top_food_group"},
    ],
    data_filter_columns: [
        {name: "branch_id", label: "Branch ID", type: "string"},
        {name: "company_id", label: "Company ID", type: "string"},
        {name: "branch_name", label: "Branch Name", type: "string"},
        {name: "store_subtype", label: "Store Subtype", type: "string"},
        {name: "store_type", label: "Store Type", type: "string"},
        {name: "store_tagging", label: "Store Tagging", type: "string"},

        {name: "article_type", label: "Article Type", type: "string"},
        {name: "article_number", label: "Article Number", type: "string"},
        {name: "article_description", label: "Article Description", type: "string"},
        {name: "food_group", label: "Food Group", type: "string"},
        {name: "top_food_group", label: "Top Food Group", type: "string"},
    ],
    views: [
        {
            name: 'rtv_damanged',
            sql: 'SELECT\n' +
                'company_id,\n' +
                'branch_id,\n' +
                'branch_name,\n' +
                'transaction_date,\n' +
                'MAX(rtv_value) as rtv_value,\n' +
                'MAX(damaged_value) as damaged_value\n' +
                'FROM {path} as inner_table\n' +
                'GROUP BY 1,2,3,4'
        },
        {
            name: 'inventory_value',
            sql: 'SELECT\n' +
                '  company_id,\n' +
                '  branch_id,\n' +
                '  branch_name,\n' +
                '  top_food_group,\n' +
                '  food_group,\n' +
                '  MAX(inv_transaction_date) as transaction_date_max,\n' +
                '  DATE_TRUNC(inv_transaction_date, MONTH) as transaction_date,\n' +
                '  DATE_TRUNC(inv_transaction_date, MONTH) as inv_transaction_date,\n' +
                '  SUM(inventory_value) as inventory_value\n' +
                'FROM {path} as inner_table\n' +
                'WHERE transaction_date IN (SELECT max(inv_transaction_date) FROM {path} as date_table GROUP BY DATE_TRUNC(inv_transaction_date, MONTH))\n' +
                'GROUP BY 1,2,3,4,5, DATE_TRUNC(inv_transaction_date, MONTH)'
        }
    ]
}

const storeSalesDatasourceDefinition = {
    table: {
        name: TABLE_NAME_SALES,
    },
    columns: [
        {name: "company"},
        {name: "company_id"},
    ],
    views: []
}


// components:
//  Inventory Topline
export const COMPONENT_INVENTORY_TOPLINE = 'markets_inventory_topline'
export const COMPONENT_INVENTORY_TOPLINE_DAYS = 'markets_inventory_topline_inventory_days'
export const COMPONENT_INVENTORY_TOPLINE_VALUE = 'markets_inventory_topline_inventory_value'
export const COMPONENT_INVENTORY_TOPLINE_RTV_PERC_TO_SALES = 'markets_inventory_topline_rtv_percent_to_sales'
export const COMPONENT_INVENTORY_TOPLINE_DAMAGED_PERC_TO_SALES = 'markets_inventory_topline_damaged_percent_to_sales'

//  Inventory By Group
export const COMPONENT_INVENTORY_BY_GROUP = 'markets_inventory_by_group'
export const COMPONENT_INVENTORY_BY_PRODUCT_CATEGORY = 'markets_inventory_by_product_category'
export const COMPONENT_INVENTORY_BY_REGION = 'markets_inventory_by_region'
export const COMPONENT_INVENTORY_BY_STORE_SIZE = 'markets_inventory_by_store_size'
export const COMPONENT_INVENTORY_BY_STORE_TYPE = 'markets_inventory_by_store_type'
export const COMPONENT_INVENTORY_BY_STORE_SUBTYPE = 'markets_inventory_by_store_subtype'

export const COMPONENT_INVENTORY_BY_GROUP_LABEL = 'markets_inventory_by_group_label'

export const COMPONENT_INVENTORY_BY_GROUP_TOTAL_INVENTORY = 'markets_inventory_store_view_total_inventory'
export const COMPONENT_INVENTORY_BY_GROUP_TOTAL_INVENTORY_DAYS = 'markets_inventory_store_view_total_inventory_days'
export const COMPONENT_INVENTORY_BY_GROUP_TOTAL_INVENTORY_VALUE = 'markets_inventory_store_view_total_inventory_value'

export const COMPONENT_INVENTORY_BY_GROUP_RETURN_TO_VENDOR = 'markets_inventory_store_view_return_to_vendor'
export const COMPONENT_INVENTORY_BY_GROUP_RETURN_TO_VENDOR_VALUE = 'markets_inventory_store_view_return_to_vendor_value'
export const COMPONENT_INVENTORY_BY_GROUP_RETURN_TO_VENDOR_PERC_TO_SALES = 'markets_inventory_store_view_return_to_vendor_percent_to_sales'

export const COMPONENT_INVENTORY_BY_GROUP_DAMAGED = 'markets_inventory_store_view_damaged'
export const COMPONENT_INVENTORY_BY_GROUP_DAMAGED_VALUE = 'markets_inventory_store_view_damaged_value'
export const COMPONENT_INVENTORY_BY_GROUP_DAMAGED_PERC_TO_SALES = 'markets_inventory_store_view_damaged_percent_to_sales'

//  Inventory Store View
export const COMPONENT_INVENTORY_STORE_VIEW = 'markets_inventory_store_view'
export const COMPONENT_INVENTORY_STORE_NAME = 'markets_inventory_store_name'

export const COMPONENT_INVENTORY_STORE_VIEW_TOTAL_INVENTORY = 'markets_inventory_store_view_total_inventory'
export const COMPONENT_INVENTORY_STORE_VIEW_TOTAL_INVENTORY_DAYS = 'markets_inventory_store_view_total_inventory_days'
export const COMPONENT_INVENTORY_STORE_VIEW_TOTAL_INVENTORY_VALUE = 'markets_inventory_store_view_total_inventory_value'

export const COMPONENT_INVENTORY_STORE_VIEW_RETURN_TO_VENDOR = 'markets_inventory_store_view_return_to_vendor'
export const COMPONENT_INVENTORY_STORE_VIEW_RETURN_TO_VENDOR_VALUE = 'markets_inventory_store_view_return_to_vendor_value'
export const COMPONENT_INVENTORY_STORE_VIEW_RETURN_TO_VENDOR_PERC_TO_SALES = 'markets_inventory_store_view_return_to_vendor_percent_to_sales'

export const COMPONENT_INVENTORY_STORE_VIEW_DAMAGED = 'markets_inventory_store_view_damaged'
export const COMPONENT_INVENTORY_STORE_VIEW_DAMAGED_VALUE = 'markets_inventory_store_view_damaged_value'
export const COMPONENT_INVENTORY_STORE_VIEW_DAMAGED_PERC_TO_SALES = 'markets_inventory_store_view_damaged_percent_to_sales'

export const dashboardComponents = [
    {
        component: "Inventory Topline",
        id: COMPONENT_INVENTORY_TOPLINE,
        subcomponents: [
            {component: "Inventory Days", id: COMPONENT_INVENTORY_TOPLINE_DAYS},
            {component: "Inventory Value", id: COMPONENT_INVENTORY_TOPLINE_VALUE},
            {component: "RTV % to Sales", id: COMPONENT_INVENTORY_TOPLINE_RTV_PERC_TO_SALES},
            {component: "Damaged % to Sales", id: COMPONENT_INVENTORY_TOPLINE_DAMAGED_PERC_TO_SALES},
        ]
    },
    {
        component: "Inventory By Group",
        id: COMPONENT_INVENTORY_BY_GROUP,
        subcomponents: [
            {component: "Product Category", id: COMPONENT_INVENTORY_BY_PRODUCT_CATEGORY},
            {component: "Region", id: COMPONENT_INVENTORY_BY_REGION},
            {component: "Store Size", id: COMPONENT_INVENTORY_BY_STORE_SIZE},
            {component: "Store Type", id: COMPONENT_INVENTORY_BY_STORE_TYPE},
            {component: "Store Subtype", id: COMPONENT_INVENTORY_BY_STORE_SUBTYPE},

            {component: "Group Label", id: COMPONENT_INVENTORY_BY_STORE_SUBTYPE},
            {
                component: "Total Inventory", 
                id: COMPONENT_INVENTORY_BY_GROUP_TOTAL_INVENTORY,
                subcomponents:[
                    {component: "Days", id: COMPONENT_INVENTORY_BY_GROUP_TOTAL_INVENTORY_DAYS},
                    {component: "Value", id: COMPONENT_INVENTORY_BY_GROUP_TOTAL_INVENTORY_VALUE},
                ],
            },
            {
                component: "Return to Vendor", 
                id: COMPONENT_INVENTORY_BY_GROUP_RETURN_TO_VENDOR,
                subcomponents:[
                    {component: "Value", id: COMPONENT_INVENTORY_BY_GROUP_RETURN_TO_VENDOR_VALUE},
                    {component: "% to Sales", id: COMPONENT_INVENTORY_BY_GROUP_RETURN_TO_VENDOR_PERC_TO_SALES},
                ],
            },
            {
                component: "Damaged", 
                id: COMPONENT_INVENTORY_BY_GROUP_DAMAGED,
                subcomponents:[
                    {component: "Value", id: COMPONENT_INVENTORY_BY_GROUP_DAMAGED_VALUE},
                    {component: "% to Sales", id: COMPONENT_INVENTORY_BY_GROUP_DAMAGED_PERC_TO_SALES},
                ],
            },
        ]
    },
    {
        component: "Inventory Store View",
        id: COMPONENT_INVENTORY_STORE_VIEW,
        subcomponents: [
            {component: "Store", id: COMPONENT_INVENTORY_STORE_NAME},
            {
                component: "Total Inventory", 
                id: COMPONENT_INVENTORY_STORE_VIEW_TOTAL_INVENTORY,
                subcomponents:[
                    {component: "Days", id: COMPONENT_INVENTORY_STORE_VIEW_TOTAL_INVENTORY_DAYS},
                    {component: "Value", id: COMPONENT_INVENTORY_STORE_VIEW_TOTAL_INVENTORY_VALUE},
                ],
            },
            {
                component: "Return to Vendor", 
                id: COMPONENT_INVENTORY_STORE_VIEW_RETURN_TO_VENDOR,
                subcomponents:[
                    {component: "Value", id: COMPONENT_INVENTORY_STORE_VIEW_RETURN_TO_VENDOR_VALUE},
                    {component: "% to Sales", id: COMPONENT_INVENTORY_STORE_VIEW_RETURN_TO_VENDOR_PERC_TO_SALES},
                ],
            },
            {
                component: "Damaged", 
                id: COMPONENT_INVENTORY_STORE_VIEW_DAMAGED,
                subcomponents:[
                    {component: "Value", id: COMPONENT_INVENTORY_STORE_VIEW_DAMAGED_VALUE},
                    {component: "% to Sales", id: COMPONENT_INVENTORY_STORE_VIEW_DAMAGED_PERC_TO_SALES},
                ],
            },
        ]
    },
]

export const dashboardDefinition = {
    name: 'Markets Inventory',
    data_sources: [
        storeLookupDatasourceDefinition,
        storeInventoryDatasourceDefinition,
        storeSalesDatasourceDefinition
    ],
    entry_points: [
        'markets-dashboard/inventory',
    ],
    owners: [
        "jethro.guzman@sminvestments.com"
    ],
    path: 'markets-dashboard/inventory',  // this is the main path of the dashboard
    components: dashboardComponents
}