import React from 'react';
import {Box, Button, Grid, IconButton, Modal, Stack, Tab, Tabs, Typography} from '@mui/material';
import Section from "../../../../../common/components/Dashboard/Section";
import DataSources from "./index";


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: '5px',
    px: 2,
    pb: 2,
    width: '70%',
    minWidth: '70%',
    maxHeight: '90vh',
    overflow:'auto'
};

export default function DataSourcesAsModal({dataSources, isOpen, toggle}) {
    return <Modal open={isOpen} onClose={toggle}>
        <Grid sx={style}>
            <DataSources dataSources={dataSources} />
        </Grid>
    </Modal>
}