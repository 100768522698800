import { useState } from 'react'
import { Grid, Typography, Collapse, TextField } from '@mui/material'
import CollapsibleComponent from '../../../../../common/components/CollapsibleComponent'


export default function RawEditor({ data }) {
    const [isExpanded, setIsExpanded] = useState('true')

    return <>
       <Grid xs={12} display={'flex'} alignItems={'center'}>
                    <Typography sx={{fontSize:'1rem', fontWeight: 'bold'}}> Query Viewer</Typography>
                    <CollapsibleComponent isExpand={isExpanded} setExpand={setIsExpanded}/>
        </Grid>
        <Collapse in={isExpanded}>
            <Grid container xs={12} sx={{ width: '100%' }}>
                <Grid item xs={12}>
                    <TextField
                        id="outlined-multiline-static"
                        multiline
                        placeholder={'Filter queries...'}
                        fullWidth
                        value={data}
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                </Grid>
            </Grid>
        </Collapse>
    </>
}