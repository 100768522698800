import React from "react";
import {Box, Grid} from "@mui/material";
import {useModuleList} from "../../../../common/API/hooks";
import EntryPointModuleSwitch from "./EntryPointModuleSwitch";
import {useGroupEntry, useModuleAccessChecker, useUserEntry} from "../hooks";
import LoadingComponent from "../../../../common/components/LoadingComponent";

export default function EntryPointManagement({dashboardDefinition, userId, groupId}) {
    const {data: moduleList, isLoading: moduleLoading} = useModuleList();
    const {entry: userEntry, isLoading: isLoadingUserList} = useUserEntry(userId);
    const {entry: groupEntry, isLoading: groupLoading} = useGroupEntry(groupId);

    const entryPoints = dashboardDefinition?.entry_points || [];

    const matchingModules = moduleList?.filter(module => {
        return entryPoints.includes(module.name)
    });


    const {hasAccessToModule, getUsersGroupsAllowedForModule, isLoading} = useModuleAccessChecker(userId, groupId)

    if (isLoading || isLoadingUserList || moduleLoading || isLoadingUserList) {
        return <LoadingComponent/>
    }

    if (!matchingModules || matchingModules.length === 0) {
        return <Box component="div" sx={{p: 2}}>
            There are no available paths/entry points to access this Dashboard. This Dashboard may not yet be published.
        </Box>
    }

    return (
        <>

            <Box component="div" sx={{p: 2}}>
                The following are the paths/entry points to access this Dashboard.{" "}
                {
                    !!userId && <>
                        If this user has been provided access to the dashboard via a Group, you may not be able to manage their direct access.
                    </>
                }
            </Box>
            <Grid xs={12} marginY={2} display="flex" alignItems={'center'}>

                <Grid container>
                    {
                        matchingModules?.map(module => {
                            const matchingGroups = getUsersGroupsAllowedForModule(module)

                            return <EntryPointModuleSwitch
                                key={module.id}
                                module={module}
                                hasAccess={hasAccessToModule(module)}
                                hasAccessViaGroup={matchingGroups && matchingGroups.length > 0 ? matchingGroups.join(", ") : null}
                                userName={userEntry?.username}
                                groupName={groupEntry?.name}
                            />
                        })
                    }
                </Grid>
            </Grid>

        </>
    )
}