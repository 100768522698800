import {useQuery} from "react-query";
import ApiClient from "../../../../../common/API";
import {useContext} from "react";
import {BlacklistedComponentsContext} from "../../contexts";
import {useDashboardComponents, useDashboardPath} from "../DashboardMetaProvider";


export function useDashboardsComponentBlacklistQuery() {
    return useQuery(
        ["user", "component", "blacklist"],
        () => {
            return ApiClient()
                .get('dashboard/common-dashboard/with_blacklist/')
                .then((res) => res.data)
        },
        {
            refetchOnWindowFocus: false,
            staleTime: 600000,
            cacheTime: 600000
        }
    );
}


export function useIsComponentUsable(componentId) {
    const dashboardPath = useDashboardPath()
    if (!dashboardPath) {
        console.warn("Make sure DashboardMetaProvider is a parent or ancestor.")
    }

    const blacklistedComponents = useBlacklistedDashboardComponents()
    if (!componentId) {
        return true
    }
    return !blacklistedComponents.includes(componentId);

}

const getFlatComponentIds = (components) => {
    return components.reduce((acc, component) => {
        if (component.subcomponents) {
            acc = [
                ...acc,
                ...getFlatComponentIds(component.subcomponents)
            ]
        }
        return acc.concat(component.id)
    }, [])
}

export function useBlacklistedDashboardComponents() {
    const dashboardPath = useDashboardPath()
    const components = useDashboardComponents()
    if (!dashboardPath) {
        console.warn("Make sure DashboardMetaProvider is a parent or ancestor.")
    }

    const {
        blacklistedComponentsByDashboardPath,
        isLoading
    } = useContext(BlacklistedComponentsContext)

    if (!dashboardPath) {
        return []  // no blacklists
    }

    if (isLoading) {
        return getFlatComponentIds(components)  // everything is blacklisted
    }

    return blacklistedComponentsByDashboardPath[dashboardPath] || []
}