import React, { useEffect, useState } from "react";
import { Box, Divider, Typography } from "@mui/material";
import InformationToolTip from "../../../../common/components/InformationToolTip";


export default function ZeroSalesToolTip() {
    const [tooltipOpen, setTooltipOpen] = useState(false);

    useEffect(() => {
        const clearToolTipOnScroll = () => {
            // Close the tooltip when scrolling
            setTooltipOpen(false);
        };

        window.addEventListener('scroll', clearToolTipOnScroll);
        return () => {
            window.removeEventListener('scroll', clearToolTipOnScroll);
        };
    }, []);

    const informationContent = <Box paddingTop={.25}>
        <ul>
            <li>The dashboard displays the Top 888 and Top 4K articles with zero sales for the selected number of consecutive days.</li>
            <li>Sales data is updated daily at 11PM to ensure the most complete sales information of the previous day.</li>
        </ul>
    </Box>

    const handleTooltipChange = (value) => {
        setTooltipOpen(value);
    };

    return <InformationToolTip onChange={handleTooltipChange} open={tooltipOpen} information={informationContent} />
}