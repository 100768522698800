import axios from 'axios';
import { clearAuthTokens, getAuthTokens } from '../utils';
import { isLoggedIn } from '../utils';

const BASE_URL = process.env.NODE_ENV === 'test' ? '' : process.env.REACT_APP_API_DOMAIN;

// FIXME: move this to somewhere more appropriate
export const LOGIN_URL = `token/`;
export const LOGIN_LONG_URL = `token-long/`;  // used for remember me
export const REFRESH_TOKEN_URL = `token/refresh/`;
export const REFRESH_TOKEN_LONG_URL = 'token-long/refresh/';  // used for remember me

let refreshPromise = null;
const clearPromise = () => refreshPromise = null;

async function refreshToken() {
    const refreshToken = getAuthTokens()?.refreshToken
    const refreshUrl = localStorage.getItem("remember_me") === "true" ? REFRESH_TOKEN_LONG_URL : REFRESH_TOKEN_URL
    const response = await axios.post(`${BASE_URL}${refreshUrl}`, {
        refresh: refreshToken
    })

    return response.data
}

const ApiClient=()=> {
    const axiosInstance = axios.create({ 
        baseURL : BASE_URL,
        timeout: 10000
    });

    //setting the token for all the request
    axiosInstance.interceptors.request.use(config => {
        const token = getAuthTokens()?.accessToken
        if(token) {
            config.headers.Authorization = `Bearer ${token}`
            config.headers["X-Forwarded-Authorization"] = `Bearer ${token}`  // comment out for production
        }
        return config;
    }, error => {
        console.error("Invalid AccessToken")
        Promise.reject(error)
    });

    axiosInstance.interceptors.response.use(response => response, 
        async(error) =>{
            const originalRequest = error.config;
            // If the error status is 401 and there is no originalRequest._retry flag,
            // it means the token has expired and we need to refresh it
            if((error.response?.status === 401 || error.response?.status === 403) && !originalRequest._retry && isLoggedIn()) {
                originalRequest._retry = true;

                try {
                    //store the promise returned by refreshToken until its fullfilled
                    //let all incoming 401,403 errors await the same promise, then retry and kill that promise when it is fulfilled(See: clearPromise declaration on top.)
                    //so that later 401,403 errors have a chance to refresh the token.
                    if(!refreshPromise) {
                        refreshPromise = refreshToken().finally(clearPromise);
                    }
                    const { access, refresh } = await refreshPromise

                    const tokens = {
                        'accessToken': access,
                        'refreshToken': refresh
                    }

                    localStorage.setItem('auth-tokens-production', JSON.stringify(tokens));

                    originalRequest.headers.Authorization = `Bearer ${access}`;

                    return axios(originalRequest);
                } catch(error){
                    clearAuthTokens()
                    window.location.reload()
                    
                    return Promise.reject(error)
                }
            }
            return Promise.reject(error)
        })
    return axiosInstance
}

export default ApiClient
