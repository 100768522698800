import { useMemo } from 'react';
import TableComponentV2 from '../../../common/components/TableComponentV2';
import { Typography, Box, Grid, Link } from '@mui/material';
import LoadingComponent from '../../../common/components/LoadingComponent';
import Fuse from 'fuse.js';
import { useGroupPermissionList } from './hooks';
import ActionButton from './Components/ActionButton';
import AccessibleDashboardCount from "./AccessibleDashboardCount";


export default function GroupPermissionManagementTable({ search, entity, ownerView = false, opsView = true, dashboardName }) {
    const { data: groupList, isLoading: groupLoading } = useGroupPermissionList();

    const filteredGroups = useMemo(() => {
        if (groupLoading || !groupList) {
            return []
        }
        const sortedGroups = groupList.sort((a, b) => {
            return a.name.localeCompare(b.name);
        })
        if (!search) {
            return sortedGroups;
        }

        const options = {
            keys: ['name'],
            threshold: 0.3,
        };

        const fuse = new Fuse(sortedGroups, options);
        const searchItemLowerCase = search.toLowerCase();

        return fuse.search(searchItemLowerCase).map(result => result.item);
    }, [groupLoading, groupList, search])

    const group_permission_management_columns = useMemo(
        () => {
            const columns = [
                {
                    id: 'group_name',
                    header: 'Group',
                    accessorKey: 'name',
                    cell: (row) => {
                        const value = row?.getValue();
                        return <Box mx={3} >
                            <Grid container xs={12} my={1}>
                                <Link href={opsView && `#/ops-permission-management/group/${row?.row?.original?.name} /${row?.row?.original?.id}`}
                                    underline={opsView ? 'hover' : 'none'} sx={{ cursor: 'pointer', color: 'black' }}> <Typography >{value}</Typography> </Link>
                            </Grid>
                        </Box >
                    },
                    customStyle: { width: opsView ? '5rem' : '20rem' },
                },
            ];
            if (opsView === true) {
                columns.push(
                    {
                        id: 'group_dashboard_count',
                        header: 'Accessible Dashboards',
                        cell: (row) => {
                            return (
                                <Grid container alignItems={'center'} justifyContent={'center'}>
                                    <AccessibleDashboardCount userId={null} groupId={row.row?.original.id} />
                                </Grid>
                            )
                        },
                        customStyle: { width: '9rem' },
                    })
            }

            if (ownerView === true) {
                columns.push(
                    {
                        id: 'visibility',
                        header: 'Visibility',
                        cell: (row) => {
                            return <Grid container alignItems={'center'} justifyContent={'center'}>
                            </Grid>
                        },
                    },
                    {
                        id: 'filtered',
                        header: 'Filtered',
                        cell: (row) => {
                            return <Grid container alignItems={'center'} justifyContent={'center'}>
                            </Grid>
                        },
                    },
                    {
                        id: 'via_group',
                        header: 'Via Group',
                        cell: (row) => {
                            return <Grid container alignItems={'center'} justifyContent={'center'}>
                            </Grid>
                        },
                    },
                    {
                        id: 'actions',
                        header: 'Actions',
                        cell: (row) => {
                            return <Grid container alignItems={'center'} justifyContent={'center'}>
                                <ActionButton row={row} dashboardName={dashboardName} />
                            </Grid>
                        },
                    },
                )
            }

            return columns;
        },
        [ownerView, groupList]
    );

    if (groupLoading) {
        return (<LoadingComponent />)
    }

    return (
        <TableComponentV2
            columns={group_permission_management_columns}
            data={filteredGroups}
            tableId={'GrouprPermissionManagementTable'}
            isCompact={true}
            hasPagination={true}
            isInventoryTable={true}
            customTableContainerStyle={{
                borderRadius: "0",
                boxShadow: 'none',
            }}
        />
    )
}