import Box from "@mui/material/Box";
import NumericValueDisplay from "../../../common/presentation/numericValueDisplay";
import {Typography} from "@mui/material";

export function CellDisplay({mainValue, subValuePerc, isBlankWhenZero = false}) {
    const isMainValueZero = mainValue === "0" || mainValue === 0
    const mainValueDisplay = (isMainValueZero && isBlankWhenZero) ? "-" : <NumericValueDisplay value={mainValue} isAbbreviated={true} />
    return (
        <Box sx={{ display: 'flex', width: { sm: '40%' }, marginX: 'auto', my: 0.5 }}>
            <span style={{ whiteSpace: 'nowrap' }}>
                { mainValueDisplay }
            </span>
            {
                (typeof subValuePerc === 'undefined' || !mainValue) ? null :
                    <Typography sx={{ color: 'text.secondary', fontSize: '0.75rem', marginLeft: '0.5em', whiteSpace: 'nowrap' }}>
                        ({subValuePerc}%)
                    </Typography>
            }
        </Box>
    );
}