import React from 'react';
import {useTableDetail} from "../../../../../common/API/hooks";
import StatusDisplayWithDetail from "./StatusDisplayWithDetail";


export default function TableNameStatusDisplay({tableName}) {
    const {data: tableDetail, isLoading} = useTableDetail(tableName, {})

    return <>
        <StatusDisplayWithDetail isLoading={isLoading} isSuccess={!!tableDetail}>
            {tableName}
        </StatusDisplayWithDetail>
    </>
}