import React, {useContext} from 'react';
import {WindowSizeContext} from "../../../../../common/context/WindowSizeContext";
import {useSelectorState} from "../../providers/selectorProvider";
import ConditionalSelectComponent from "../../../../../common/components/ConditionalSelectComponent";


export default function ContextBasedDropdownSelector(
    {
        label,
        contextKey, defaultValue, options, isLoading,
        hideIfEmpty=false,
        autoSelectFirstOption = true,
        ...selectProps
    }
) {
    const windowSize = useContext(WindowSizeContext)
    const [value, setValue] = useSelectorState(contextKey, defaultValue);

    const selectedOption = options.find(option => option.value === value);

    React.useEffect(() => {
        // if the selected option is undefined, and there are options available, set the default value
        if (selectedOption === undefined) {
            if (defaultValue && value !== defaultValue && (
                options?.length > 0 && !!options.find(option => option.value === defaultValue)
            )) {
                setValue(defaultValue);
            } else if (autoSelectFirstOption && options?.length > 0) {  // if there is no default value, and autoSelectFirstOption is true, set the first option
                const firstOption = options[0];
                if (firstOption.value !== value) {
                    setValue(firstOption.value);
                }
            }
        }
    }, [selectedOption, autoSelectFirstOption, defaultValue, value, options])

    if (!isLoading && options.length === 0 && hideIfEmpty) {
        return null
    }

    const appliedProps = {
        disableClearable: true,
        ...selectProps
    }


    return <ConditionalSelectComponent
        id={`${contextKey}_selector`}
        labelId={`${contextKey}_selector-label`}
        options={options}
        label={label}
        value={selectedOption || null}
        onSelectChange={(event, newValue) => {
            const value = windowSize.isOnMobile ? event?.target?.value : newValue?.value;
            setValue(value);
        }}
        clearSelected={() => {
            if (defaultValue) {
                setValue(defaultValue);
            } else if (autoSelectFirstOption && options.length > 0) {
                setValue(options[0].value);
            }
        }}
        isLoading={isLoading}
        {...appliedProps}
    />
}