import {useContext} from 'react';
import {ModulePermissionsContext} from '../../../common/context/ModulePermissionsContext';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import CardContent from './CardContents';
import {useMediaQuery} from '@mui/material';
import {createTheme} from '@mui/material/styles';
import {MANAGEMENT_MODULES} from "../constants";

function hasAccess(strModule, isParent, data) {
    if (isParent) {
        const parentLinks = data.map(module => module?.split('/')?.[0])
        return parentLinks?.length > 0 && parentLinks.includes(strModule)
    } else {
        return data?.length > 0 && data.includes(strModule)
    }
}

export default function Dashboard() {
    const { moduleList } = useContext(ModulePermissionsContext)

    const theme = createTheme();
    const screenSize = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Grid container spacing={2} sx={{ mt: 5 }}>
        <Grid item xs={12} md={4}>
            <Typography variant='h5' ml={1} mb={1}>
                Admin Management
            </Typography>

            <Card style={{ border: "none", boxShadow: "none" }}>
                <Grid container spacing={2} p={2} direction={'row'}>
                    {Object.values(MANAGEMENT_MODULES).map((item, index) => (
                        <CardContent
                            key={index}
                            name={item.name}
                            icon={item.icon}
                            path={item.path}
                            access={hasAccess(item.path.split('/')[1], false, moduleList)}
                        />
                    ))}
                </Grid>
            </Card>
        </Grid>
    </Grid>
    )
}