import { IconButton, Tooltip } from "@mui/material"
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import LoadingComponent from "../../../../common/components/LoadingComponent"
import DashboardPermissionManagementModal from "../DashboardPermissionManagementModal";
import { useState } from "react";


export default function ActionButton({ isLoading, dashboardName, dashboardId }) {
    const [isModalOpen, setModalOpen] = useState(false);

    if (isLoading) {
        return <LoadingComponent />
    }

    return <>
        <Tooltip title="Edit" placement="right" arrow>
            <IconButton aria-label="Edit" size="small" onClick={() => setModalOpen(true)}>
                <EditRoundedIcon fontSize="small" />
            </IconButton>
        </Tooltip>
        <DashboardPermissionManagementModal
            key={dashboardId}
            isOpen={isModalOpen} setIsOpen={setModalOpen}
            dashboardId={dashboardId} dashboardName={dashboardName}
        />
    </>
}