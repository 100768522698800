import React from 'react';
import Button from "@mui/material/Button";
import DataSourcesAsModal from "./DataSources/DataSourcesAsModal";


export default function DataSourcesDisplay({dataSources}) {
    const [isModalOpen, setModalOpen] = React.useState(false);

    return (<>
        <Button onClick={() => setModalOpen(true)}>
            {dataSources?.length || 0}
        </Button>
            <DataSourcesAsModal
                dataSources={dataSources}
                toggle={() => setModalOpen(false)}
                isOpen={isModalOpen}/>
        </>
    )
}