
export const dashboardName = 'BU View > Customer > Demographics'
export const DASHBOARD_PATH = 'business-unit-view/customer/demographics'

// components
export const COMPONENT_CARD_MEMBERS_SCORECARD = 'card_members_scorecard'
export const COMPONENT_SCORE_CARD = 'score_card'
export const COMPONENT_CUSTOMER_DEMOGRAPHICS_SECTION = 'customer_demographics'
export const COMPONENT_CUSTOMER_DEMOGRAPHICS_GENDER = 'customer_demographics_gender'
export const COMPONENT_CUSTOMER_DEMOGRAPHICS_AGE_OR_GENERATION = 'customer_demographics_agegeneration'
export const COMPONENT_CUSTOMER_DEMOGRAPHICS_INTERACTION = 'customer_demographics_interaction'

export const COMPONENT_BRAND_PERFORMANCE_SECTION = 'brand_performance'
export const COMPONENT_BRAND_PERFORMANCE_TOTAL_COLUMNS = 'brand_performance_total_columns'
export const COMPONENT_BRAND_PERFORMANCE_CUSTOMER_COUNT_COLUMNS = 'brand_performance_customer_count_columns'

export const COMPONENT_METRIC_SALES = 'metric_sales'
export const COMPONENT_METRIC_SALES_VALUE = 'metric_sales_value'
export const COMPONENT_METRIC_SALES_CONTRIBUTION = 'metric_sales_contribution'

export const COMPONENT_METRIC_TRANSACTIONS = 'metric_transactions'
export const COMPONENT_METRIC_TRANSACTIONS_VALUE = 'metric_transactions_value'
export const COMPONENT_METRIC_TRANSACTIONS_CONTRIBUTION = 'metric_transactions_contribution'

export const COMPONENT_METRIC_UNITS_SOLD_PER_TRANSACTION = 'metric_units_sold_per_transaction'
export const COMPONENT_METRIC_UNITS_SOLD_PER_TRANSACTION_VALUE = 'metric_units_sold_per_transaction_value'
export const COMPONENT_METRIC_UNITS_SOLD_PER_TRANSACTION_CONTRIBUTION = 'metric_units_sold_per_transaction_contribution'

export const COMPONENT_METRIC_BASKET_SIZE = 'metric_basket_size'
export const COMPONENT_METRIC_BASKET_SIZE_VALUE = 'metric_basket_size_value'
export const COMPONENT_METRIC_BASKET_SIZE_CONTRIBUTION = 'metric_basket_size_contribution'

export const COMPONENT_METRIC_AVG_FREQUENCY_OF_VISIT = 'metric_avg_frequency_of_visit'
export const COMPONENT_METRIC_AVG_FREQUENCY_OF_VISIT_VALUE = 'metric_avg_frequency_of_visit_value'
export const COMPONENT_METRIC_AVG_FREQUENCY_OF_VISIT_CONTRIBUTION = 'metric_avg_frequency_of_visit_contribution'


export const dashboardDefinition = {
    name: dashboardName,
    data_sources: [
        {
            "table": {
                "name": "l3_rsa_customer_attributes",
            },
            "columns": [
                {"name": "age_bin"},
                {"name": "pos_sku_tot_qty"},
                {"name": "load_date"},
                  {
                    "name": "header_tran_date",
                    "required_aggregates": [
                        {
                            name: "header_tran_date_avg",
                            aggregate_type: "avg",
                        }
                    ]
                },
                {"name": "gender_bin"},
                {"name": "gcr_persistent_id"},
                {"name": "final_brand"},
                {"name": "pos_sku_gross_sales"},
                {"name": "smac_number"},
                {"name": "pm_subdept_desc_standardized"},
                {"name": "sbu_bu_group"},
                {"name": "pm_department_desc_standardized"},
                {"name": "smac_card_type"},
                {"name": "pm_business_unit_desc_standardized"},
                {
                    "name": "header_tran_key",
                    "required_aggregates": [
                        {
                            name: "header_tran_key_sum",
                            aggregate_type: "sum",
                        },
                    ]
                },
                {"name": "pm_product_description_standardized"},
                {
                    name: 'units_sold_per_txn',
                    column_type: 'float64',
                    protected: true
                },
                {
                    name: 'basket_size',
                    column_type: 'float64',
                    protected: true
                },
                {
                    name: 'basket_size_bin',
                    column_type: 'string',
                    protected: true,
                },
                {
                    name: 'frequency_of_visit',
                    column_type: 'float64',
                    protected: true
                }
            ],
            "data_filter_columns": [
                {name: "age_bin", label: "Age Bin", type: "string"},
                // {name: "load_date", label: "Load Date", type: "date"},
                // {name: "header_tran_date", label: "Transaction Date", type: "date"},
                {name: "gender_bin", label: "Gender Bin", type: "string"},
                {name: "smac_card_type", label: "Card Type", type: "string"},
                {name: "sbu_bu_group", label: "BU Group", type: "string"},
                {name: "pm_business_unit_desc_standardized", label: "Business Unit", type: "string"},
                {name: "pm_department_desc_standardized", label: "Department", type: "string"},
                {name: "pm_subdept_desc_standardized", label: "Sub Department", type: "string"},
                {name: "final_brand", label: "Brand", type: "string"},
                {name: "pm_product_description_standardized", label: "Product Description", type: "string"},
            ],
            "views": [
                {
                    "name": "cpb_customer_ratio_brand_gender",
                    "sql": 'SELECT \n' +
                        '  final_brand, \n' +
                        '  gender_bin, \n' +
                        '  per_brand_gender_customers, \n' +
                        '  SAFE_DIVIDE(per_brand_gender_customers, per_gender_customers) * 100 customer_ratio  \n' +
                        '\n' +
                        'FROM( \n' +
                        '  SELECT DISTINCT \n' +
                        '       CASE \n' +
                        '        WHEN final_brand = " " THEN \'No Brand\' \n' +
                        '        ELSE final_brand \n' +
                        '      END AS final_brand, \n' +
                        '      gender_bin, \n' +
                        '      COUNT(DISTINCT gcr_persistent_id) OVER (PARTITION BY \n' +
                        '                                              final_brand,\n' +
                        '                                              gender_bin) per_brand_gender_customers, \n' +
                        '      COUNT(DISTINCT gcr_persistent_id) OVER (PARTITION BY \n' +
                        '                                              gender_bin) per_gender_customers, \n' +
                        '  FROM \n' +
                        '    {path} \n' +
                        '    {filters} \n' +
                        ') \n' +
                        'ORDER BY  gender_bin ASC'
                },
                {
                    "name": "cpb_customer_ratio_brand_gender_age",
                    "sql": 'SELECT \n' +
                        '  final_brand, \n' +
                        '  gender_bin, \n' +
                        '  age_bin, \n' +
                        '  per_brand_gender_age_customers, \n' +
                        '  SAFE_DIVIDE(per_brand_gender_age_customers, per_gender_customers) * 100 AS customer_ratio  \n' +
                        'FROM( \n' +
                        '  SELECT DISTINCT \n' +
                        '    CASE  \n' +
                        '      WHEN final_brand = " " THEN \'No Brand\'  \n' +
                        '      ELSE final_brand  \n' +
                        '    END AS final_brand, \n' +
                        '    gender_bin, \n' +
                        '    age_bin, \n' +
                        '    COUNT(DISTINCT gcr_persistent_id) OVER (PARTITION BY \n' +
                        '                                            final_brand, \n' +
                        '                                            gender_bin, \n' +
                        '                                            age_bin) AS per_brand_gender_age_customers, \n' +
                        '    COUNT(DISTINCT gcr_persistent_id) OVER (PARTITION BY \n' +
                        '                                            final_brand, \n' +
                        '                                            gender_bin) AS per_gender_customers, \n' +
                        '  FROM \n' +
                        '      {path} \n' +
                        '      {filters} \n' +
                        ')'
                },
                {
                    name: "cpb_sales_txn_upt",
                    sql: 'SELECT\n' +
                        '      final_brand, \n' +
                        '      age_bin,\n' +
                        '      gender_bin,\n' +
                        '      SUM (pos_sku_gross_sales) AS pos_sku_gross_sales,\n' +
                        '      SUM (pos_sku_tot_qty) AS pos_sku_tot_qty, \n' +
                        '      SUM (pos_sku_tot_qty) / count(distinct header_tran_key) AS units_sold_per_txn,\n' +
                        '      COUNT(distinct header_tran_key) AS header_tran_key\n' +
                        '  FROM\n' +
                        '    {path}\n' +
                        '    {filters}\n' +
                        '  GROUP BY ALL'
                },
                {
                    name: 'cpb_basket_size_visit_frequency_brand_gender',
                    sql: 'SELECT  \n' +
                        '    final_brand,  \n' +
                        '    gender_bin,  \n' +
                        '    sum(basket_size) / COUNT(DISTINCT gcr_persistent_id) basket_size,  \n' +
                        '    SUM(frequency_of_visit)/COUNT(DISTINCT gcr_persistent_id) frequency_of_visit,  \n' +
                        '  FROM (  \n' +
                        '    SELECT  \n' +
                        '      gcr_persistent_id,  \n' +
                        '      final_brand,\n' +
                        '      gender_bin,  \n' +
                        '      SUM(pos_sku_gross_sales) / COUNT(DISTINCT header_tran_key) as basket_size,  \n' +
                        '      COUNT(DISTINCT header_tran_date) as frequency_of_visit\n' +
                        '    FROM \n' +
                        '    {path} \n' +
                        '    {filters} \n' +
                        '   GROUP BY ALL\n' +
                        ')  \n' +
                        'GROUP BY ALL'
                },
                {
                    name: 'cpb_basket_size_visit_frequency_brand_gender_age',
                    sql: 'SELECT\n' +
                        '      final_brand,\n' +
                        '      gender_bin,\n' +
                        '      age_bin,\n' +
                        '      sum(basket_size) / COUNT(DISTINCT gcr_persistent_id) as basket_size,\n' +
                        '      SUM(frequency)/COUNT(DISTINCT gcr_persistent_id) as frequency_of_visit,\n' +
                        '    FROM (\n' +
                        '      SELECT\n' +
                        '        gcr_persistent_id,\n' +
                        '        final_brand,\n' +
                        '        gender_bin,\n' +
                        '        age_bin,\n' +
                        '        SUM(pos_sku_gross_sales) / COUNT(DISTINCT header_tran_key) as basket_size,\n' +
                        '        COUNT(DISTINCT header_tran_date) as frequency\n' +
                        '      FROM {path}\n' +
                        '      {filters}\n' +
                        '      GROUP BY ALL\n' +
                        ')\n' +
                        'GROUP BY ALL'
                }

            ]
        }
    ],
    entry_points: [
        'mbu/bu-view/Shoes',
        'mbu/bu-view/Ladies\'%20Fashion',
        'mbu/bu-view/Men\'s%20Fashion',
        'mbu/bu-view/Children\'s%20Fashion',
        'mbu/bu-view/Accessories%20Management',
        'mbu/bu-view/Smart%20Buy',
    ],
    owners: [
        "jethro.guzman@sminvestments.com"
    ],
    path: DASHBOARD_PATH,  // this is the main path of the dashboard
    components: [
        {
            component: "Card Members Scorecard",
            id: COMPONENT_CARD_MEMBERS_SCORECARD,
        },
        {
            component: "Score Card",
            id: COMPONENT_SCORE_CARD,
        },
        {
            component: "Customer Demographics",
            id: COMPONENT_CUSTOMER_DEMOGRAPHICS_SECTION,
            // subcomponents: [
            //     {component: "Gender Chart", id: COMPONENT_CUSTOMER_DEMOGRAPHICS_GENDER},
            //     {component: "Age/Generation Chart", id: COMPONENT_CUSTOMER_DEMOGRAPHICS_AGE_OR_GENERATION},
            //     {
            //         component: "Gender - Age/Generation Interaction Chart",
            //         id: COMPONENT_CUSTOMER_DEMOGRAPHICS_INTERACTION
            //     },
            // ]
        },
        {
            component: "Brand Performance",
            id: COMPONENT_BRAND_PERFORMANCE_SECTION,
            subcomponents: [
                {component: "Total Columns", id: COMPONENT_BRAND_PERFORMANCE_TOTAL_COLUMNS},
                {component: "Customer Count Columns", id: COMPONENT_BRAND_PERFORMANCE_CUSTOMER_COUNT_COLUMNS},
            ]
        },
        {
            component: "Metrics",
            readOnly: true,
            subcomponents: [
                {
                    component: "Sales",
                    id: COMPONENT_METRIC_SALES,
                    readOnly: true,  // default metrics is sales
                    subcomponents: [
                        {component: "Value", id: COMPONENT_METRIC_SALES_VALUE},
                        {component: "Contribution", id: COMPONENT_METRIC_SALES_CONTRIBUTION},
                    ]
                },
                {
                    component: "Transactions",
                    id: COMPONENT_METRIC_TRANSACTIONS,
                    subcomponents: [
                        {component: "Value", id: COMPONENT_METRIC_TRANSACTIONS_VALUE},
                        {component: "Contribution", id: COMPONENT_METRIC_TRANSACTIONS_CONTRIBUTION},
                    ]
                },
                {
                    component: "Units Sold Per Transaction",
                    id: COMPONENT_METRIC_UNITS_SOLD_PER_TRANSACTION,
                    subcomponents: [
                        {component: "Value", id: COMPONENT_METRIC_UNITS_SOLD_PER_TRANSACTION_VALUE},
                        {component: "Contribution", id: COMPONENT_METRIC_UNITS_SOLD_PER_TRANSACTION_CONTRIBUTION},
                    ]
                },
                {
                    component: "Basket Size",
                    id: COMPONENT_METRIC_BASKET_SIZE,
                    subcomponents: [
                        {component: "Value", id: COMPONENT_METRIC_BASKET_SIZE_VALUE},
                        {component: "Contribution", id: COMPONENT_METRIC_BASKET_SIZE_CONTRIBUTION},
                    ]
                },
                {
                    component: "Avg Frequency of Visit",
                    id: COMPONENT_METRIC_AVG_FREQUENCY_OF_VISIT,
                    subcomponents: [
                        {component: "Value", id: COMPONENT_METRIC_AVG_FREQUENCY_OF_VISIT_VALUE},
                        {component: "Contribution", id: COMPONENT_METRIC_AVG_FREQUENCY_OF_VISIT_CONTRIBUTION},
                    ]
                },
            ]
        }]


}