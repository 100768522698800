import BlacklistableComponentWrapper from "../../common/providers/blacklistedComponentsProvider/BlacklistableComponentWrapper";
import MemberPenetration from "./MemberPenetration";
import SMACCardStatus from "./SMACCardStatus";
import { COMPONENT_SMAC_CARD_STATUS, COMPONENT_MEMBER_PENETRATION } from "../dashboardDefinition";

export default function AcquisitionContent() {

    return <>
        <BlacklistableComponentWrapper componentId={COMPONENT_SMAC_CARD_STATUS}>
            <SMACCardStatus/>
        </BlacklistableComponentWrapper>
        <BlacklistableComponentWrapper componentId={COMPONENT_MEMBER_PENETRATION}>
            <MemberPenetration/>
        </BlacklistableComponentWrapper>
    </>
}