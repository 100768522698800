import React, { useMemo } from "react";
import TableComponentV2 from "../../../../../common/components/TableComponentV2";
import LoadingComponent from "../../../../../common/components/LoadingComponent";
import { Box } from "@mui/system";
import NumericValueDisplay from "../../../common/presentation/numericValueDisplay";
import { IndicatorCellBox } from "../../../Customers/Transactions/BUGroupShareOfWallet";
import BarIndicator from "../../../common/presentation/barIndicator";
import { convertToValidNumber } from "../../../../../common/utils";
import NoAvailableDataDisplay from "../../NoAvailableDataDisplay";
import {useSelectorValue} from "../../../common/providers/selectorProvider";

export default function CustomerActivityByHomeBranchTable({ data, isLoading}) {
    const branch = useSelectorValue('branch');

    const filteredData = useMemo(() => {
        if (!branch || branch.toString().toLowerCase() === 'all branch' || !data || data.length === 0) return data;
        return data?.filter(item => {
            return item.pk === branch
        });
    }, [data, branch]);

    const CUSTOMER_ACTIVITY_BY_HOMEBRANCH = useMemo(() => {
        return [
            {
                id: 'branch',
                header: 'Branch',
                accessorKey: 'pk',
                cell: ({ row, getValue }) => {
                    const value = getValue();
                    return <Box sx={{ marginX: 'auto', marginLeft: '1em', width: "13em", textWrap: "nowrap" }}>
                        {value}
                    </Box>
                },
            },
            {
                id: 'core_member',
                header: 'Core Member',
                accessorKey: 'core_members',
                cell: ({ row, getValue }) => {
                    return <Box sx={{ marginX: 'auto', width: "3em", textWrap: "nowrap" }}>
                        <NumericValueDisplay value={getValue()} isAbbreviated={true} />
                    </Box>
                },
                customStyle: {
                    height: '2rem',
                    textAlign: 'center',
                    verticalAlign: 'middle',
                    borderLeft: '3px solid'
                },
            },
            {
                id: 'core_member_ly',
                header: 'vs Last Year',
                accessorFn: (row) =>  (row?.core_members / row?.core_members_ly) * 100,
                cell: (row) => {
                    const value = row?.getValue()
                    return <IndicatorCellBox>
                        {(value !== null && value !== Infinity && !isNaN(value)) ?
                            <BarIndicator colorFunctionFor={'SBU'} value={convertToValidNumber(row?.getValue())?.toFixed(1)} isValueColorCoded={true} isOnTable={true} label="vs last year" />
                            : '-'
                        }
                    </IndicatorCellBox>
                }
            },
            {
                id: 'sales',
                header: 'Sales',
                accessorKey: 'sales',
                cell: (row) => {
                    return <Box sx={{ marginX: 'auto', width: "5em", textWrap: "nowrap" }}>
                        {row?.getValue() !== 0 ?
                            <NumericValueDisplay value={row.getValue()} isAbbreviated={true} prefix={'₱'} />
                            : '-'
                        }
                    </Box>
                },
                customStyle: {
                    height: '2rem',
                    textAlign: 'center',
                    verticalAlign: 'middle',
                    borderLeft: '3px solid'
                },
            },
            {
                id: 'sales_ly',
                header: 'vs Last Year',
                accessorFn: (row) =>  (row?.sales / row?.sales_ly) * 100,
                cell: (row) => {
                    const value = row?.getValue()
                    return <IndicatorCellBox>
                        {(value !== null && value !== Infinity && !isNaN(value)) ?
                            <BarIndicator colorFunctionFor={'SBU'} value={convertToValidNumber(row?.getValue())?.toFixed(1)} isValueColorCoded={true} isOnTable={true} label="vs last year" />
                            : '-'
                        }
                    </IndicatorCellBox>
                }
            },
            {
                id: 'transaction',
                header: 'Transaction',
                accessorKey: 'txn',
                cell: ( row ) => {
                    return <Box sx={{ marginX: 'auto', width: "3em", textWrap: "nowrap" }}>
                        {row?.getValue() !== null ?
                            <NumericValueDisplay value={row.getValue()} isAbbreviated={true} />
                            : '-'
                        }
                    </Box>
                },
                customStyle: {
                    height: '2rem',
                    textAlign: 'center',
                    verticalAlign: 'middle',
                    borderLeft: '3px solid'
                },
            },
            {
                id: 'txxn_ly',
                header: 'vs Last Year',
                accessorFn: (row) =>  (row?.txn / row?.txn_ly) * 100,
                cell: (row) => {
                    const value = row?.getValue()
                    return <IndicatorCellBox>
                        {(value !== null && value !== Infinity && !isNaN(value)) ?
                            <BarIndicator colorFunctionFor={'SBU'} value={convertToValidNumber(row?.getValue())?.toFixed(1)} isValueColorCoded={true} isOnTable={true} label="vs last year" />
                            : '-'
                        }
                    </IndicatorCellBox>
                }
            },
            {
                id: 'visit_freq',
                header: 'Freq. of Visit',
                accessorKey: 'visit_freq',
                cell: ({ row, getValue }) => {
                    return <Box sx={{ marginX: 'auto', width: "3em", textWrap: "nowrap" }}>
                        <NumericValueDisplay value={getValue()?.toFixed(0)} isAbbreviated={true}/>
                    </Box>
                },
                customStyle: {
                    height: '2rem',
                    textAlign: 'center',
                    verticalAlign: 'middle',
                    borderLeft: '3px solid'
                },
            },
            {
                id: 'visit_freq_ly',
                header: 'vs Last Year',
                accessorFn: (row) =>  (row?.visit_freq / row?.visit_freq_ly) * 100,
                cell: (row) => {
                    const value = row?.getValue()
                    return <IndicatorCellBox>
                        {(value !== null && value !== Infinity && !isNaN(value)) ?
                            <BarIndicator colorFunctionFor={'SBU'} value={convertToValidNumber(row?.getValue())?.toFixed(1)} isValueColorCoded={true} isOnTable={true} label="vs last year" />
                            : '-'
                        }
                    </IndicatorCellBox>
                }
            },
            {
                id: 'atv',
                header: 'Avg. Trx Value',
                accessorKey: 'atv',
                cell: ({ row, getValue }) => {
                    return <Box sx={{ marginX: 'auto', width: "4em", textWrap: "nowrap" }}>
                        <NumericValueDisplay value={getValue()} isAbbreviated={true} prefix={'₱'} />
                    </Box>
                },
                customStyle: {
                    height: '2rem',
                    textAlign: 'center',
                    verticalAlign: 'middle',
                    borderLeft: '3px solid'
                },
            },
            {
                id: 'atv_ly',
                header: 'vs Last Year',
                accessorFn: (row) =>  (row?.atv / row?.atv_ly) * 100,
                cell: (row) => {
                    const value = row?.getValue()
                    return <IndicatorCellBox>
                        {(value !== null && value !== Infinity && !isNaN(value)) ?
                            <BarIndicator colorFunctionFor={'SBU'} value={convertToValidNumber(row?.getValue())?.toFixed(1)} isValueColorCoded={true} isOnTable={true} label="vs last year" />
                            : '-'
                        }
                    </IndicatorCellBox>
                }
            },
            {
                id: 'avg_spend_visit',
                header: 'Avg. Spend/Visit',
                accessorKey: 'avg_spend_visit',
                cell: ({ row, getValue }) => {
                    return <Box sx={{ marginX: 'auto', width: "4em", textWrap: "nowrap" }}>
                        <NumericValueDisplay value={getValue()} isAbbreviated={true} prefix={'₱'} />
                    </Box>
                },
                customStyle: {
                    height: '2rem',
                    textAlign: 'center',
                    verticalAlign: 'middle',
                    borderLeft: '3px solid'
                },
            },
            {
                id: 'avg_spend_visit_ly',
                header: 'vs Last Year',
                accessorFn: (row) =>  (row?.avg_spend_visit / row?.avg_spend_visit_ly) * 100,
                cell: (row) => {
                    const value = row?.getValue()
                    return <IndicatorCellBox>
                        {(value !== null && value !== Infinity && !isNaN(value)) ?
                            <BarIndicator colorFunctionFor={'SBU'} value={convertToValidNumber(row?.getValue())?.toFixed(1)} isValueColorCoded={true} isOnTable={true} label="vs last year" />
                            : '-'
                        }
                    </IndicatorCellBox>
                }
            },
        ]
    }, []
    );

    if (isLoading) {
        return <LoadingComponent />
    }

    if (!data || data?.length === 0) {
        return <NoAvailableDataDisplay />
    }

    return <TableComponentV2
        columns={CUSTOMER_ACTIVITY_BY_HOMEBRANCH}
        data={filteredData}
        tableId={'CustomerActivityByBranch'}
        isCompact
        hasPagination={true}
        customTableHeaderStyle={{ backgroundColor: "#caddeb" }}
    />
}