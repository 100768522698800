import React, { useContext } from 'react';
import ConditionalSelectComponent from '../../../../common/components/ConditionalSelectComponent';
import { WindowSizeContext } from '../../../../common/context/WindowSizeContext';
import { getSelectedOption } from '../../../../common/utils';
import { useQuery } from "react-query";
import ApiClient from '../../../../common/API';
import { USE_QUERY_DEFAULT_OPTIONS } from '../../../../Constants/settings';
import { STORE_ZERO_SALES_TABLE } from '../hooks';

export default function ProductCategoryFilter({ filters, onFilterChange, overviewTableIsEmpty}) {
    const windowSize = useContext(WindowSizeContext);
    
    const { data: productCategoryData, isLoading: isLoadingProductCategoryData } = useQuery(
        ["article_category"],
        () => ApiClient().get(`data/table/${STORE_ZERO_SALES_TABLE}/query/`, {
            params: {
                columns: 'article_category',
                distinct: true,
                article_category__gte: '0'  // this is a hack to filter out the null value; ideally, we should be able to use article_category__isnull=false
            }
        }).then(res => {
            return res.data.data
        }), {
        ...USE_QUERY_DEFAULT_OPTIONS
    }
    );

    const availableOptions = (productCategoryData || [])
            .map((row) => {
                return {
                    value: row?.article_category,
                    label: row?.article_category,
                }
            })
            .filter((option) => option.value !== "" && option.label !== "")
            .sort((a, b) => a.label?.localeCompare(b.label));

    return (
        <>
            <ConditionalSelectComponent
                id="product_category_filter"
                labelId="product-category"
                options={availableOptions}
                label="Product Category"
                value={filters ? (windowSize.isOnMobile ? filters?.article_category : getSelectedOption(filters?.article_category, availableOptions)) : '' &&  null}
                onSelectChange={(event, newValue) => {
                    windowSize.isOnMobile ? onFilterChange('article_category', event.target.value)
                        : onFilterChange('article_category', newValue?.value)
                }}
                disableClearable={false}
                disabled={!filters?.store_id || isLoadingProductCategoryData || overviewTableIsEmpty}
                endAdornment={true}
                startAdornment={false}
                clearSelected={() => onFilterChange('article_category', null)}
            />
        </>
    );
}
