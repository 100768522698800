import React from "react";
import {useCommonDashboardList} from "../OpsPermissionManagement/hooks";
import SaveUnregisteredDashboard from "./saveUnregisteredDashboard";
import savedCommonDashboards from "leaflet";
import {Typography} from "@mui/material";


export default function UnregisteredDashboards() {
    const {data: savedCommonDashboards, isLoading: isLoadingRegisteredDashboards} = useCommonDashboardList()

    if (!savedCommonDashboards || isLoadingRegisteredDashboards) {
        return null
    }

    const registeredDashboards = window.dashboardRegistry

    const unsavedDashboards = Object.values(registeredDashboards).filter(dashboard => {
        const dashboardPath = dashboard.path
        return !savedCommonDashboards.some(savedDashboard => savedDashboard.path === dashboardPath)
    });

    const savedDashboards = Object.values(registeredDashboards).filter(dashboard => {
        const dashboardPath = dashboard.path
        return savedCommonDashboards.some(savedDashboard => savedDashboard.path === dashboardPath)
    });

    const displaySavedDashboardCount = <>
        <Typography variant="body2" color="textSecondary">
            Dashboards registered in this environment: {savedDashboards?.length || 0}
        </Typography>
    </>

    if (unsavedDashboards.length === 0) {
        return displaySavedDashboardCount
    }

    return <>
        {displaySavedDashboardCount}

        The following dashboards are detected but are not yet saved in this environment.
        {
            unsavedDashboards.map(dashboard => {
                return <SaveUnregisteredDashboard dashboard={dashboard} />
            })
        }
    </>

}