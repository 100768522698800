import { useContext, useMemo } from "react";
import ReactEcharts from "echarts-for-react";
import { abbreviateNumber } from "../../../../../common/utils";
import { WindowSizeContext } from "../../../../../common/context/WindowSizeContext";
import {CHART_FONT_SIZE} from "../../constants";

export default function VerticalBarGraph({ xAxisLabel, seriesData, title }) {
  const windowSize = useContext(WindowSizeContext);

  // Memoize the chart options to avoid unnecessary recalculations
  const total = seriesData?.reduce((acc, num) => acc + num, 0) || 0;
  
  const getPercentage = (value) => value / total * 100;
  
  const options = useMemo(() => {
    const getBarStyle = (label) => {
      return label === 'No Data'
        ? { color: '#afafaf' }  // Gray for 'No Data'
        : { color: '#4E88DD' }; // Blue for normal data
    };

    const formatLabel = (value) => abbreviateNumber(value);
    return {
      title: {
        text: title,
        textStyle: {
            color: '#000'
        },
        top: '20px',
        left: '25px',
      },
      tooltip: {
        show: true,
      },
      animation: false,
      xAxis: {
        type: 'category',
        data: xAxisLabel,
        color: 'black',
        axisTick: {
          show: false,
        },
        axisLabel: {
          color: 'black',
          rotate: windowSize.isOnMobile ? 45 : 0,
          fontSize: CHART_FONT_SIZE,
          show: true,
          interval: 0
        },
      },
      yAxis: {
        type: 'value',
        axisLabel: {
          show: false,
          fontSize: CHART_FONT_SIZE
        },
        splitLine: {
          show: false,
        },
        axisLine: {
          show: false,
        },
      },
      series: [
        {
          data: seriesData?.map((value, index) => {
            const percent = getPercentage(value)
            const smallBar = percent < 10 // if less than 10 percent show labels at top
            return {
                value,
                itemStyle: getBarStyle(xAxisLabel[index]),
                label: {
                  show: true,
                  fontSize: CHART_FONT_SIZE,
                  color: smallBar ? '#000' : '#fff',
                  position: smallBar ? 'top' : 'inside',
                  formatter: () => `${formatLabel(value)}\n(${percent.toFixed()}%)`,
                },
              }
          }),
          type: 'bar',
        },
      ],
    };
  }, [xAxisLabel, seriesData, title, windowSize.isOnMobile]);

  return (
    <ReactEcharts
      notMerge={true}
      lazyUpdate={false}
      option={options}
      style={{
        height:'20rem'
      }}
    />
  );
}
