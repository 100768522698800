import { useMemo } from 'react'
import TableComponentV2 from "../../../../common/components/TableComponentV2"
import { Grid, Box, Typography } from '@mui/material'
import LoadingComponent from '../../../../common/components/LoadingComponent';
import ActionButton from '../Components/ActionButton';
import HasComponentBlacklist from "./HasComponentBlacklist";
import HasDataFilterCheckboxDisplay from "./HasDataFilterCheckboxDisplay";
import HasAccessToDashboardCheckboxDisplay from "./HasAccessToDashboardCheckboxDisplay";


export default function UserPermissionTable({ search, data, isLoading, userDashboardData, id, dataFilter, dashboardAccess }) {

    const dashboardList = data?.map((item) => { return { id: item.id, dashboard: item.name } })

    const user_permission_management_columns = useMemo(
        () => [
            {
                id: 'dashboard',
                header: 'Datasource',
                accessorKey: 'dashboard',
                cell: (row) => {
                    return <Box mx={3}>
                        <Grid container whiteSpace={'nowrap'} fontWeight={500}>
                            <Typography variant='body1'>{row?.getValue()}</Typography>
                        </Grid>
                    </Box>
                },
                customStyle: { width: '30rem' }
            },
            {
                id: 'has_access',
                header: 'Has Access',
                cell: (row) => {
                    const dashboardName = row?.row?.original?.dashboard;

                    return <Grid container alignItems={'center'} justifyContent={'center'}>
                        <HasAccessToDashboardCheckboxDisplay
                        dashboardName={dashboardName}
                        userId={id}
                        groupId={null}
                        />
                    </Grid>
                }
            },
            {
                id: 'filtered',
                header: 'Has Component Filter',
                cell: (row) => {
                    const dashboardId = row?.row?.original?.id;

                    return <Grid container alignItems={'center'} justifyContent={'center'}>
                        <HasComponentBlacklist
                        dashboardId={dashboardId}
                        userId={id}
                        groupId={null}
                        />
                    </Grid>

                },
            },
            {
                id: 'filtered_data',
                header: 'Has Data Filter',
                cell: (row) => {
                    const dashboardId = row?.row?.original?.id;

                    return <Grid container alignItems={'center'} justifyContent={'center'}>
                        <HasDataFilterCheckboxDisplay
                        dashboardId={dashboardId}
                        userId={id}
                        groupId={null}
                        />
                    </Grid>
                },
            },
            {
                id: 'actions',
                header: 'Actions',
                cell: (row) => {
                    const dashboardName = row?.row?.original?.dashboard;
                    const dashboardId = row?.row?.original?.id;

                    return <Grid container alignItems={'center'} justifyContent={'center'}>
                        <ActionButton dashboardId={dashboardId} dashboardName={dashboardName} isLoading={isLoading} />
                    </Grid>
                },
            },
        ],
        [isLoading, id, userDashboardData] // note: removal of userDashboardData in dependency causes unnecessary creation of datafilter obj for a table that already has one
    )

    if (isLoading) {
        return <LoadingComponent />
    }

    return (
        <Grid>
            {
                dashboardList.length !== 0 ? <TableComponentV2
                    columns={user_permission_management_columns}
                    data={dashboardList}
                    tableId={'ops-permission-management-users'}
                    isCompact={true}
                    hasPagination={true}
                    isInventoryTable={true}
                    customTableContainerStyle={{
                        borderRadius: "0",
                        boxShadow: 'none',
                    }}
                /> : <Grid container justifyContent={'center'} my={3}><Typography>No Dashboard Access Yet...</Typography></Grid>
            }
        </Grid>
    )
}