import React from 'react';
import { Grid, Card } from '@mui/material';
import GenderGraph from './Gender';
import AgeGraph from './Age';
import GenerationGraph from './Generation';
import MaritalStatus from './MaritalStatus';
import ContactInformation from './ContactInformation';
import CampaignSubscription from './CampaignSubscription';
import { 
  COMPONENT_CUSTOMER_GENERATION,
  COMPONENT_CUSTOMER_AGE,
  COMPONENT_CUSTOMER_MARITAL_STATUS,
  COMPONENT_CUSTOMER_GENDER,
  COMPONENT_CUSTOMER_CONTACT_INFO,
  COMPONENT_CUSTOMER_CAMPAIGN_SUBSCRIPTION
} from '../../dashboardDefinition';
import BlacklistableComponentWrapper from '../../../common/providers/blacklistedComponentsProvider/BlacklistableComponentWrapper';

const COMPONENT_IDS = [
  { componentId: COMPONENT_CUSTOMER_GENERATION, Component: GenerationGraph },
  { componentId: COMPONENT_CUSTOMER_AGE, Component: AgeGraph },
  { componentId: COMPONENT_CUSTOMER_GENDER, Component: GenderGraph },
  { componentId: COMPONENT_CUSTOMER_MARITAL_STATUS, Component: MaritalStatus },
  { componentId: COMPONENT_CUSTOMER_CONTACT_INFO, Component: ContactInformation },
  { componentId: COMPONENT_CUSTOMER_CAMPAIGN_SUBSCRIPTION, Component: CampaignSubscription }
];


export default function CustomerDemography({ blacklistedComponents }) {
  const visibleComponents = COMPONENT_IDS.filter(
    ({ componentId }) => !blacklistedComponents.includes(componentId)
  );

  return (
    <Grid container spacing={1} marginBottom={2}>
      {visibleComponents.map(({ componentId, Component }, index) => {
        const isLastOdd = visibleComponents.length % 2 !== 0 && index === visibleComponents.length - 1;

        return (
          <BlacklistableComponentWrapper key={componentId} componentId={componentId}>
            <Grid item xs={12} sm={isLastOdd ? 12 : 6}>
              <Card>
                <Component />
              </Card>
            </Grid>
          </BlacklistableComponentWrapper>
        );
      })}
    </Grid>
  );
}