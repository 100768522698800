import React from 'react';
import {useTableDetail} from "../../../../../common/API/hooks";
import StatusDisplayWithDetail from "./StatusDisplayWithDetail";
import {Box} from '@mui/material';
import Button from "@mui/material/Button";
import {useTableColumnAggregateMutation, useTableColumnMutation} from "./hooks";

export default function ColumnsStatusDisplay({tableName, columns}) {
    const {data: tableDetail, isLoading} = useTableDetail(tableName, {})
    const columnMutation = useTableColumnMutation()
    const columnAggregateMutation = useTableColumnAggregateMutation()

    const missingColumns = React.useMemo(() => {
        if (!tableDetail) {
            return []
        }
        return columns.filter(column => {
            return !tableDetail.columns.find(apiColumn => apiColumn.name === column.name)
        })
    }, [tableDetail, columns]);

    const columnsWithMissingAggregates = React.useMemo(() => {
        if (!tableDetail) {
            return []
        }
        return columns.filter(column =>{
            return column.required_aggregates && column.required_aggregates.length > 0 &&
                column.required_aggregates.every(aggregate => {
                    return !tableDetail.aggregates.find(apiAggregate => apiAggregate.name === aggregate.name)
                })
        }).map(column => {
            const dataFromApi = tableDetail.columns.find(apiColumn => apiColumn.name === column.name)
            return {
                ...(dataFromApi || {}),
                ...column,
            }
        })
    }, [tableDetail, columns]);


    const isExpectedColumnsComplete = missingColumns.length === 0 && columnsWithMissingAggregates.length === 0;

    const fixMissing = () => {
        const tableId = tableDetail.id;
        missingColumns.forEach(col => {
            columnMutation.mutate({
                table: tableId,
                ...col
            })
        })
    }

    const fixMissingAggregates = () => {
        const tableId = tableDetail.id;
        columnsWithMissingAggregates.forEach(col => {
            col.required_aggregates.forEach(aggregate => {
                columnAggregateMutation.mutate({column: col, aggregateType: aggregate.aggregate_type})
            })
        })
    }

    return (
        <>
            <StatusDisplayWithDetail

                isLoading={isLoading}
                isSuccess={isExpectedColumnsComplete}
            >
                <Box>
                    {columns.length} Columns
                </Box>
                {
                    missingColumns.length > 0 && (
                        <Box>
                            Missing:
                            <ul>
                                {missingColumns.map(c => <li>{c.name}</li>)}
                            </ul>
                            <Button onClick={fixMissing}>
                                FIX COLUMNS
                            </Button>
                        </Box>
                    )
                }
                {
                    columnsWithMissingAggregates.length > 0 && (
                        <Box>
                            Columns with Missing Aggregates:
                            <ul>
                                {columnsWithMissingAggregates.map(c => <li>{c.name}</li>)}
                            </ul>

                            <Button onClick={fixMissingAggregates}>
                                FIX AGGREGATES
                            </Button>
                        </Box>
                    )
                }
            </StatusDisplayWithDetail>
        </>
    )
}