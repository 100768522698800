import { useContext, useMemo } from 'react';
import TableComponentV2 from '../../../common/components/TableComponentV2';
import { Typography, Box, Grid, Chip, Divider, Link } from '@mui/material';
import LoadingComponent from '../../../common/components/LoadingComponent';
import Fuse from 'fuse.js';
import { WindowSizeContext } from '../../../common/context/WindowSizeContext';
import { useOwnersPermissionList, useUserData } from './hooks';
import { LoginColntext } from '../../../common/context/LoginContext';
import ActionButton from './Components/ActionButton';
import CheckBox from './Components/CheckBox';
import AccessibleDashboardCount from "./AccessibleDashboardCount";



export default function UserPermissionManagementTable({ search, entity, ownerView = false, opsView = true, dashboardName }) {
    const { data: ownerList, isLoading: ownerLoading } = useOwnersPermissionList()
    const { data: userList, isLoading: userLoading } = useUserData()
    const windowSize = useContext(WindowSizeContext)
    const isUser = entity === 'user'

    const filteredUsers = useMemo(() => {
        if (userLoading || !userList) {
            return []
        }
        const sortedUserList = userList.sort((a, b) => {
            return a.username.localeCompare(b.username)
        })

        if (!search) {
            return sortedUserList;
        }

        const options = {
            keys: ['username', 'email', 'groups'],
            threshold: 0.3, // Adjust the threshold as needed
        };


        const fuse = new Fuse(sortedUserList, options);
        const searchItemLowerCase = search.toLowerCase();

        return fuse.search(searchItemLowerCase).map(result => result.item);
    }, [userLoading, userList, search]);


    const user_permission_management_columns = useMemo(
        () => {
            const columns = [
                {
                    id: 'user_name',
                    header: 'User',
                    accessorKey: 'username',
                    cell: (row) => {
                        const username = row?.getValue();
                        const email = row?.row?.original.email;

                        const displayComponent = <>
                            <Typography>{username}</Typography>
                            {username !== email && <Chip label={email} sx={{marginLeft: "0.5rem"}} size={"small"}/>}
                        </>

                        return (
                            <Box mx={3}>
                                <Grid container xs={12} my={1}>

                                        <Link
                                            href={opsView && `#/ops-permission-management/user/${row?.row?.original.email}/${row?.row?.original.id}`}
                                            underline={opsView ? 'hover' : 'none'}
                                            sx={{ cursor: 'pointer', color: 'black' }}
                                        >
                                            <Grid item xs={12} display={'flex'} alignItems={'center'}>
                                                {displayComponent}
                                            </Grid>
                                        </Link>
                                </Grid>
                            </Box>
                        );
                    },
                    customStyle: { width: '5rem' },
                },
            ];

            if (opsView === true) {
                columns.push(
                    {
                        id: 'dashboard_count',
                        header: 'Accessible Dashboards',
                        cell: (row) => {
                            return (
                                <Grid container alignItems={'center'} justifyContent={'center'}>
                                    <AccessibleDashboardCount groupId={null} userId={row.row?.original.id} />
                                </Grid>
                            );
                        },
                        customStyle: { width: '25rem' },
                    })
            }

            if (ownerView === true) {
                columns.push(
                    {
                        id: 'visibility',
                        header: 'Visibility',
                        cell: (row) => {
                            return <Grid container alignItems={'center'} justifyContent={'center'}>
                                <CheckBox row={row} data={userList} isLoading={userLoading} dashboardName={dashboardName} accessView={"owners"} />
                            </Grid>;
                        },
                    },
                    {
                        id: 'filtered',
                        header: 'Filtered',
                        cell: (row) => {
                            return <Grid container alignItems={'center'} justifyContent={'center'}>
                            </Grid>;
                        },
                    },
                    {
                        id: 'via_group',
                        header: 'Via Group',
                        cell: (row) => {
                            return <Grid container alignItems={'center'} justifyContent={'center'}>
                            </Grid>;
                        },
                    },
                    {
                        id: 'actions',
                        header: 'Actions',
                        cell: (row) => {
                            return <Grid container alignItems={'center'} justifyContent={'center'}>
                                <ActionButton row={row} dashboardName={dashboardName} />
                            </Grid>;
                        },
                    },
                );
            }

            return columns;
        },
        [ownerView, userList]
    );

    if (userLoading) {
        return (<LoadingComponent />)
    }

    return (
        <TableComponentV2
            columns={user_permission_management_columns}
            data={filteredUsers}
            tableId={'userPermissionManagementTable'}
            isCompact={true}
            hasPagination={true}
            isInventoryTable={true}
            customTableContainerStyle={{
                borderRadius: "0",
                boxShadow: 'none',
            }}
        />
    )
}