import { useState, useMemo } from 'react'
import TableComponentV2 from "../../../../common/components/TableComponentV2"
import { Grid, Box, IconButton, Tooltip, Typography } from '@mui/material'
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import DashboardPermissionManagementModal from '../DashboardPermissionManagementModal';
import LoadingComponent from '../../../../common/components/LoadingComponent';
import CheckBox from '../Components/CheckBox';
import ActionButton from '../Components/ActionButton';
import HasAccessToDashboardCheckboxDisplay from "./HasAccessToDashboardCheckboxDisplay";
import HasComponentBlacklist from "./HasComponentBlacklist";
import HasDataFilterCheckboxDisplay from "./HasDataFilterCheckboxDisplay";

function ActionsColumn({ row }) {
    const [isModalOpen, setModalOpen] = useState(false);

    return <>
        <Tooltip title='Edit' placement='right' arrow>
            <IconButton aria-label='Edit' size='Small' onClick={() => setModalOpen(true)}>
                <EditRoundedIcon
                    fontSize='small'
                />
            </IconButton>
        </Tooltip>

        <DashboardPermissionManagementModal row={row} isOpen={isModalOpen} setIsOpen={setModalOpen} />
    </>;
}


export default function GroupPermissionTable({ id, search, data, isLoading, groupDashboardData, dataFilter, dashboardAccess }) {

    const dashboardList = data?.map((item) => { return { id: item.id, dashboard: item.name } })

    const group_permission_management_columns = useMemo(
        () => [
            {
                id: 'dashboard',
                header: 'Dashboard',
                accessorKey: 'dashboard',
                cell: (row) => {
                    return <Box mx={3}>
                        <Grid container whiteSpace={'nowrap'} fontWeight={500}>
                            <Typography variant='body1'>{row?.getValue()}</Typography>
                        </Grid>
                    </Box>
                },
                customStyle: { width: '25rem' }
            },
            {
                id: 'has_access',
                header: 'Has Access',
                cell: (row) => {
                    const dashboardName = row?.row?.original?.dashboard;

                    return <Grid container alignItems={'center'} justifyContent={'center'}>
                        <HasAccessToDashboardCheckboxDisplay
                        dashboardName={dashboardName}
                        userId={null}
                        groupId={id}
                        />
                    </Grid>
                }
            },
            {
                id: 'filtered',
                header: 'Has Component Filter',
                cell: (row) => {
                    const dashboardId = row?.row?.original?.id;

                    return <Grid container alignItems={'center'} justifyContent={'center'}>
                        <HasComponentBlacklist
                        dashboardId={dashboardId}
                        userId={null}
                        groupId={id}
                        />
                    </Grid>

                },
            },
            {
                id: 'filtered_data',
                header: 'Has Data Filter',
                cell: (row) => {
                    const dashboardId = row?.row?.original?.id;

                    return <Grid container alignItems={'center'} justifyContent={'center'}>
                        <HasDataFilterCheckboxDisplay
                        dashboardId={dashboardId}
                        userId={null}
                        groupId={id}
                        />
                    </Grid>
                },
            },
            {
                id: 'actions',
                header: 'Actions',
                cell: (row) => {
                    const dashboardName = row?.row?.original?.dashboard;
                    const dashboardId = row?.row?.original?.id;

                    console.log("row", {dashboardName, dashboardId})

                    return <Grid container alignItems={'center'} justifyContent={'center'}>
                        <ActionButton dashboardId={dashboardId} dashboardName={dashboardName} isLoading={isLoading} />
                    </Grid>
                },
            },
        ],
        [data, groupDashboardData]
    )

    if (isLoading) {
        return <LoadingComponent />
    }

    return (
        <Grid>
            {dashboardList?.length !== 0 ? <TableComponentV2
                columns={group_permission_management_columns}
                data={dashboardList}
                tableId={'ops-permission-managemen-groups'}
                isCompact={true}
                hasPagination={true}
                isInventoryTable={true}
                customTableContainerStyle={{
                    borderRadius: "0",
                    boxShadow: 'none',
                }}
            /> : <Grid container justifyContent={'center'} my={3}><Typography>No Dashboard Access Yet...</Typography></Grid>
            }
        </Grid>
    )
} 