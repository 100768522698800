import React from "react";
import Grid from "@mui/material/Grid";
import {Box, Tab, Tabs} from "@mui/material";
import ColumnsTab from "../ColumnsTab";
import RowFiltersTab from "../RowFiltersTab";


export default function TableTabs({selectedTable, dashboardDefinition, tableRestrictions, setTableRestrictions}) {
    const [activeTab, setActiveTab] = React.useState('columns');
    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
    }

    if (!selectedTable) {
        return (
            <Box component="div" sx={{p: 2}}>
                Select a table above to view and edit its columns and row filters.
            </Box>
        )
    }

    const nonAccessibleCols = tableRestrictions?.blacklisted_columns || []
    const setNonAccessibleCols = (newNonAccessibleColumns) => {
        setTableRestrictions(prevState => {
            return {
                ...prevState,
                blacklisted_columns: newNonAccessibleColumns
            }
        })
    }

    const queryStatement = {
        combinator: 'and',
        rules: tableRestrictions?.filter_statements || []
    }
    const setQueryStatement = (newQueryStatement) => {
        setTableRestrictions(prevState => {
            return {
                ...prevState,
                filter_statements: newQueryStatement.rules
            }
        })
    }

    return <>
        <Grid xs={12}>
            <Box sx={{borderTop: 1, borderColor: 'divider'}}>
                <Tabs
                    value={activeTab} onChange={handleTabChange}
                    TabIndicatorProps={{sx: {backgroundColor: '#0A4FB4'}}}>
                    <Tab
                        label="Columns" value={'columns'}
                        sx={{textTransform: 'none', '&.Mui-selected': {color: '#0A4FB4'}}}/>
                    <Tab
                        label="Row Filters" value={'rowFilters'}
                        sx={{textTransform: 'none', '&.Mui-selected': {color: '#0A4FB4'}}}/>
                </Tabs>
            </Box>
        </Grid>
        <Grid xs={12}>


            {
                activeTab === 'columns' &&
                <ColumnsTab
                    nonAccessibleCols={nonAccessibleCols}
                    // accessibleCols={accessibleCols}
                    setNonAccessibleCols={setNonAccessibleCols}
                    // setAccessibleCols={setAccessibleCols}
                    dashboardDefinition={dashboardDefinition}
                    selectedTable={selectedTable}
                />
            }
            {
                activeTab === 'rowFilters' && <RowFiltersTab
                    selectedTable={selectedTable}
                    queryStatement={queryStatement}
                    setQueryStatement={setQueryStatement}
                    dashboardDefinition={dashboardDefinition}
                    />
            }
        </Grid>
    </>
}