import { useState } from 'react'
import { Grid, Typography, Stack, Chip } from '@mui/material'
import { useDashboardTableList } from '../hooks'
import LoadingComponent from '../../../../../common/components/LoadingComponent'


export default function TableSelection({selectedTable, setSelectedTable, data, isLoading}){

    const dashboardTables = data?.data_sources?.map((item) => {
        const { table } = item
        return { tableName: table?.name }
    })

    const handleOnClick = (tableName) => {
        setSelectedTable(tableName)
    }

    if (isLoading) {
        return <LoadingComponent />
    }

    return <>
        <Grid container xs={12} display={'flex'} alignItems={'center'}>
            {/* <Typography sx={{fontSize: '1rem', fontWeight: 'bold'}}>Tables</Typography> */}
            <Stack direction={'flexrow'} spacing={1} useFlexGap flexWrap="wrap" >
                {
                    dashboardTables?.map((item, index) => {
                        return <Chip
                            key={index}
                            label={item.tableName}
                            onClick={() => handleOnClick(item.tableName)}
                            sx={{
                                borderRadius: '5px',
                                color: selectedTable === item.tableName ? 'white' : 'black',
                                backgroundColor: selectedTable === item.tableName ? '#0A4FB4' : 'none',
                                '&:hover': {
                                    backgroundColor: selectedTable === item.tableName ? '#0A4FB4' : 'none', // No color change on hover
                                    cursor: 'pointer'
                                }
                            }}
                        />
                    })
                }
            </Stack>
        </Grid>
    </>
}