import React from 'react';
import {useCommonDashboardList} from "../OpsPermissionManagement/hooks";
import DataSourcesDisplay from "./DataSourcesDisplay";
import TableComponentV2 from "../../../../common/components/TableComponentV2";
import { Box } from '@mui/material';


export default function Dashboards() {
    const {data: savedCommonDashboards, isLoading: isLoadingRegisteredDashboards} = useCommonDashboardList()
    const registeredDashboards = window.dashboardRegistry

    const dashboardTableColumns = React.useMemo(() => {
        return [
            {
                header: 'Dashboard Name',
                accessorKey: 'name',
                cell: (row) => <Box mx={3}>{row?.getValue()}</Box>,
            },
            {
                header: 'Path',
                accessorKey: 'path',
                cell: (row) => <Box mx={3}>{row?.getValue()}</Box>,
            },
            {
                header: 'Data Sources',
                cell: ({row}) => {
                    return <DataSourcesDisplay dataSources={row.original.data_sources}/>
                },
                customStyle: { textAlign: 'center' }

            }
        ]
    }, [])

    if (!savedCommonDashboards || isLoadingRegisteredDashboards) {
        return null
    }


    const savedDashboards = Object.values(registeredDashboards).filter(dashboard => {
        const dashboardPath = dashboard.path
        return savedCommonDashboards.some(savedDashboard => savedDashboard.path === dashboardPath)
    }).map(dashboard => {
        const dashboardPath = dashboard.path
        const record = savedCommonDashboards.find(savedDashboard => savedDashboard.path === dashboardPath)
        return {
            ...dashboard,
            db: record
        }
    });

    return <TableComponentV2
        columns={dashboardTableColumns}
        data={savedDashboards}
        isCompact={true}
        />
}