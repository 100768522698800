import React from 'react';
import Button from "@mui/material/Button";
import DataSourcesAsModal from "./DataSourcesAsModal";


export default function DataSourceAsModalButton({dataSources}) {
    const [isModalOpen, setModalOpen] = React.useState(false);

    return (<>
        <Button onClick={() => setModalOpen(true)}>
            CHECK
            {" "}{dataSources?.length || 0}{" "}
            DATA SOURCES
        </Button>
        <DataSourcesAsModal
            dataSources={dataSources}
            toggle={() => setModalOpen(false)}
            isOpen={isModalOpen}/>

    </>)
}