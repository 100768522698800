import {useTable} from "./hooks";
import SplitMultiSelect from "../../../../common/components/SplitMultiSelect";
import LoadingComponent from "../../../../common/components/LoadingComponent";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";

export default function ColumnsTab({nonAccessibleCols, setNonAccessibleCols, dashboardDefinition, selectedTable}) {
    console.log("selectedTable", selectedTable, dashboardDefinition)

    const {data: dataTableDefinition, isLoading: isLoadingDataTableDefinition} = useTable({tableName: selectedTable})


    if (isLoadingDataTableDefinition) {
        return <LoadingComponent/>
    }
    if (!selectedTable) {
        return <div>No table selected</div>
    }

    const columnsFromDashboardDefinition = dashboardDefinition
        ?.data_sources
        ?.find(dataSource => dataSource.table.name === selectedTable)
        ?.columns
    const columnNamesFromDashboardDefinition = columnsFromDashboardDefinition?.map(column => column.name)

    const columnsFromDataTableDefinition = dataTableDefinition?.columns

    if (!columnsFromDataTableDefinition) {
        return <div>No columns found</div>
    }

    const matchedColumns = columnsFromDataTableDefinition?.filter(column => columnNamesFromDashboardDefinition?.includes(column.name))

    return <>
        <SplitMultiSelect
            selected={nonAccessibleCols}
            options={matchedColumns.map(column => ({label: column.name, value: column.id}))}
            onChange={(values) => setNonAccessibleCols(values)}
            reverse={true}
            selectedLabel={
                <>
                    <VisibilityOff style={{marginRight: 5}}/>
                    Hidden Columns
                </>
            }
            unselectedLabel={
                <>
                    <Visibility style={{marginRight: 5}}/>
                    Visible Columns
                </>
            }
        />
    </>
}