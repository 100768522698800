import { Checkbox } from "@mui/material";
import LoadingComponent from "../../../../common/components/LoadingComponent";
import CheckBoxRoundedIcon from '@mui/icons-material/CheckBoxRounded';
import DisabledByDefaultRoundedIcon from '@mui/icons-material/DisabledByDefaultRounded';


export default function CheckBox({ row, data, isLoading, accessView }) {

    if (isLoading || data === undefined) {
        return <LoadingComponent />;
    }

    let isAccessAvailable = true;
    if (accessView === 'ops') {
        isAccessAvailable = data
    }

    return <Checkbox
        checked={isAccessAvailable}
        icon={isAccessAvailable ? <CheckBoxRoundedIcon fontSize='medium' /> : <DisabledByDefaultRoundedIcon fontSize='medium' />} />;
}