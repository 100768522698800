import { useState, useMemo } from 'react'
import { Grid, Typography, Collapse, Box } from '@mui/material'
import CollapsibleComponent from '../../../../../common/components/CollapsibleComponent'
import TableComponentV2 from '../../../../../common/components/TableComponentV2'
import LoadingComponent from '../../../../../common/components/LoadingComponent'
import {useQuery} from "react-query";
import ApiClient from "../../../../../common/API";


export default function DataPreview({ selectedTable, filters }) {
    const [isExpanded, setIsExpanded] = useState('true')

    const {data: columnSampleData, isLoading} = useQuery(
        ['data_preview', 'table', selectedTable, filters, 'query'],
        () => {
            const cleanedFilters = filters ? Object.keys(filters).reduce((acc, key) => {
                if (filters[key]) {
                    acc[key] = filters[key]
                }
                return acc
            }, {}) : {};
            
            return ApiClient().get(`data/table/${selectedTable}/query/`,
                {
                    params: {
                        ...(cleanedFilters || {}),
                        limit: 100
                    }
                }).then((res) => {
                    return res.data.data;
                })
        },
        {
            enabled: !!selectedTable,
            refetchOnWindowFocus: false,
            staleTime: 600000, //10mins,
        }
    );

    const columns = useMemo(() => {
        if (!columnSampleData) return []

        const columns = Object.keys(columnSampleData?.[0] || {})
        const tableColumns = columns?.map((column) => {
            return {
                id: column,
                header: column,
                headerStyle: { backgroundColor: '#0A4FB4', color: 'white' },
                accessorKey: column,
                cell: (row) => {
                    const value = row?.getValue();
                    return <Grid container sx={{ width: { sm: '100%' }, marginX: 3, whiteSpace: 'nowrap', }} >
                        <Typography>{value}</Typography>
                    </Grid >
                },
                customStyle: { width: '25rem', textAlign: 'center' }
            }
        })

        return tableColumns
    }, [columnSampleData])

    return <>
        <Grid item xs={12} display={'flex'} alignItems={'center'}>
            <Typography sx={{ fontSize: '1rem', fontWeight: 'bold' }}> Data Preview</Typography>
            <CollapsibleComponent isExpand={isExpanded} setExpand={setIsExpanded} />
        </Grid>
        <Collapse in={isExpanded}>
            {
                !selectedTable ?
                    <Grid container justifyContent={'center'}> No Table Selected </Grid> :

                    isLoading ?
                        <LoadingComponent /> :
                        (!columnSampleData || columnSampleData.length === 0) ?
                            <Grid container justifyContent={'center'}> No Data Available </Grid> :
                            <TableComponentV2
                                columns={columns}
                                data={columnSampleData}
                                isCompact={true}
                                hasPagination={false}
                                propPageSize={10}
                                customTableContainerStyle={{
                                    borderRadius: "5px",
                                }}
                            />
            }
        </Collapse>
    </>
}