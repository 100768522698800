import ApiClient from "../../common/API";
import { useState } from 'react';
import LoadingComponent from "../../common/components/LoadingComponent";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import EmailIcon from '@mui/icons-material/Email';
import * as React from 'react';
import BadgeIcon from '@mui/icons-material/Badge';
import { toast } from 'react-toastify';
import { Typography } from "@mui/material";
import { WindowSizeContext } from "../../common/context/WindowSizeContext";

const DOMAIN_LIST = [
    'sminvestments.com',
    'smretail.com',
    'smsupermalls.com',
    // 'smmarkets.com',
    // 'smdevelopment.com',
    // 'smhypermarket.com',
    // 'smsavemore.com',
    // 'smsupermarket.com'
]

//used for setting default values for adid
const DEFAULT_ADID_DOMAIN_LIST = [
    'sminvestments.com'
]
export default function RegisterForm(props) {
    const windowSize = React.useContext(WindowSizeContext);
    const [isLoading, setIsLoading] = useState(false);
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

    const emailDomain = props.email.split("@")?.[1] || ''  
    const params = {
        email: props.email,
        active_directory_id: props.adid === '' ? undefined : props.adid
    }

    const enableAdId = React.useMemo(()=> {            
        return DOMAIN_LIST.includes(emailDomain)
    },[emailDomain])

    const registerIsDisabled = React.useMemo(()=> { 
        if(enableAdId) {
            return (props.email === '' || props.adid === '')  || !emailRegex.test(props.email)
        } else {
            return  props.email === '' || !emailRegex.test(props.email)
        }
    },[enableAdId,props.email,props.adid])
    
    const adid = React.useMemo(()=> {
        if(DOMAIN_LIST.includes(emailDomain)) {
            if(DEFAULT_ADID_DOMAIN_LIST.includes(emailDomain)) {
                props.setAdid(props.email.split("@")?.[0] || '')
            }

            return props.adid
        } else {
            props.setAdid('')
        }
        
        return props.adid
    },[props.adid,props.email,emailDomain])

    const signup = (e) => {
        if (e) {
            e.preventDefault();
        }
        setIsLoading(true);
        ApiClient().post('auth/register/', params).then(response => {
            setIsLoading(false);
            props.clearFields()
            toast.success(
                <div>
                    <Typography variant="caption">Registration for <span style={{ color: '#1976d2', textDecoration: 'underline', fontWeight: 'bold', fontSize: 13 }}>{props.email}</span> has been sent.</Typography>
                    <Typography variant="caption">Kindly wait for the approval of your request, Thank you.</Typography>
                </div>,
            {
                autoClose: 10000, //10 seconds
                style: {width: '400px', 
                    right: windowSize.isOnMobile ? '1em': '5.5em',
                }
            });
            
            props.switchToLoginForm()
        }).catch(error => {
            const errorMsg = error?.response?.data ? error.response.data?.email ||  error.response.data?.active_directory_id : error.message;
            setIsLoading(false);
            toast.error(errorMsg?.toString());
        })
    }

    return (
        <Box
            component="form"
            noValidate
            autoComplete="off">
            <Box sx={{
                typography: "body1",
                textAlign: "center",
                mb: "40px",
                mt: "10px"
            }}
                style={{
                    fontFamily: "Henry Sans",
                }}>
                Fill up the fields with correct details.
            </Box>
            <TextField
                InputProps={{
                    startAdornment: <EmailIcon sx={{ color: 'primary.main', mr: 1, my: 0.5 }} />,
                }}
                required
                id="outlined"
                label="Email"
                placeholder="Enter Your Email"
                type="email"
                fullWidth
                sx={{ my: 1, width: "100%" }}
                onChange={(e) => props.setEmail(e.target.value)} value={props.email}
            />
            <TextField
                required
                id="outlined"
                label="ADID / Network Username"
                placeholder="Enter Your ADID / Network Username"
                InputProps={{
                    startAdornment:
                        < BadgeIcon sx={{ color: !enableAdId ? '#00000042' : "primary.main", mr: 1, my: 0.5 }} />,
                }}
                fullWidth
                sx={{ my: 1, width: "100%", mt: "15px" }}
                onChange={(e) => props.setAdid(e.target.value)} value={adid}
                disabled={!enableAdId}
            />
            <Box textAlign='center' sx={{ my: 3 }}>
                {
                    isLoading ? <LoadingComponent /> :
                        <Button
                            type="submit"
                            size="large"
                            variant="contained"
                            disabled={registerIsDisabled}
                            onClick={() => { signup() }} sx={{ my: 0.5, width: "100%", fontFamily: "Henry Sans", varian: "h6" }}>Register</Button>
                }
            </Box>
        </Box >
    )

}