import React from "react";
import {useMutation, useQueryClient} from "react-query";
import ApiClient from "../../../../common/API";
import {toast} from "react-toastify";
import Button from "@mui/material/Button";
import {useUserList} from "../../../../common/API/hooks";
import LoadingComponent from "../../../../common/components/LoadingComponent";
import {useTableViewsMutation} from "../Dashboards/DataSources/hooks";
import DataSourceAsModalButton from "../Dashboards/DataSources/DataSourceAsModalButton";

const cleanDataSource = dataSource => {
    return {
        table: dataSource.table,
        columns: dataSource.columns
    }
}

export default function SaveUnregisteredDashboard({dashboard}) {
    const queryClient = useQueryClient()
    const {data: userList, isLoading: isLoadingUserList} = useUserList({}, {})
    const commonDashboardMutation = useMutation((dashboardData) => {
        const postPromise = ApiClient().post(`dashboard/common-dashboard/`, dashboardData)
        toast.promise(postPromise, {
            loading: 'Registering...',
            success: `Dashboard "${dashboardData.name}" saved!`,
            error: `Error registering dashboard "${dashboardData.name}"`,
        })
        return postPromise.then((res) => res.data)
    }, {
        onSuccess: () => {
            return queryClient.invalidateQueries(["dashboard", "dashboard_lists"])
        }
    })

    const datasourceActivationMutation = useMutation((table) => {
        const tableName = table.name
        const postPromise = ApiClient().post(`data/table/${tableName}/activate_for_usage/`, {})

        toast.promise(postPromise, {
            loading: 'Activating...',
            success: `Table "${tableName}" activated!`,
            error: `Error activating table "${tableName}"`,
        })
        return postPromise.then((res) => res.data)
    })

    const userEmailToId = userList?.reduce((acc, user) => {
        acc[user.email.toLowerCase()] = user.id
        return acc
    }, {})


    const ownerIds = (userEmailToId && dashboard.owners) ?
        dashboard.owners?.map(ownerEmail => {
            return userEmailToId[ownerEmail.toLowerCase()]
        }) :
        []



    const prepareDashboard = () => {
        const dashboardApiData = {
            name: dashboard.name,
            path: dashboard.path,
            owner_ids: ownerIds,
            data_sources: dashboard.data_sources?.map(cleanDataSource) || []
        }

        // save the dashboard in common dashboard storage
        commonDashboardMutation.mutateAsync(dashboardApiData).then(() => {
            toast.info(<div>
                Dashboard had been registered. <DataSourceAsModalButton dataSources={dashboard.data_sources}/>
            </div>, {
                autoClose: false,
                closeOnClick: false
            })
        })

        // activate the data sources
        dashboard.data_sources?.forEach(dataSource => {
            datasourceActivationMutation.mutate(dataSource.table)
        })
    }

    return (
        <div>
            {dashboard.name} - {" "}

            {
                isLoadingUserList ?
                    <LoadingComponent/> :
                    <Button onClick={prepareDashboard}>
                        REGISTER
                    </Button>
            }

        </div>
    )

}