import React from 'react';
import { TextField } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';

export default function SearchBar({ search, setSearch, searchFor }) {

    return (
        <TextField
            sx={{ bgcolor: "white", minWidth: "100%" }}
            size='small'
            id="outlined-search"
            label={"Search " + searchFor} // make dynamic can pass the value of the toggle
            placeholder={"Search " + searchFor + "..."}
            type="search"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            InputProps={{
                style: { borderRadius: '10px' },
                endAdornment: <InputAdornment position="end"><SearchIcon /></InputAdornment>,
            }}
        />
    )
}
