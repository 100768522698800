import React from 'react';
import {Box, Grid} from '@mui/material';
import StatusDisplay from "./StatusDisplay";


export default function StatusDisplayWithDetail({children, isLoading=true, isSuccess=true, size="1em"}) {
    return (
            <Grid container spacing={2}>
                <Grid item xs={"auto"}>
                    <StatusDisplay isLoading={isLoading} isSuccess={isSuccess}/>
                </Grid>
                <Grid item flexGrow={1} size={"grow"} xs>
                    {children}
                </Grid>
            </Grid>
    )
}