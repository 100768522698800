import React from "react";
import {useFindDashboardDataFilters} from "../DashboardPermissionManagementModal/hooks";
import {Checkbox} from "@mui/material";
import CheckBoxRoundedIcon from "@mui/icons-material/CheckBoxRounded";
import DisabledByDefaultRoundedIcon from "@mui/icons-material/DisabledByDefaultRounded";
import LoadingComponent from "../../../../common/components/LoadingComponent";


export default function HasDataFilterCheckboxDisplay({dashboardId, userId, groupId}) {
    const {
        data: dataFilters,
        isLoading: isLoadingDataFilters
    } = useFindDashboardDataFilters({
        userId: userId,
        groupId: groupId,
        dashboardId
    })

    if (isLoadingDataFilters) {
        return (
            <LoadingComponent/>
        )
    }

    const hasAnyDataFilter = dataFilters?.some(dataFilterRow => {
        return dataFilterRow.filter_statements.length > 0 || dataFilterRow.blacklisted_columns.length > 0
    })


    return (
        <Checkbox
            checked={hasAnyDataFilter}
            icon={
                hasAnyDataFilter ?
                    <CheckBoxRoundedIcon fontSize='medium'/> :
                    <DisabledByDefaultRoundedIcon fontSize='medium'/>
            }
        />
    )
}