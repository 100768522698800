import React from 'react';
import {useModuleAccessChecker} from "./hooks";
import {useModuleList} from "../../../common/API/hooks";
import LoadingComponent from "../../../common/components/LoadingComponent";
import {Typography} from "@mui/material";


export default function AccessibleDashboardCount({userId, groupId}) {
    const registeredDashboards = Object.values(window.dashboardRegistry)
    const {hasAccessToModule, isLoading: isLoadingModuleAccessChecker} = useModuleAccessChecker(userId, groupId)
    const {data: moduleList, isLoading: isLoadingModuleList} = useModuleList();

    const accessibleDashboards = React.useMemo(() => {
        if (!hasAccessToModule || !moduleList) {
            return 0
        }
        return registeredDashboards.filter(dashboardDefinition => {
            const entryPoints = dashboardDefinition.entry_points;
            const matchingModules = moduleList?.filter(module => {
                return entryPoints.includes(module.name)
            })
            return matchingModules.some(module => hasAccessToModule(module));
        })
    }, [hasAccessToModule, moduleList]);

    if (isLoadingModuleAccessChecker || isLoadingModuleList) {
        return <LoadingComponent/>
    }

    return (
        <Typography>
            {
                accessibleDashboards?.length > 0 ?
                    accessibleDashboards.length :
                    '-'
            }
        </Typography>
    );
}