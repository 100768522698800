import {useState, useMemo} from 'react';
import {Grid, Typography, Collapse, Autocomplete, TextField, Select, MenuItem} from '@mui/material';
import CollapsibleComponent from '../../../../../common/components/CollapsibleComponent';
import {QueryBuilder, formatQuery} from 'react-querybuilder';
import 'react-querybuilder/dist/query-builder.css';
import RawEditor from './RawEditor';
import {createTheme, ThemeProvider,} from '@mui/material/styles';
import {GlobalStyles} from '@mui/material'
import {QueryBuilderMaterial} from '@react-querybuilder/material';
import CircularProgress from '@mui/material/CircularProgress';
import {useFilterValues, useTableDetail} from "../../../../../common/API/hooks";
import {useQuery} from "react-query";
import ApiClient from "../../../../../common/API";
import QueryBuilderValueEditor from "./QueryBuilderValueEditor";

const muiTheme = (hasSelectedTable) => {
    return createTheme({
        components: {
            MuiButton: {
                styleOverrides: {
                    root: {
                        fontSize: '.8rem',
                        textTransform: 'none',
                        backgroundColor: '#0A4FB4',
                        '&:hover': {
                            backgroundColor: hasSelectedTable && '#0A4FB4',
                            cursor: hasSelectedTable && 'pointer',
                        },
                    },
                },
            },
        },
    });
};

const globalStyles = {
    '.queryBuilder .rule-remove svg': {
        width: '20px !important',
        height: '20px !important',
    },
    '.queryBuilder .ruleGroup-remove svg': {
        width: '20px !important',
        height: '20px !important',
    },
};


export default function QueryBuilderComponent({selectedTable, queryStatement, setQueryStatement, dashboardDefinition}) {
    const {data: tableDetails, isLoading} = useTableDetail(selectedTable)

    const hasSelectedTable = tableDetails ? true : false

    const [isExpanded, setIsExpanded] = useState(true);

    const datasourceFromDashboardDefinition = dashboardDefinition?.data_sources?.find(item => item.table?.name === selectedTable)
    const filterableColumns = datasourceFromDashboardDefinition?.data_filter_columns


    if (!datasourceFromDashboardDefinition) {
        return <Typography>Table not found</Typography>
    }

    const availableFields = filterableColumns

    const data = queryStatement?.rules?.length === 0 ? '' : formatQuery(queryStatement, 'sql');

    const CustomCombinatorSelector = ({value}) => {

        return (
            <TextField
                id="outlined"
                defaultValue={value.toUpperCase()}
                size='small'
                InputProps={{
                    readOnly: true,
                }}
                disabled={hasSelectedTable ? false : true}
                sx={{width: '63px'}}
            />
        );
    };

    const defaultCombinator = [{name: 'and', value: 'and', label: 'AND'}]
    const defaultOperator = [
        {name: '=', value: '=', label: '='},
        {name: '!=', value: '!=', label: '!='},
        {name: '<', value: '<', label: '<'},
        {name: '>', value: '>', label: '>'},
        {name: '<=', value: '<=', label: '<='},
        {name: '>=', value: '>=', label: '>='},
        {name: 'null', value: 'null', label: 'is null'},
        {name: 'notNull', value: 'notNull', label: 'is not null'},
        {name: 'in', value: 'in', label: 'in'},
        {name: 'notIn', value: 'notIn', label: 'not in'},
    ]

    return (
        <>
            <Grid item xs={12} display={'flex'} alignItems={'center'}>
                <Typography sx={{fontSize: '1rem', fontWeight: 'bold'}}> Query Builder</Typography>
                <CollapsibleComponent isExpand={isExpanded} setExpand={setIsExpanded}/>
            </Grid>
            <Collapse in={isExpanded}>
                <ThemeProvider theme={muiTheme(hasSelectedTable)}>
                    <GlobalStyles styles={globalStyles}/>
                    <QueryBuilderMaterial>
                        <QueryBuilder
                            context={{
                                selectedTable: selectedTable,
                                datasourceDefinition: datasourceFromDashboardDefinition
                            }}
                            fields={availableFields} combinators={defaultCombinator}
                            operators={defaultOperator} query={queryStatement}
                            onQueryChange={setQueryStatement}
                            controlElements={{
                                valueEditor: QueryBuilderValueEditor,
                                combinatorSelector: CustomCombinatorSelector
                            }}
                            disabled={!hasSelectedTable}/>
                    </QueryBuilderMaterial>
                </ThemeProvider>
            </Collapse>
            <RawEditor data={data}/>
        </>
    );
}
