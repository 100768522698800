import * as React from 'react';
import { useState, useEffect } from 'react';
import {styled} from '@mui/material/styles';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import {Box, Button, Divider, Grid, IconButton, List, ListItem, Typography} from '@mui/material';
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import {bu_view_merchandise_dictionary} from './ComponentsDictionary';
import {NextPlan} from "@mui/icons-material";

export const NewSwitch = styled(Switch)(({ theme }) => ({
    padding: 8,
    '& .MuiSwitch-track': {
        borderRadius: 22 / 2,
        '&::before, &::after': {
            content: '""',
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            width: 16,
            height: 16,
        },
        '&::before': {
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
                theme.palette.getContrastText(theme.palette.primary.main),
            )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
            left: 12,
        },
        '&::after': {
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
                theme.palette.getContrastText(theme.palette.primary.main),
            )}" d="M19,13H5V11H19V13Z" /></svg>')`,
            right: 12,
        },
    },
    '& .MuiSwitch-thumb': {
        boxShadow: 'none',
        width: 16,
        height: 16,
        margin: 2,
    },
}));

function Collapsible({ isExpand, toggleExpand }) {
    return (
        <IconButton size='small' onClick={toggleExpand}>
            {!isExpand ? (
                <KeyboardArrowRightRoundedIcon fontSize='medium' />
            ) : (
                <KeyboardArrowDownRoundedIcon fontSize='medium' />
            )}
        </IconButton>
    );
}


function NestedComponentList({component, toggleBlacklist, blacklistedComponents, depth=0, isParentBlacklisted=false}) {
    const [isExpanded, setIsExpanded] = useState(true);
    const hasSubcomponents = component.subcomponents && component.subcomponents.length > 0;
    const toggleExpand = () => setIsExpanded(!isExpanded);

    const parentRow = depth === 0;
    const childRow = depth === 1;
    const subchildRow = depth === 2;

    const isBlacklisted = !!component.id && blacklistedComponents.includes(component.id);

    // Check if all subcomponents are blacklisted
    const areAllSubcomponentsBlacklisted = hasSubcomponents && component.subcomponents?.every(subcomponent => {
        return !!subcomponent.id && blacklistedComponents.includes(subcomponent.id);
    });

    // Determine the checked state for the parent switch
    const isParentChecked = !isBlacklisted && !isParentBlacklisted && !areAllSubcomponentsBlacklisted;
    
    return (
        <React.Fragment>
            <ListItem disablePadding>
                <Grid container justifyContent={'space-between'} alignItems={'center'} bgcolor={parentRow && '#e0e0e0'}>
                    <Grid item display={'flex'} alignItems={'center'} pl={4 * depth}>
                        {hasSubcomponents && (
                            <Collapsible isExpand={isExpanded} toggleExpand={toggleExpand} />
                        )}
                        <Typography variant={parentRow ? 'body1' : 'body2'} fontWeight={subchildRow ? 'none' : 'bold'}>
                            {component.component}
                        </Typography>
                    </Grid>
                    {(childRow || subchildRow) && (
                        <Grid item xs>
                            <Divider variant="middle" component="li" sx={{ borderBottomWidth: 2, bgcolor: subchildRow ? 'white' : 'black', borderBottomStyle: subchildRow && 'dashed', borderBottomColor: subchildRow && 'black' }} />
                        </Grid>
                    )}
                    <Grid item>
                        <FormControlLabel
                            label={""}
                            control={
                                <NewSwitch
                                    disabled={component.readOnly || !component.id }
                                    checked={isParentChecked}
                                    onChange={(e) => toggleBlacklist(component.id)}
                                />
                            }
                        />
                    </Grid>
                </Grid>
            </ListItem>
            {hasSubcomponents && isExpanded && (
                <List disablePadding>
                    {component.subcomponents?.map(subcomponent =>
                        <NestedComponentList
                            component={subcomponent}
                            toggleBlacklist={toggleBlacklist}
                            blacklistedComponents={blacklistedComponents}
                            isParentBlacklisted={isBlacklisted || isParentBlacklisted}
                            depth={depth + 1}
                        />
                    )}
                </List>
            )}
        </React.Fragment>
    );
}


function recursiveGetAllComponentIds(components) {
    let allComponentIds = [];
    components.forEach(component => {
        if (component.id) {
            allComponentIds.push(component.id);
        }

        if (component.subcomponents) {
            allComponentIds = allComponentIds.concat(recursiveGetAllComponentIds(component.subcomponents));
        }
    });
    return allComponentIds;
}



export default function ComponentsTab({ blacklistedComponents, setBlacklistedComponents, dashboardDefinition }) {
    const components = dashboardDefinition?.components || [];
    const allComponentIds = recursiveGetAllComponentIds(components);

    const checkAndAddParentIfNeeded = () => {
        const componentIdsToToggle = new Set(); 
    
        const checkSubcomponents = (subcomponents) => {
            return subcomponents.every(sub => blacklistedComponents.includes(sub.id)) && subcomponents;
        };
    
        const processComponent = (component) => {
            if (!component.subcomponents) return;
    
            const allSubcomponentsBlacklisted = checkSubcomponents(component.subcomponents);
    
            component.subcomponents.forEach(sub => {
                if (Array.isArray(sub.subcomponents)) {
                    const innerSubComponentsBlacklisted = checkSubcomponents(sub.subcomponents);
                    if (innerSubComponentsBlacklisted) {
                        componentIdsToToggle.add(sub.id);
                    }
    
                    // Recursively check deeper levels of subcomponents
                    processComponent(sub);
                }
            });
    
            // If all subcomponents are blacklisted, add the parent component to the toggle list
            if (allSubcomponentsBlacklisted) {
                componentIdsToToggle.add(component.id);
            }
        };
    
        components.forEach(processComponent);
    
        return [...componentIdsToToggle];
    };

    const toggleComponentAndSubcomponents = (id) => {
        const componentIdsToToggle = new Set();
        
        const findComponentAndSubcomponents = (subcomponents) => {
            subcomponents.forEach((sub) => {
                if (sub.id === id) {
                    // If the current subcomponent is the one we are looking for, add it
                    componentIdsToToggle.add(sub.id);
                    // Add its subcomponents if they exist
                    if (sub.subcomponents) {
                        toggleSubcomponents(sub.subcomponents);
                    }
                } else if (sub.subcomponents) {
                    // If the subcomponents has subcomponents, check them 
                    findComponentAndSubcomponents(sub.subcomponents);
                }
            });
        };
    
        const toggleSubcomponents = (subcomponents) => {
            subcomponents.forEach((sub) => {
                componentIdsToToggle.add(sub.id);
                if (sub.subcomponents) {
                    toggleSubcomponents(sub.subcomponents);
                }
            });
        };
    
        components.forEach((component) => {
            if (component.id === id) {
                componentIdsToToggle.add(component.id);
                if (component.subcomponents) {
                    toggleSubcomponents(component.subcomponents);
                }
            } else if (component.subcomponents) {
                findComponentAndSubcomponents(component.subcomponents);
            }
        });

        const parentComponentIds = checkAndAddParentIfNeeded();
        // Add parent component IDs only if their children are not already toggled
        parentComponentIds.forEach(parentId => {
            // Only add parent if no child is already toggled
            const isChildAlreadyToggled = [...componentIdsToToggle].some(id => components.find(comp => comp.id === id)?.subcomponents?.some(sub => sub.id === parentId));
            if (!isChildAlreadyToggled) {
                componentIdsToToggle.add(parentId);
            }
        });

        return [...componentIdsToToggle];
    };
    
    const toggleBlacklist = (componentId) => {
        const idsToToggle = toggleComponentAndSubcomponents(componentId);

        if (blacklistedComponents.includes(componentId)) {
            setBlacklistedComponents(blacklistedComponents.filter(item => !idsToToggle.includes(item)));
        } else {
            setBlacklistedComponents([...blacklistedComponents, ...idsToToggle]);
        }
    };

    useEffect(() => {
        const parentComponentIds = checkAndAddParentIfNeeded();
        const newIdsToAdd = parentComponentIds.filter(id => !blacklistedComponents.includes(id));
        
        if (newIdsToAdd.length > 0) {
            setBlacklistedComponents(prevState => {
                return [...prevState, ...newIdsToAdd];
            });
        }
    }, [ blacklistedComponents ]);

    const disableAllComponents = () => {
        setBlacklistedComponents([...allComponentIds]);
    };

    const enableAllComponents = () => {
        setBlacklistedComponents([]);
    };

    return (
        <>
            <Box component="div" sx={{ p: 2 }}>
                You can disable components that you do not want to be displayed in the dashboard for the user/group.
            </Box>
            <Grid item xs={12}>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 1 }}>
                    <Button size="medium"
                        sx={{ textTransform: 'none', fontWeight: 'bold', color: '#0A4FB4', width: '100px' }}
                        onClick={disableAllComponents}>
                        Disable All
                    </Button>
                    <Button size="medium"
                        sx={{ textTransform: 'none', fontWeight: 'bold', color: '#0A4FB4', width: '100px' }}
                        onClick={enableAllComponents}>
                        Enable All
                    </Button>
                </Box>
                <Divider orientation="horizontal" sx={{ my: 1, borderRightWidth: 2, bgcolor: 'grey' }} />
            </Grid>
            <Box component="div" sx={{ p: 2, overflowY: "scroll", maxHeight: "40vh" }}>
                <List disablePadding>
                    {components?.map(
                        component =>
                            <NestedComponentList
                                component={component}
                                blacklistedComponents={blacklistedComponents}
                                toggleBlacklist={toggleBlacklist}
                                depth={0}
                            />
                    )}
                </List>
            </Box>
        </>
    );
}
