import React, {useEffect, useMemo, useState} from 'react';
import {Box, Button, Grid, IconButton, Modal, Stack, Tab, Tabs, Typography} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import ComponentsTab from './ComponentsTab';
import {
    useFindDashboardAccessPermission,
    useFindDashboardDataFilters,
    useSaveDashboardAccessMutation,
    useSaveDataFiltersMutation
} from './hooks';
import {useQueryClient} from 'react-query';
import {useParams} from 'react-router-dom';
import {useFindDashboardDefinitionByName} from '../hooks';
import DPMInformationToolTip from '../Components/DPMInformationToolTip';
import {isArraysEqual} from "../../../../common/utils";
import DataTab from "./DataTab";
import EntryPointManagement from "./EntryPointManagement";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: '5px',
    px: 2,
    pb: 2,
    width: '70%',
    minWidth: '70%',
    maxHeight: '90vh',
    overflow: 'auto',
    overflowX: 'hidden'
};


export default function DashboardPermissionManagementModal({isOpen, setIsOpen, dashboardName, dashboardId}) {
    const {email, id: userOrGroupId, toggle_group} = useParams()
    const [activeTab, setActiveTab] = useState('access');

    const [blacklistedComponents, setBlacklistedComponents] = useState([])
    const [restrictionsPerTable, setRestrictionsPerTable] = useState({})

    const resetFilter = () => {
        setBlacklistedComponents([])
        setRestrictionsPerTable((prevState) => {
            if (!prevState || Object.keys(prevState).length === 0) {
                return {}
            }
            return Object.keys(prevState).reduce((acc, key) => {
                acc[key] = {
                    ...prevState[key],
                    blacklisted_columns: [],
                    filter_statements: []
                };
                return acc;
            }, {})
        })
    }
    const canReset = React.useMemo(() => {
        return blacklistedComponents.length > 0 || Object.keys(restrictionsPerTable).some(tableName => {
            return restrictionsPerTable[tableName].blacklisted_columns?.length > 0 ||
                restrictionsPerTable[tableName].filter_statements?.length > 0
        })
    }, [blacklistedComponents, restrictionsPerTable])

    // Toggle indicator (User / Group)
    const isUser = toggle_group === 'user' ? true : false;

    const dashboardDefinition = useFindDashboardDefinitionByName(dashboardName);

    const {
        data: dashboardPermission,
        isLoading: isDashboardAccessPermissionsLoading
    } = useFindDashboardAccessPermission({
        userId: isUser ? userOrGroupId : null,
        groupId: isUser ? null : userOrGroupId,
        dashboardId
    });
    const dashboardAccessPermissionId = dashboardPermission?.id
    const blacklistedComponentsFromPermission = dashboardPermission?.component_blacklist

    useEffect(() => {
        if (!!blacklistedComponentsFromPermission) {  // TODO: check this. We may have no restriction saved in the API
            setBlacklistedComponents(blacklistedComponentsFromPermission || [])
        }
    }, [blacklistedComponentsFromPermission]);

    const {
        data: dataFilters,
        isLoading: isLoadingDataFilters
    } = useFindDashboardDataFilters({
        userId: isUser ? userOrGroupId : null,
        groupId: isUser ? null : userOrGroupId,
        dashboardId
    })

    const dataFilterDictFromAPI = useMemo(() => {
        if (!dataFilters) {
            return {}
        }

        return dataFilters.reduce((acc, dataFilterRow) => {
            acc[dataFilterRow.table] = {
                id: dataFilterRow.id,
                filter_statements: dataFilterRow.filter_statements,
                blacklisted_columns: dataFilterRow.blacklisted_columns.map(col => col.id)
            }
            return acc
        }, {})
    }, [dataFilters])

    useEffect(() => {
        if (!!dataFilterDictFromAPI) {
            setRestrictionsPerTable(dataFilterDictFromAPI)
        }
    }, [dataFilterDictFromAPI]);


    // const saveRowFilters = useSaveDataFiltersMutation({ id: dataFilterId })
    const saveDashboardAccessMutation = useSaveDashboardAccessMutation({
        id: dashboardPermission?.id
    })
    const saveDataFilterMutation = useSaveDataFiltersMutation()


    const queryClient = useQueryClient();
    const commonProperties = {
        user_ids: [],
        group_ids: [],
    }
    if (isUser) {
        commonProperties.user_ids = [userOrGroupId];
    } else {
        commonProperties.group_ids = [userOrGroupId];
    }

    // Function for saving filters
    const handleSaveFilters = () => {
        saveDashboardComponentBlacklist()
        saveDataFilters()
    };

    const saveDashboardComponentBlacklist = () => {

        if (dashboardAccessPermissionId) {
            const hasChangedBlacklistedComponents = !isArraysEqual(blacklistedComponents, blacklistedComponentsFromPermission || [])
            if (hasChangedBlacklistedComponents) {
                saveDashboardAccessMutation.mutate({
                    component_blacklist: blacklistedComponents
                });
            }
        } else {
            const dashboardAccessInstance = {
                dashboard: dashboardId,
                component_blacklist: blacklistedComponents,
                ...commonProperties
            };
            saveDashboardAccessMutation.mutate(dashboardAccessInstance);
        }
    }

    const saveDataFilters = () => {
        Object.keys(restrictionsPerTable).forEach(table => {
            const {id, filter_statements, blacklisted_columns} = restrictionsPerTable[table]
            if (id) {
                // check if there are changes
                const hasFilterStatementsChanged = !isArraysEqual(filter_statements, dataFilterDictFromAPI[table]?.filter_statements || [])
                const hasBlacklistedColumnsChanged = !isArraysEqual(blacklisted_columns, dataFilterDictFromAPI[table]?.blacklisted_columns || [])
                if (hasFilterStatementsChanged || hasBlacklistedColumnsChanged) {
                    saveDataFilterMutation.mutate({
                        id,
                        filter_statements,
                        blacklisted_columns
                    })
                }
            } else {
                // check if there are values
                if (filter_statements?.length > 0 || blacklisted_columns?.length > 0) {
                    saveDataFilterMutation.mutate({
                        table,
                        name: 'Filter',
                        description: `Filter for ${table}`,
                        filter_statements,
                        blacklisted_columns,
                        ...commonProperties
                    })
                }
            }
        })
    }

    const handleChange = (event, newValue) => {
        setActiveTab(newValue);
    };

    const closeModal = () => {
        setIsOpen(false);
    };

    // TODO: move to constants
    const TAB_STYLES = {textTransform: 'none', '&.Mui-selected': {color: '#0A4FB4'}}


    return (
        <Modal open={isOpen}>
            <Grid sx={style}>
                {/* modal header*/}
                <Grid xs={12} marginY={2} display="flex" alignItems={'center'}>
                    <Typography variant='h6' ml={1} fontWeight={'medium'}>
                        Permission for{" "}
                        <span style={{textDecoration: 'underline'}}>
                            {email}
                        </span>
                        {" "}to {dashboardName}
                    </Typography>
                    {/* <Typography>
                    </Typography> */}
                    <DPMInformationToolTip/>
                    <Grid sx={{marginLeft: 'auto'}}>
                        <IconButton onClick={closeModal}>
                            <ClearIcon fontSize='medium'/>
                        </IconButton>
                    </Grid>
                </Grid>

                {/* modal body */}
                <Grid xs={12} sx={{minHeight: '50vh', overflowY: "hidden"}}>
                    <Grid>
                        <Grid xs={12}>
                            <Box sx={{borderTop: 1, borderColor: 'divider'}}>
                                <Tabs value={activeTab} onChange={handleChange}
                                      TabIndicatorProps={{sx: {backgroundColor: '#0A4FB4'}}}>
                                    <Tab label="Access" value={'access'} sx={TAB_STYLES}/>
                                    <Tab label="Components" value={'component'} sx={TAB_STYLES}/>
                                    <Tab label="Data Sources" value={'data'} sx={TAB_STYLES}/>

                                    {/*<Tab label="Columns" value={'columns'} sx={{ textTransform: 'none', '&.Mui-selected': { color: '#0A4FB4' } }} />*/}
                                    {/*<Tab label="Row Filters" value={'rowFilters'} sx={{ textTransform: 'none', '&.Mui-selected': { color: '#0A4FB4' } }} />*/}
                                </Tabs>
                            </Box>
                        </Grid>
                        <Grid xs={12}>
                            {
                                activeTab === 'access' &&
                                <EntryPointManagement
                                    dashboardDefinition={dashboardDefinition}
                                    userId={isUser ? userOrGroupId : null}
                                    groupId={isUser ? null : userOrGroupId}
                                />
                            }
                            {
                                activeTab === 'component' &&
                                <ComponentsTab
                                    blacklistedComponents={blacklistedComponents}
                                    setBlacklistedComponents={setBlacklistedComponents}
                                    dashboardDefinition={dashboardDefinition}
                                />
                            }
                            {
                                activeTab === 'data' && <DataTab
                                    restrictionsPerTable={restrictionsPerTable}
                                    setRestrictionsPerTable={setRestrictionsPerTable}
                                    dashboardDefinition={dashboardDefinition}
                                    dashboardId={dashboardId}
                                />
                            }

                        </Grid>
                    </Grid>
                </Grid>

                {/* modal footer*/}
                {
                    activeTab !== 'access' && <Grid container xs={12}>
                        <Grid item xs={12} sx={{display: 'flex', justifyContent: 'flex-end', mt: 2}}>
                            <Stack spacing={2} direction="row">
                                <Button
                                    disabled={!canReset}
                                    variant="outlined" size="small"
                                    onClick={() => {
                                        resetFilter()
                                    }}
                                    sx={{
                                        textTransform: 'none',
                                        color: '#0A4FB4',
                                        borderColor: '#0A4FB4',
                                        width: '100px'
                                    }}>
                                    Reset
                                </Button>

                                <Button
                                    variant="contained" size="small" onClick={handleSaveFilters}
                                    sx={{textTransform: 'none', bgcolor: '#0A4FB4', width: '100px'}}>
                                    Apply Filters
                                </Button>
                            </Stack>
                        </Grid>
                    </Grid>
                }

            </Grid>
        </Modal>
    );
}