import React from "react";
import {useFindDashboardAccessPermission} from "../DashboardPermissionManagementModal/hooks";
import CheckBoxRoundedIcon from "@mui/icons-material/CheckBoxRounded";
import DisabledByDefaultRoundedIcon from "@mui/icons-material/DisabledByDefaultRounded";
import {Checkbox} from "@mui/material";
import LoadingComponent from "../../../../common/components/LoadingComponent";


export default function HasComponentBlacklist({dashboardId, userId, groupId}) {
    const {
        data: dashboardPermission,
        isLoading: isDashboardAccessPermissionsLoading
    } = useFindDashboardAccessPermission({
        userId: userId,
        groupId: groupId,
        dashboardId
    });

    if (isDashboardAccessPermissionsLoading) {
        return (
            <LoadingComponent/>
        )
    }

    const isChecked = dashboardPermission?.component_blacklist?.length > 0;

    return (
        <Checkbox
            checked={isChecked}
            icon={
                isChecked ?
                    <CheckBoxRoundedIcon fontSize='medium'/> :
                    <DisabledByDefaultRoundedIcon fontSize='medium'/>
            }
        />
    )
}