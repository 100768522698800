import React, {useMemo} from 'react'
import TableComponentV2 from '../../../../../../../common/components/TableComponentV2'
import {Box, IconButton, Grid} from '@mui/material'
import MetricFilter from '../../Filters/MetricFilter'
import NumericValueDisplay from '../../../numericValueDisplay'
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';
import ArrowRightRoundedIcon from '@mui/icons-material/ArrowRightRounded';
import {getMetricColumnHeader, getParamsBasedOnPeriod} from '../../../../../Customers/util'
import {useCustomerPurchaseBehaviorV3} from './hooks'
import LoadingComponent from '../../../../../../../common/components/LoadingComponent'
import BarIndicator from '../../../barIndicator'
import Section from "../../../../../../../common/components/Dashboard/Section";
import {useFiltersContext} from "../../Provider/context";
import {useFiltersContextAsParams} from "../../CustomerLocation/hooks";
import {CUSTOMER_FILTER_DEFINITIONS} from "../../constants";
import {
    useBlacklistedDashboardComponents,
    useIsComponentUsable
} from "../../../../providers/blacklistedComponentsProvider/hooks";
import {
    COMPONENT_BRAND_PERFORMANCE_CUSTOMER_COUNT_COLUMNS,
    COMPONENT_BRAND_PERFORMANCE_TOTAL_COLUMNS,
    COMPONENT_METRIC_SALES_CONTRIBUTION, COMPONENT_METRIC_SALES_VALUE,
    COMPONENT_METRIC_TRANSACTIONS_VALUE, COMPONENT_METRIC_TRANSACTIONS_CONTRIBUTION,
    COMPONENT_METRIC_UNITS_SOLD_PER_TRANSACTION_VALUE, COMPONENT_METRIC_UNITS_SOLD_PER_TRANSACTION_CONTRIBUTION,
    COMPONENT_METRIC_BASKET_SIZE_VALUE, COMPONENT_METRIC_BASKET_SIZE_CONTRIBUTION,
    COMPONENT_METRIC_AVG_FREQUENCY_OF_VISIT_VALUE, COMPONENT_METRIC_AVG_FREQUENCY_OF_VISIT_CONTRIBUTION

} from "../dashboardDefinition";


const metricToValueAndContributionMap = {
    actual_sales: [COMPONENT_METRIC_SALES_VALUE, COMPONENT_METRIC_SALES_CONTRIBUTION],
    actual_transactions: [COMPONENT_METRIC_TRANSACTIONS_VALUE, COMPONENT_METRIC_TRANSACTIONS_CONTRIBUTION],
    actual_unit_sold_per_txn: [COMPONENT_METRIC_UNITS_SOLD_PER_TRANSACTION_VALUE, COMPONENT_METRIC_UNITS_SOLD_PER_TRANSACTION_CONTRIBUTION],
    actual_basket_size: [COMPONENT_METRIC_BASKET_SIZE_VALUE, COMPONENT_METRIC_BASKET_SIZE_CONTRIBUTION],
    actual_frequency_of_visit: [COMPONENT_METRIC_AVG_FREQUENCY_OF_VISIT_VALUE, COMPONENT_METRIC_AVG_FREQUENCY_OF_VISIT_CONTRIBUTION]
}


function collectValues(data) {
    const initial = {
        male_customer_ratio: [],
        male_totals: [],
        male_percentage_contrib: [],
        female_customer_ratio: [],
        female_totals: [],
        female_percentage_contrib: [],
        no_data_customer_ratio: [],
        no_data_totals: [],
        no_data_percentage_contrib: [],
        other_gender_customer_ratio: [],
        other_gender_totals: [],
        other_gender_percentage_contrib: []
    };

    return data.reduce((acc, curr) => {
        for (const key in initial) {
            if (curr[key] !== undefined) {
                acc[key].push(curr[key]);
            }
        }
        return acc;
    }, {...initial});
}


export default function CustomerBrandPerformance(
    {
        params,
        period,
        business_unit,
        showHeatmap = false
    }
) {

    const {filters, setFilter} = useFiltersContext();
    const blacklistedComponents = useBlacklistedDashboardComponents()
    const isTotalColumnVisibleToUser = useIsComponentUsable(COMPONENT_BRAND_PERFORMANCE_TOTAL_COLUMNS)
    const isCustomerCountVisibleToUser = useIsComponentUsable(COMPONENT_BRAND_PERFORMANCE_CUSTOMER_COUNT_COLUMNS)

    const excludedFilters = ['gender_bin', 'age_group', 'brand']
    const applicableFiltersFromDefinition = Object.keys(CUSTOMER_FILTER_DEFINITIONS)
        .filter(key => !excludedFilters.includes(key))
        .reduce((acc, key) => {
            acc[key] = CUSTOMER_FILTER_DEFINITIONS[key]
            return acc
        }, {})
    const filtersAsParams = useFiltersContextAsParams(applicableFiltersFromDefinition)

    const paramsBasedOnPeriod = React.useMemo(() => {
        const isMTD = period === 'mtd';
        const isQoQ = period === 'qoq';

        return getParamsBasedOnPeriod(isMTD, isQoQ, {
            ...params,
            pm_business_unit_desc_standardized: `${business_unit}`,
            ...filtersAsParams
        })
    }, [params, business_unit, filters, period])


    const selectedMetric = React.useMemo(() => {
        return filters?.metric_column
    }, [filters?.metric_column])


    const [isMetricValueVisible, isMetricContributionVisible] = useMemo(() => {
        const [valueComponent, contributionComponent] = metricToValueAndContributionMap[selectedMetric] || [null, null]
        return [
            !blacklistedComponents.includes(valueComponent),
            !blacklistedComponents.includes(contributionComponent)
        ]
    }, [blacklistedComponents, selectedMetric])

    const dynamicHeader = getMetricColumnHeader(period, selectedMetric)

    const {
        data,
        isLoading: isDataLoading,
        isLoadingBrandGenderDatasets: isDataTopLayerLoading
    } = useCustomerPurchaseBehaviorV3(paramsBasedOnPeriod, selectedMetric)

    // Sort parent rows
    const sortedParentData = useMemo(() => {
        return [...data || []].sort((a, b) => {
            if (a.brand > b.brand) return 1;
            if (a.brand < b.brand) return -1;
            return 0;
        });
    }, [data]);

    // Sort subrows
    const sortedDataWithSubrows = useMemo(() => {
        const customAgeBinOrder = [
            'Below 18',
            '18-19',
            '20-24',
            '25-29',
            '30-34',
            '35-39',
            '40-44',
            '45-49',
            '50-54',
            '55-59',
            '60-64',
            '65 and Above',
            'No Data'
        ];

        return sortedParentData.map(parentRow => ({
            ...parentRow,
            subRows: parentRow.subRows?.sort((a, b) => {
                const brandAIndex = customAgeBinOrder.indexOf(a.brand);
                const brandBIndex = customAgeBinOrder.indexOf(b.brand);
                if (brandAIndex === -1 && brandBIndex === -1) {
                    return 0; // If both brands are not found, keep their relative order
                } else if (brandAIndex === -1) {
                    return 1; // Move brands not found in the custom order to the end
                } else if (brandBIndex === -1) {
                    return -1; // Move brands not found in the custom order to the end
                }
                return brandAIndex - brandBIndex;
            }) || []
        }));
    }, [sortedParentData]);

    const filteredAgeGroupData = sortedDataWithSubrows?.map(obj => ({
        ...obj,
        subRows: obj.subRows.filter(subRow => {
            return !filters?.age_group?.length || filters.age_group.includes(subRow.ageGroup)
        })
    }))?.filter(obj => obj.subRows.length > 0 || !filters?.age_group?.length);

    const filteredData = filteredAgeGroupData?.filter(obj => !filters?.brand?.length || filters.brand.includes(obj.brand))

    //Fix me: Apply on TableComponentV2
    const customSort = (rowA, rowB, columnId) => {
        if (rowA.depth > 0 && rowB.depth > 0) {
            return 0; // Do not sort subRows
        }
        return rowA.original[columnId] > rowB.original[columnId] ? 1 : -1;
    }

    const minMaxData = filteredData.reduce((acc, brandData) => {
        const {brand, subRows} = brandData;
        if (subRows.length === 0) {
            return acc;
        }

        acc[brand] = collectValues(subRows);

        return acc;
    }, {});

    const getRatio = (brand, data, accessorKey) => {
        const subRowArray = minMaxData?.[brand]?.[accessorKey]?.map(value => isNaN(value) ? 0 : value)

        const min = Math.min(...subRowArray || [])
        const max = Math.max(...subRowArray || [])

        const ratio = (data - min) / (max - min);

        return !isFinite(ratio) || isNaN(ratio) ? 0 : ratio
    }

    // color fn for Customer Purchase Behavior; ratio is the proportion of the value to the min and max of the column; 
    const getColorBasedOnPercentage = (ratio) => {

        const lightMintGreen = {r: 206, g: 227, b: 227}; // lightest color for smallest value -> #CEE3E3
        const darkMintGreen = {r: 172, g: 195, b: 194}; // darkest color for largest value -> #ACC3C2

        if (ratio === 0) return '#CEE3E3';  // ratio = 0 means the value was equal to the min value of that certain set of rows/subrows
        if (ratio === 1) return '#ACC3C2';  // ratio = 1 means the value was equal to the max value of that certain set of rows/subrows

        const r = Math.round(lightMintGreen.r + ratio * (darkMintGreen.r - lightMintGreen.r));
        const g = Math.round(lightMintGreen.g + ratio * (darkMintGreen.g - lightMintGreen.g));
        const b = Math.round(lightMintGreen.b + ratio * (darkMintGreen.b - lightMintGreen.b));

        return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)}`;
    }


    const CellDisplay = ({row, value, accessorKey, postfix, isThousandsOnly}) => {

        const isSubRow = row?.depth > 0
        const prefix = !postfix ? ((selectedMetric === 'actual_sales' || selectedMetric === 'actual_basket_size') ? '₱' : '') : ''
        const fixedValue = value === 0 ? 0 : value?.toFixed(2)

        if (isSubRow && value !== 0 && showHeatmap) {
            const brand = row.getParentRow().original.brand
            const ratio = getRatio(brand, value, accessorKey)
            const color = getColorBasedOnPercentage(ratio)

            return <Box sx={{
                width: '3.5rem',
                height: '1.5rem',
                marginX: 'auto',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                <BarIndicator value={value.toFixed(2)} bgColor={color} prefix={prefix} postfix={postfix}
                              componentType={'cpb'}/>
            </Box>
        }

        return <>
            <Box sx={{width: {sm: '100%', xs: '4rem'}, marginX: 'auto'}}>
                <NumericValueDisplay value={fixedValue} postfix={postfix} prefix={prefix}
                                     isThousandsOnly={isThousandsOnly} isAbbreviated/>
            </Box>
        </>
    }

    const useThousands = selectedMetric !== 'actual_frequency_of_visit' && selectedMetric !== 'actual_units_sold_per_txn';
    const showContributions = selectedMetric !== 'actual_frequency_of_visit'
    const customer_purchase_col = useMemo(
        () => {
            const customStyle12Rem = {
                width: '12rem',
                height: '2rem',
                textAlign: 'center',
                verticalAlign: 'middle',
                whiteSpace: 'nowrap'
            }
            const customStyle15Rem = {
                width: '15rem',
                ...customStyle12Rem
            }
            const generateSubColumnDefinitions = (gender) => {
                const customerCountColumnDefinition = {
                    header: 'Customer Count',
                    accessorKey: `${gender}_customer_count`,
                    cell: ({row, getValue}) => {
                        return <CellDisplay value={getValue()} row={row} accessorKey={`${gender}_customer_count`}
                                            postfix=' '/>
                    },
                    customStyle: customStyle12Rem,
                    sortingFn: customSort
                };

                const metricValueColumn = {
                    header: dynamicHeader,
                    accessorKey: `${gender}_totals`,
                    cell: ({row, getValue}) => {
                        return <CellDisplay value={getValue()} row={row} accessorKey={`${gender}_totals`}
                                            isThousandsOnly={useThousands}/>
                    },
                    customStyle: customStyle15Rem,
                    sortingFn: customSort
                };
                const metricContributionColumn = {
                    header: '% Cont.',
                    accessorKey: `${gender}_percentage_contrib`,
                    cell: ({row, getValue}) => {
                        if (!showContributions) {
                            return 'N/A'
                        }
                        return <CellDisplay value={getValue()} row={row}
                                            accessorKey={`${gender}_percentage_contrib`}
                                            postfix={'%'}/>
                    },
                    customStyle: customStyle12Rem,
                    sortingFn: customSort
                };
                const subColumns = []
                if (isCustomerCountVisibleToUser) {
                    subColumns.push(customerCountColumnDefinition)
                }
                if (isMetricValueVisible) {
                    subColumns.push(metricValueColumn)
                }
                if (isMetricContributionVisible) {
                    subColumns.push(metricContributionColumn)
                }

                return subColumns
            }
            const columns = [
                {
                    header: ' ',
                    headerStyle: {backgroundColor: '#0A4FB4', color: 'white'},
                    columns: [{
                        header: 'Brand / Age',
                        accessorKey: 'brand',
                        cell: ({row, getValue}) => {
                            const value = getValue();
                            return (
                                <Box sx={{
                                    fontWeight: row.depth === 0 ? 'bold' : 'regular',
                                    marginLeft: `${row.depth * 1}rem`,
                                    whiteSpace: 'nowrap',
                                    display: 'flex',
                                    alignItems: 'center'
                                }}>
                                    {value === 'Floating' || value === null ? 'Others' : value}
                                    {row.original.subRows && (
                                        <IconButton
                                            size="small"
                                            onClick={(event) => {
                                                event.stopPropagation(); // Stop the click event from propagating
                                                row.getToggleExpandedHandler()();
                                            }}
                                            sx={{cursor: 'pointer'}}
                                        >
                                            {row.getIsExpanded() ? <ArrowDropDownRoundedIcon fontSize="medium"/> :
                                                <ArrowRightRoundedIcon fontSize="medium" color="#e0e0e0"/>}
                                        </IconButton>
                                    )}
                                </Box>
                            );
                        },
                        sortingFn: customSort
                    }
                    ],
                    customStyle: {width: '10rem', height: '2rem', textAlign: 'left', verticalAlign: 'middle'},
                },
                {
                    header: 'Female',
                    id: 'female',
                    genderFilterValue: 'female',
                    headerStyle: {backgroundColor: '#0A4FB4', color: 'white'},
                    columns: generateSubColumnDefinitions('female'),
                },
                {
                    header: 'Male',
                    id: 'male',
                    genderFilterValue: 'male',
                    headerStyle: {backgroundColor: '#0A4FB4', color: 'white'},
                    columns: generateSubColumnDefinitions('male'),
                },
                {
                    header: 'Other Gender',
                    id: 'other_gender',
                    genderFilterValue: 'other gender',
                    headerStyle: {backgroundColor: '#0A4FB4', color: 'white'},
                    columns: generateSubColumnDefinitions('other_gender'),
                },
            ]

            const totalColumnDefinition = {
                header: 'Total',
                id: 'total',
                headerStyle: {backgroundColor: '#0A4FB4', color: 'white'},
                columns: generateSubColumnDefinitions('total'),
            }
            if (isTotalColumnVisibleToUser) {
                columns.push(totalColumnDefinition)
            }


            if (filters.gender_bin && filters.gender_bin.length > 0) {
                return columns.filter(column => {
                    if (!column.genderFilterValue) {
                        return true  // these columns should not be removed if they do not exist in the selected filters
                    }

                    const selectedGenders = filters.gender_bin.map(g => g.toString().toLowerCase())
                    return selectedGenders.includes(column.genderFilterValue)

                })
            }

            return columns
        },
        [dynamicHeader, minMaxData, useThousands, filters.gender_bin, isMetricValueVisible, isMetricContributionVisible]
    );

    return (
        <Section
            title={"Brand Performance"}
            selectorsOrFilters={[
                <MetricFilter
                    filters={filters} onFilterChange={setFilter} params={params}
                    business_unit={business_unit} metric_options={'bu_view_merch_customer'}/>
            ]}>

            {isDataTopLayerLoading ?
                <LoadingComponent/> :
                <TableComponentV2
                    initialSort={{id: 'brand', asc: true}}
                    columns={customer_purchase_col}
                    data={filteredData}
                    tableId={'customerPurchaseBehavior'}
                    isCompact={true}
                    customTableHeaderStyle={{
                        backgroundColor: '#caddeb'
                    }}
                    hasPagination={true}
                    sortingFn={customSort}
                />
            }
        </Section>
    )
}