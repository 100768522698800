import React from 'react';
import Section from "../../../../../common/components/Dashboard/Section";
import DataSource from "./DataSource";


export default function DataSources({dataSources}) {
    return (<>
            {
                dataSources.map((dataSource, index) => (
                    <DataSource dataSource={dataSource} index={index} />
                ))
            }
        </>
    )
}