import React from "react";
import ContextBasedDropdownSelector from "../../common/presentation/selectors/ContextBasedDropdownSelector";
import { CARD_STATUS_REGISTERED, CARD_STATUS_UNREGISTERED } from "../hooks";

const OPTIONS = [
    { label: 'All', value: 'All'},
    { label: CARD_STATUS_REGISTERED, value: CARD_STATUS_REGISTERED},
    { label: CARD_STATUS_UNREGISTERED, value: CARD_STATUS_UNREGISTERED}
]
export default function RegistrationSelector() {
    return (
        <ContextBasedDropdownSelector
        isLoading={false}
        options={OPTIONS}
        label={"Registration"}
        contextKey={"registration"}
        defaultValue={"All"}
        />
    )
}